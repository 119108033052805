<template>
  <div class="participant-details">
    <!-- Top Action Bar -->
    <div class="action-bar">
      <div class="left-actions">
        <el-button icon="el-icon-back" size="medium" plain @click="goBack">返回列表</el-button>
      </div>
      <div class="order-info">
        <div class="info-item">
          <span class="order-number">订单编号: {{ orderData.order_no }}</span>
        </div>
        <div class="info-item">
          <span class="activity-title">{{ activity.activity_title_cn }}</span>
          <el-tag :type="getActivityStatusType" size="small" effect="plain">{{ getActivityStatusText }}</el-tag>
        </div>
      </div>
    </div>

    <!-- Participants Tabs -->
    <div class="main-content">
      <div class="left-column">
        <el-tabs v-model="activeEnroller" type="border-card" class="participant-tabs">
          <el-tab-pane
            v-for="(participant, index) in participants"
            :key="participant.participant_id"
            :label="participant.name || `报名人${index + 1}`"
            :name="participant.participant_id">
            <template #label>
              <div class="tab-label">
                <span>{{ participant.name || `报名人${index + 1}` }}</span>
                <el-tooltip :content="participant.is_deleted ? '恢复报名人' : '删除报名人'" placement="top" effect="light">
                  <i :class="participant.is_deleted ? 'el-icon-refresh-right' : 'el-icon-delete'" @click.stop="participant.is_deleted ? handleRestore(participant) : handleDelete(participant)"></i>
                </el-tooltip>
              </div>
            </template>
            
            <!-- Basic Information Card -->
            <el-card class="detail-card">
              <div slot="header" class="card-header">
                <span class="title enroller">基本信息</span>
                <div class="header-actions">
                  <template v-if="!editingBasic">
                    <el-button type="text" icon="el-icon-edit" @click="startEdit(participant, 'basic')">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="saveEnroller(participant.participant_id)">保存</el-button>
                    <el-button type="text" icon="el-icon-close" @click="cancelEdit('basic')">取消</el-button>
                  </template>
                </div>
              </div>
              <div class="card-content">
                <template v-if="!editingBasic">
                  <div class="info-grid">
                    <div class="info-item full-width">
                      <span class="label">姓名</span>
                      <span class="value">{{ participant.name || '-' }}</span>
                    </div>
                  </div>
                  <div class="info-grid" style="margin-top: 16px;">
                    <div class="info-item">
                      <span class="label">联系电话</span>
                      <span class="value">{{ participant.phone || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">所在学校</span>
                      <span class="value">{{ participant.school }}</span>
                    </div>
                  </div>
                  <div class="info-grid three-columns" style="margin-top: 16px;">
                    <div class="info-item">
                      <span class="label">性别</span>
                      <span class="value" v-if="participant.gender === 0">男</span>
                      <span class="value" v-else-if="participant.gender === 1">女</span>
                      <span class="value" v-else>-</span>
                    </div>
                    <div class="info-item">
                      <span class="label">年龄</span>
                      <span class="value">{{ participant.age }}岁</span>
                    </div>
                    <div class="info-item">
                      <span class="label">年级</span>
                      <span class="value" v-if="participant.grade === 'XIAO-1'">一年级</span>
                      <span class="value" v-else-if="participant.grade === 'XIAO-2'">二年级</span>
                      <span class="value" v-else-if="participant.grade === 'XIAO-3'">三年级</span>
                      <span class="value" v-else-if="participant.grade === 'XIAO-4'">四年级</span>
                      <span class="value" v-else-if="participant.grade === 'XIAO-5'">五年级</span>
                      <span class="value" v-else-if="participant.grade === 'XIAO-6'">六年级</span>
                      <span class="value" v-else-if="participant.grade === 'CHU-1'">初一</span>
                      <span class="value" v-else-if="participant.grade === 'CHU-2'">初二</span>
                      <span class="value" v-else-if="participant.grade === 'CHU-3'">初三</span>
                      <span class="value" v-else-if="participant.grade === 'GAO-1'">高一</span>
                      <span class="value" v-else-if="participant.grade === 'GAO-2'">高二</span>
                      <span class="value" v-else-if="participant.grade === 'GAO-3'">高三</span>
                      <span class="value" v-else-if="participant.grade === 'OTHER'">其他</span>
                      <span class="value" v-else>{{ participant.grade || '-' }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="info-grid">
                    <div class="info-item full-width">
                      <span class="label">姓名<span class="required">*</span></span>
                      <el-input v-model="basicEdit.name" size="small"></el-input>
                    </div>
                  </div>
                  <div class="info-grid" style="margin-top: 16px;">
                    <div class="info-item">
                      <span class="label">联系电话</span>
                      <el-input v-model="basicEdit.phone" size="small"></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">所在学校</span>
                      <el-input v-model="basicEdit.school" size="small"></el-input>
                    </div>
                  </div>
                  <div class="info-grid three-columns" style="margin-top: 16px;">
                    <div class="info-item">
                      <span class="label">性别<span class="required">*</span></span>
                      <el-select v-model="basicEdit.gender" size="small" style="width: 100%">
                        <el-option :value="0" label="男"></el-option>
                        <el-option :value="1" label="女"></el-option>
                      </el-select>
                    </div>
                    <div class="info-item">
                      <span class="label">年龄<span class="required">*</span></span>
                      <el-input v-model="basicEdit.age" size="small">
                        <template slot="append">岁</template>
                      </el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">年级</span>
                      <el-select v-model="basicEdit.grade" size="small" style="width: 100%">
                        <el-option value="XIAO-1" label="一年级"></el-option>
                        <el-option value="XIAO-2" label="二年级"></el-option>
                        <el-option value="XIAO-3" label="三年级"></el-option>
                        <el-option value="XIAO-4" label="四年级"></el-option>
                        <el-option value="XIAO-5" label="五年级"></el-option>
                        <el-option value="XIAO-6" label="六年级"></el-option>
                        <el-option value="CHU-1" label="初一"></el-option>
                        <el-option value="CHU-2" label="初二"></el-option>
                        <el-option value="CHU-3" label="初三"></el-option>
                        <el-option value="GAO-1" label="高一"></el-option>
                        <el-option value="GAO-2" label="高二"></el-option>
                        <el-option value="GAO-3" label="高三"></el-option>
                        <el-option value="OTHER" label="其他"></el-option>
                      </el-select>
                    </div>
                  </div>
                </template>
              </div>
            </el-card>

            <!-- ID Information Card -->
            <el-card class="detail-card">
              <div slot="header" class="card-header">
                <span class="title enroller">证件信息</span>
                <div class="header-actions">
                  <template v-if="!editingIds">
                    <el-button type="text" icon="el-icon-edit" @click="startEdit(participant, 'ids')">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="saveEnroller(participant.participant_id)">保存</el-button>
                    <el-button type="text" icon="el-icon-close" @click="cancelEdit('ids')">取消</el-button>
                  </template>
                </div>
              </div>
              <div class="card-content">
                <template v-if="!editingIds">
                  <div class="info-grid">
                    <div class="info-item">
                      <span class="label">身份证类型</span>
                      <span class="value">{{ participant.id_card_type || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">身份证号</span>
                      <span class="value">{{ participant.id_card_number || '-' }}</span>
                    </div>
                  </div>
                  <div class="id-card-images three-columns">
                    <template v-if="participant.id_card_type === '户口本'">
                      <div class="image-container">
                        <span class="image-label">户口本</span>
                        <div class="image-wrapper small">
                          <img id="household" :src="getImageUrl(participant.household, 'household')" >
                        </div>
                      </div>
                    </template>
                    <template v-if="participant.id_card_type === '身份证'">
                      <div class="image-container">
                        <span class="image-label">身份证正面</span>
                        <div class="image-wrapper small">
                          <img id="id-front" :src="getImageUrl(participant.id_card_front, 'id-front')">
                        </div>
                      </div>
                      <div class="image-container">
                        <span class="image-label">身份证反面</span>
                        <div class="image-wrapper small">
                          <img id="id-back" :src="getImageUrl(participant.id_card_back, 'id-back')">
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div class="info-grid">
                    <div class="info-item">
                      <span class="label">身份证类型</span>
                      <el-select v-model="basicEdit.id_card_type" size="small" style="width: 100%">
                        <el-option value="身份证" label="身份证"></el-option>
                        <el-option value="户口本" label="户口本"></el-option>
                      </el-select>
                    </div>
                    <div class="info-item">
                      <span class="label">身份证号<span class="required">*</span></span>
                      <el-input v-model="basicEdit.id_card_number" size="small"></el-input>
                    </div>
                  </div>
                  <div class="id-card-images three-columns">
                    <template v-if="basicEdit.id_card_type === '户口本'">
                      <div class="image-container">
                        <span class="image-label">户口本</span>
                        <div class="image-wrapper small">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('household')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                            :on-success="(res) => handleIdCardUpload(res, 'household')">
                            <img v-if="basicEdit.household" :id="'household-edit'" :src="getImageUrl(basicEdit.household, 'household-edit')" class="upload-image">
                            <i v-else class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                      </div>
                    </template>
                    <template v-if="basicEdit.id_card_type === '身份证'">
                      <div class="image-container">
                        <span class="image-label">身份证正面</span>
                        <div class="image-wrapper small">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('id_card_front')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                            :on-success="(res) => handleIdCardUpload(res, 'front')">
                            <img v-if="basicEdit.id_card_front" :id="'id-front-edit'" :src="getImageUrl(basicEdit.id_card_front, 'id-front-edit')" class="upload-image">
                            <i v-else class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                      </div>
                      <div class="image-container">
                        <span class="image-label">身份证反面</span>
                        <div class="image-wrapper small">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('id_card_back')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                            :on-success="(res) => handleIdCardUpload(res, 'back')">
                            <img v-if="basicEdit.id_card_back" :id="'id-back-edit'" :src="getImageUrl(basicEdit.id_card_back, 'id-back-edit')" class="upload-image">
                            <i v-else class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </el-card>

            <!-- Health Information Card -->
            <el-card class="detail-card">
              <div slot="header" class="card-header">
                <span class="title enroller">健康信息</span>
                <div class="header-actions">
                  <template v-if="!editingHealth">
                    <el-button type="text" icon="el-icon-edit" @click="startEdit(participant, 'health')">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="saveEnroller(participant.participant_id)">保存</el-button>
                    <el-button type="text" icon="el-icon-close" @click="cancelEdit('health')">取消</el-button>
                  </template>
                </div>
              </div>
              <div class="card-content">
                <template v-if="!editingHealth">
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">身高</span>
                      <span class="value">{{ participant.height || '-' }} {{ participant.height ? 'cm' : '' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">体重</span>
                      <span class="value">{{ participant.weight || '-' }} {{ participant.weight ? 'kg' : '' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">血型</span>
                      <span class="value">{{ participant.blood_type || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">睡眠状况</span>
                      <span class="value">{{ participant.sleep_status || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">饮食忌口</span>
                      <span class="value">{{ participant.dietary_taboo || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">过敏药物</span>
                      <span class="value">{{ participant.drug_allergy || '-' }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">身高</span>
                      <el-input v-model="basicEdit.height" size="small">
                        <template slot="append">cm</template>
                      </el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">体重</span>
                      <el-input v-model="basicEdit.weight" size="small">
                        <template slot="append">kg</template>
                      </el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">血型</span>
                      <el-select v-model="basicEdit.blood_type" size="small" style="width: 100%">
                        <el-option value="A" label="A型"></el-option>
                        <el-option value="B" label="B型"></el-option>
                        <el-option value="O" label="O型"></el-option>
                        <el-option value="AB" label="AB型"></el-option>
                        <el-option value="其他" label="其他"></el-option>
                      </el-select>
                    </div>
                    <div class="info-item">
                      <span class="label">睡眠状况</span>
                      <el-input type="textarea" v-model="basicEdit.sleep_status" size="small" autosize></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">饮食忌口</span>
                      <el-input type="textarea" v-model="basicEdit.dietary_taboo" size="small" autosize></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">过敏药物</span>
                      <el-input type="textarea" v-model="basicEdit.drug_allergy" size="small" autosize></el-input>
                    </div>
                  </div>
                </template>
              </div>
            </el-card>

            <!-- Learning Information Card -->
            <el-card class="detail-card">
              <div slot="header" class="card-header">
                <span class="title enroller">学习情况</span>
                <div class="header-actions">
                  <template v-if="!editingLearning">
                    <el-button type="text" icon="el-icon-edit" @click="startEdit(participant, 'learning')">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="saveEnroller(participant.participant_id)">保存</el-button>
                    <el-button type="text" icon="el-icon-close" @click="cancelEdit('learning')">取消</el-button>
                  </template>
                </div>
              </div>
              <div class="card-content">
                <template v-if="!editingLearning">
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">学习情况</span>
                      <span class="value">{{ participant.learning_desc || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">擅长科目</span>
                      <span class="value">{{ participant.favorite_subject || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">薄弱科目</span>
                      <span class="value">{{ participant.weak_subject || '-' }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">学习情况</span>
                      <el-input v-model="basicEdit.learning_desc" size="small"></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">擅长科目</span>
                      <el-input v-model="basicEdit.favorite_subject" size="small"></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">薄弱科目</span>
                      <el-input v-model="basicEdit.weak_subject" size="small"></el-input>
                    </div>
                  </div>
                </template>
              </div>
            </el-card>

            <!-- Personality Card -->
            <el-card class="detail-card">
              <div slot="header" class="card-header">
                <span class="title enroller">个性特征</span>
                <div class="header-actions">
                  <template v-if="!editingPersonality">
                    <el-button type="text" icon="el-icon-edit" @click="startEdit(participant, 'personality')">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="saveEnroller(participant.participant_id)">保存</el-button>
                    <el-button type="text" icon="el-icon-close" @click="cancelEdit('personality')">取消</el-button>
                  </template>
                </div>
              </div>
              <div class="card-content">
                <template v-if="!editingPersonality">
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">性格特点</span>
                      <span class="value">{{ participant.personality_traits || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">兴趣爱好</span>
                      <span class="value">{{ participant.hobbies || '-' }}</span>
                    </div>
                    <div class="info-item">
                      <span class="label">喜欢的颜色</span>
                      <span class="value">{{ participant.favorite_color || '-' }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">性格特点</span>
                      <el-input type="textarea" v-model="basicEdit.personality_traits" size="small" autosize></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">兴趣爱好</span>
                      <el-input type="textarea" v-model="basicEdit.hobbies" size="small" autosize></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">喜欢的颜色</span>
                      <el-input type="textarea" v-model="basicEdit.favorite_color" size="small" autosize></el-input>
                    </div>
                  </div>
                </template>
              </div>
            </el-card>
          </el-tab-pane>

          <el-tab-pane name="add" disabled>
            <template #label>
              <div class="tab-label add-tab" @click.stop="addParticipant">
                <i class="el-icon-plus"></i>
                <span>ADD</span>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="right-column">
        <!-- Emergency Contact Card -->
        <el-card class="detail-card">
          <div slot="header" class="card-header">
            <span class="title contact">联系人信息</span>
            <div class="header-actions">
              <template v-if="!editingEmergency">
                <el-button type="text" icon="el-icon-edit" @click="startEdit(null, 'emergency')">编辑</el-button>
              </template>
              <template v-else>
                <el-button type="text" icon="el-icon-check" @click="saveEmergency">保存</el-button>
                <el-button type="text" icon="el-icon-close" @click="cancelEdit('emergency')">取消</el-button>
              </template>
            </div>
          </div>
          <div class="card-content">
            <template v-if="!editingEmergency">
              <div class="info-grid">
                <div class="info-item">
                  <span class="label">家长姓名</span>
                  <span class="value">{{ emergencyContact.name || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">家长职业</span>
                  <span class="value">{{ emergencyContact.occupation || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">家长电话</span>
                  <span class="value">{{ emergencyContact.phone || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">身份证号</span>
                  <span class="value">{{ emergencyContact.id_card_number || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">家长关系</span>
                  <span class="value">{{ emergencyContact.relationship || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">家庭住址</span>
                  <span class="value">{{ emergencyContact.address || '-' }}</span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="info-grid">
                <div class="info-item">
                  <span class="label">家长姓名<span class="required">*</span></span>
                  <el-input v-model="emergencyEdit.name" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家长职业<span class="required">*</span></span>
                  <el-input v-model="emergencyEdit.occupation" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家长电话<span class="required">*</span></span>
                  <el-input v-model="emergencyEdit.phone" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">身份证号</span>
                  <el-input v-model="emergencyEdit.id_card_number" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家长关系</span>
                  <el-input v-model="emergencyEdit.relationship" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家庭住址</span>
                  <el-input v-model="emergencyEdit.address" size="small"></el-input>
                </div>
              </div>
            </template>
          </div>
        </el-card>

        <!-- Travel Plan Card -->
        <el-card class="detail-card">
          <div slot="header" class="card-header">
            <span class="title contact">接送服务</span>
            <div class="header-actions">
              <template v-if="!editingTravel">
                <el-button type="text" icon="el-icon-edit" @click="startEdit(null, 'travel')">编辑</el-button>
              </template>
              <template v-else>
                <el-button type="text" icon="el-icon-check" @click="saveTravel">保存</el-button>
                <el-button type="text" icon="el-icon-close" @click="cancelEdit('travel')">取消</el-button>
              </template>
            </div>
          </div>
          <div class="card-content">
            <template v-if="!editingTravel">
              <div class="info-grid three-columns">
                <div class="info-item">
                  <span class="label">是否需要接送</span>
                  <span class="value">{{ travelPlan.needs_pickup ? '是' : '否' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">出发城市</span>
                  <span class="value">{{ travelPlan.departure_city || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">交通方式</span>
                  <span class="value">{{ travelPlan.transport_type || '-' }}</span>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item">
                  <span class="label">到达时间</span>
                  <span class="value">{{ formatDateTime(travelPlan.arrival_time) }}</span>
                </div>
                <div class="info-item">
                  <span class="label">车次/航班号</span>
                  <span class="value">{{ travelPlan.transport_number || '-' }}</span>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">出行备注</span>
                  <span class="value">{{ travelPlan.travel_remarks || '-' }}</span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="info-grid three-columns">
                <div class="info-item">
                  <span class="label">是否需要接送</span>
                  <el-switch v-model="travelEdit.needs_pickup"></el-switch>
                </div>
                <div class="info-item">
                  <span class="label">出发城市</span>
                  <el-input v-model="travelEdit.departure_city" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">交通方式</span>
                  <el-select v-model="travelEdit.transport_type" size="small" style="width: 100%">
                    <el-option value="飞机" label="飞机"></el-option>
                    <el-option value="火车" label="火车"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item">
                  <span class="label">到达时间</span>
                  <el-date-picker
                    v-model="travelEdit.arrival_time"
                    type="datetime"
                    size="small"
                    style="width: 100%"
                    placeholder="选择到达时间"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </div>
                <div class="info-item">
                  <span class="label">车次/航班号</span>
                  <el-input v-model="travelEdit.transport_number" size="small"></el-input>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">出行备注</span>
                  <el-input type="textarea" v-model="travelEdit.travel_remarks" size="small" autosize></el-input>
                </div>
              </div>
            </template>
          </div>
        </el-card>

        <!-- Extra Information Card -->
        <el-card class="detail-card">
          <div slot="header" class="card-header">
            <span class="title contact">通用信息</span>
            <div class="header-actions">
              <template v-if="!editingExtra">
                <el-button type="text" icon="el-icon-edit" @click="startEdit(null, 'extra')">编辑</el-button>
              </template>
              <template v-else>
                <el-button type="text" icon="el-icon-check" @click="saveExtra">保存</el-button>
                <el-button type="text" icon="el-icon-close" @click="cancelEdit('extra')">取消</el-button>
              </template>
            </div>
          </div>
          <div class="card-content">
            <template v-if="!editingExtra">
              <div class="info-grid three-columns">
                <div class="info-item">
                  <span class="label">参加过游学</span>
                  <span class="value">{{ extraInfo.has_participated ? '是' : '否' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">游学类型</span>
                  <span class="value">{{ extraInfo.study_type || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">游学详细</span>
                  <span class="value">{{ extraInfo.study_details || '-' }}</span>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item">
                  <span class="label">所在城市</span>
                  <span class="value">{{ extraInfo.city || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">了解渠道</span>
                  <span class="value">{{ extraInfo.info_source || '-'   }}</span>
                </div>
                <div class="info-item">
                  <span class="label">推荐人</span>
                  <span class="value">{{ extraInfo.referrer || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">专属客服</span>
                  <span class="value">{{ extraInfo.customer_service || '-' }}</span>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">预约备注</span>
                  <span class="value">{{ extraInfo.reservation_remarks || '-' }}</span>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">其他备注</span>
                  <span class="value">{{ extraInfo.extra_remarks || '-' }}</span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="info-grid three-columns">
                <div class="info-item">
                  <span class="label">参加过游学</span>
                  <el-switch v-model="extraEdit.has_participated"></el-switch>
                </div>
                <div class="info-item">
                  <span class="label">游学类型</span>
                  <el-input v-model="extraEdit.study_type" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">游学详细</span>
                  <el-input v-model="extraEdit.study_details" size="small"></el-input>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item">
                  <span class="label">所在城市</span>
                  <el-input v-model="extraEdit.city" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">了解渠道</span>
                  <el-input v-model="extraEdit.info_source" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">推荐人</span>
                  <el-input v-model="extraEdit.referrer" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">专属客服</span>
                  <el-input v-model="extraEdit.customer_service" size="small"></el-input>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">预约备注</span>
                  <el-input type="textarea" v-model="extraEdit.reservation_remarks" size="small" autosize></el-input>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">其他备注</span>
                  <el-input type="textarea" v-model="extraEdit.extra_remarks" size="small" autosize></el-input>
                </div>
              </div>
            </template>
          </div>
        </el-card>
      </div>
    </div>

    <!-- Delete Confirmation Dialog -->
    <el-dialog
      title="确认删除"
      :visible.sync="deleteBox"
      width="30%">
      <el-form>
        <el-form-item label="是否硬删除" required>
          <el-select v-model="hardDelete" placeholder="请选择删除类型">
            <el-option label="硬删除" value="true"></el-option>
            <el-option label="软删除" value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="删除原因" required>
          <el-input v-model="deleteReason" placeholder="请输入删除原因"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelete">取消</el-button>
        <el-button type="danger" @click="confirmDelete" :disabled="!deleteReason || !hardDelete">确定</el-button>
      </span>
    </el-dialog>

    <!-- Add Participant Dialog -->
    <el-dialog
      title="新增报名人"
      :visible.sync="addParticipantBox"
      width="30%">
      <el-form :model="newParticipant" :rules="participantRules" ref="participantForm" label-width="100px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="newParticipant.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="newParticipant.gender" placeholder="请选择性别" style="width: 100%">
            <el-option :value="0" label="男"></el-option>
            <el-option :value="1" label="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <el-input v-model="newParticipant.age" placeholder="请输入年龄">
            <template slot="append">岁</template>
          </el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_card_number">
          <el-input v-model="newParticipant.id_card_number" placeholder="请输入身份证号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addParticipantBox = false">取消</el-button>
        <el-button type="primary" @click="submitNewParticipant">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { BASE_URL } from '@/utils/urls'
import { ValidateFormName, validateFormIdCard } from '@/utils/validation'

export default {
  name: 'ParticipantDetails',
  data() {
    return {
      orderData: {},
      participants: [],
      creatorInfo: null,
      updaterInfo: null,
      hardDelete: null,
      deleteReason: '',
      activeTab: 'basic',
      activeEnroller: '',
      currentEnroller: null,
      editingIds: false,
      editingBasic: false,
      editingExtra: false,
      editingHealth: false,
      editingTravel: false,
      editingLearning: false,
      editingEmergency: false,
      editingPersonality: false,
      basicEdit: {},
      extraEdit: {},
      healthEdit: {},
      travelEdit: {},
      emergencyEdit: {},
      loadImage: true,
      deleteBox: false,
      addParticipantBox: false,
      newParticipant: {
        name: '',
        gender: null,
        age: '',
        id_card_number: '',
      },
      participantRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { validator: ValidateFormName, trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        age: [
          { required: true, message: '请输入年龄', trigger: 'blur' }
        ],
        id_card_number: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: validateFormIdCard, trigger: 'blur' }
        ]
      },
    }
  },
  computed: {
    currentEnrollerData() {
      return this.participants.find(p => p.participant_id === this.activeEnroller) || {}
    },
    emergencyContact() {
      return this.orderData.emergency_contact || {}
    },
    extraInfo() {
      return this.orderData.extra_info || {}
    },
    travelPlan() {
      return this.orderData.travel_plan || {}
    },
    activity() {
      return this.orderData.activity || {}
    },
    getActivityStatusType() {
      const statusMap = {
        1: 'warning',  // 未开始
        2: 'success',  // 进行中
        3: 'info'      // 已结束
      }
      return statusMap[this.activity.activity_status] || 'info'
    },
    getActivityStatusText() {
      const statusMap = {
        1: '未开始',
        2: '进行中',
        3: '已结束'
      }
      return statusMap[this.activity.activity_status] || '未知状态'
    }
  },
  created() {
    this.fetchOrderDetails()
  },
  methods: {
    async fetchOrderDetails() {
      try {
        const res = await this.$http.get(`participants/${this.$route.params.order_no}`)
        this.orderData = res.data
        this.participants = res.data.participants || []
        
        if (this.participants.length > 0) {
          this.activeEnroller = this.participants[0].participant_id
        }
      } catch (err) {
        this.$message.error('获取订单详情失败')
      }
    },

    formatDateTime(date) {
      if (!date) return '-'
      return new Date(date).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    },

    goBack() {
      this.$router.push('/admin/enrolls')
    },

    handleDelete(participant) {
      this.currentEnroller = participant
      this.deleteBox = true
    },

    async confirmDelete() {
      if (!this.currentEnroller || !this.deleteReason) {
        this.$message.warning('请填写删除原因')
        return
      }
      
      try {
        await this.$http.delete(`participants/${this.currentEnroller.participant_id}`, {
          data: { reason: this.deleteReason, hard_delete: this.hardDelete }
        })
        this.$message.success('删除成功')
        this.deleteBox = false
        this.hardDelete = null
        this.deleteReason = ''
        await this.fetchOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '删除失败')
      }
    },

    async handleRestore(participant) {
      try {
        await this.$http.post(`participants/${participant.participant_id}/restore`)
        this.$message.success('恢复成功')
        await this.fetchOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '恢复失败')
      }
    },

    cancelDelete() {
      this.deleteBox = false
      this.hardDelete = null
      this.deleteReason = ''
    },

    startEdit(participant, type) {
      if (type === 'basic') {
        this.loadImage = false
        this.editingBasic = true
        this.basicEdit = { ...participant }
      } else if (type === 'ids') {
        this.loadImage = true
        this.editingIds = true
        this.basicEdit = { ...participant }
      } else if (type === 'health') {
        this.loadImage = false
        this.editingHealth = true
        this.basicEdit = { ...participant }
      } else if (type === 'learning') {
        this.loadImage = false
        this.editingLearning = true
        this.basicEdit = { ...participant }
      } else if (type === 'personality') {
        this.loadImage = false
        this.editingPersonality = true
        this.basicEdit = { ...participant }
      } else if (type === 'emergency') {
        this.loadImage = false
        this.editingEmergency = true
        this.emergencyEdit = { ...this.emergencyContact }
      } else if (type === 'travel') {
        this.loadImage = false
        this.editingTravel = true
        this.travelEdit = { ...this.travelPlan }
      } else if (type === 'extra') {
        this.loadImage = false
        this.editingExtra = true
        this.extraEdit = { ...this.extraInfo }
      }
    },

    cancelEdit(type) {
      if (type === 'basic') {
        this.editingBasic = false
        this.basicEdit = {}
      } else if (type === 'ids') {
        this.editingIds = false
        this.basicEdit = {}
      } else if (type === 'health') {
        this.editingHealth = false
        this.basicEdit = {}
      } else if (type === 'learning') {
        this.editingLearning = false
        this.basicEdit = {}
      } else if (type === 'personality') {
        this.editingPersonality = false
        this.basicEdit = {}
      } else if (type === 'emergency') {
        this.editingEmergency = false
        this.emergencyEdit = {}
      } else if (type === 'travel') {
        this.editingTravel = false
        this.travelEdit = {}
      } else if (type === 'extra') {
        this.editingExtra = false
        this.extraEdit = {}
      }
      this.loadImage = true
    },

    async saveEnroller(id) {
      try {
        await this.$http.put(`participants/${this.$route.params.order_no}/${id}`, this.basicEdit)
        this.editingIds = false
        this.editingBasic = false
        this.editingHealth = false
        this.editingLearning = false
        this.editingPersonality = false
        await this.fetchOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '报名人信息更新失败')
      } finally {
        this.loadImage = true
      }
    },

    async saveEmergency() {
      try {
        await this.$http.put(`participants/${this.$route.params.order_no}`, {
          emergency_contact: this.emergencyEdit,
        })
        this.editingEmergency = false
        await this.fetchOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '联系人信息更新失败')
      } finally {
        this.loadImage = true
      }
    },

    async saveTravel() {
      try {
        await this.$http.put(`participants/${this.$route.params.order_no}`, {
          travel_plan: this.travelEdit
        })
        if ( this.travelEdit.needs_pickup && this.travelEdit.arrival_time === null ) {
          this.$message.error('请填写到达时间')
          return
        }
        this.editingTravel = false
        await this.fetchOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '接送服务更新失败')
      } finally {
        this.loadImage = true
      }
    },

    async saveExtra() {
      try {
        await this.$http.put(`participants/${this.$route.params.order_no}`, {
          extra_info: this.extraEdit
        })
        if (this.extraEdit.has_participated === true && (this.extraEdit.study_type === '' || this.extraEdit.study_details === null)) {
          this.$message.error('请填写学习类型和学习详情')
          return
        }
        this.editingExtra = false
        await this.fetchOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '通用信息更新失败')
      } finally {
        this.loadImage = true
      }
    },

    getImageUrl(path, imageId) {
      if (!path) return ''
      if (imageId && this.loadImage) {
        this.$http({
          method: 'get',
          url: `images/confidential/${path}`,
          responseType: 'blob'
        }).then(res => {
          const objectURL = URL.createObjectURL(res)
          const img = document.getElementById(imageId)
          if (img) img.src = objectURL
        }).catch(error => {
          console.error('加载图片失败:', error)
        })
      }
    },

    handleIdCardUpload(response, type) {
      if (!response.data) {
        this.$message.error('上传失败')
        return
      }
      if (type === 'front') {
        this.basicEdit.id_card_front = response.data
      } else if (type === 'back') {
        this.basicEdit.id_card_back = response.data
      } else if (type === 'household') {
        this.basicEdit.household = response.data
      } else {
        this.$message.error('未知的上传类型')
      }
    },

    getUploadUrl(type) {
      return `${BASE_URL}/api/v1/images/confidential/${type}?order_no=${this.$route.params.order_no}`
    },

    beforeUpload(file) {
      const isImage = file.type.startsWith('image/')
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传图片文件!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    getHeaders() {
      return {
        'Authorization': `Bearer ${getToken()}`
      }
    },

    addParticipant() {
      this.addParticipantBox = true
      this.newParticipant = {
        name: '',
        gender: null,
        age: '',
        id_card_number: '',
      }
    },

    submitNewParticipant() {
      this.$refs.participantForm.validate(async (valid) => {
        if (valid) {
          try {
            await this.$http.put(`participants/${this.$route.params.order_no}`, {
              participants: [this.newParticipant],
            })
            this.$message.success('新增报名人成功')
            this.addParticipantBox = false
            await this.fetchOrderDetails()
          } catch (err) {
            this.$message.error(err.response?.data?.message || '新增报名人失败')
          }
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.participant-details {
  min-height: 100vh;

  .action-bar {
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);

    .left-actions {
      margin-right: auto;
    }

    .order-info {
      display: flex;
      align-items: center;
      gap: 24px;
      
      .info-item {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        .order-number {
          color: #666;
          font-size: 13px;
        }

        .activity-title {
          font-size: 13px;
          color: #666;
          font-weight: normal;
        }
      }
    }
  }

  .main-content {
    display: grid;
    grid-template-columns: 1fr 500px;
    gap: 20px;
    margin: 0 auto;
    max-width: 1800px;
    // padding: 0 20px;

    .left-column {

      .el-tabs {
        :deep(.el-tabs__header) {
          border-radius: 8px 8px 0 0;
          overflow: hidden;
        }
        :deep(.el-tabs__item.is-active) {
          border-radius: 8px 8px 0 0;
        }
      }

      .participant-tabs {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
        height: fit-content;

        :deep(.el-tabs__content) {
          padding: 20px;
        }

        .tab-label {
          display: flex;
          align-items: center;
          gap: 8px;

          .el-icon-delete,
          .el-icon-refresh-right {
            font-size: 14px;
            color: #909399;
            cursor: pointer;
            transition: all 0.2s;
            margin-left: 4px;

            &:hover {
              color: red;
            }
          }

          .el-icon-refresh-right:hover {
            color: #409EFF;
          }

          &.add-tab {
            color: #409EFF;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 4px;
            
            &:hover {
              color: #66b1ff;
            }

            .el-icon-plus {
              font-size: 14px;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .detail-card {
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);

    &:last-child {
      margin-bottom: 0;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #303133;
        position: relative;
        padding-left: 12px;
      }

      .enroller {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 3px;
          height: 16px;
          background: #409EFF;
          border-radius: 2px;
        }
      }

      .contact {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 3px;
          height: 16px;
          background: #67C23A;
          border-radius: 2px;
        }
      }

      .header-actions {
        .el-button {
          padding: 4px 8px;
          font-size: 13px;

          &:hover {
            background: rgba(64, 158, 255, 0.06);
          }

          & + .el-button {
            margin-left: 8px;
          }
        }
      }
    }

    .card-content {
      padding: 20px;
    }

    .info-grid {
      display: grid;
      grid-template-columns: repeat(2, minmax(160px, 1fr));
      gap: 16px;

      &.three-columns {
        grid-template-columns: repeat(3, 1fr);
      }

      .info-item {
        background: #f8f9fa;
        padding: 12px 16px;
        border-radius: 6px;

        &.full-width {
          grid-column: 1 / -1;
        }

        .label {
          display: block;
          color: #909399;
          font-size: 13px;
          margin-bottom: 4px;
        }

        .value {
          color: #303133;
          font-size: 14px;
          word-break: break-all;
        }

        .el-input,
        .el-select,
        .el-date-picker {
          margin-top: 4px;
        }

        .el-textarea {
          margin-top: 4px;
          
          :deep(.el-textarea__inner) {
            background-color: #fff;
          }
        }

        :deep(.el-input__inner) {
          background-color: #fff;
        }
      }
    }
  }

  .image-uploader {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;

    :deep(.el-upload) {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upload-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .el-icon-plus {
      font-size: 28px;
      color: #8c939d;
    }
  }
}

.id-card-images {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  
  .image-container {
    .image-label {
      display: block;
      color: #909399;
      font-size: 13px;
      margin-bottom: 8px;
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f8f9fa;
      border-radius: 6px;
      overflow: hidden;
      // width: 220px;
      height: 125px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.required {
  color: #F56C6C;
  margin-left: 2px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  vertical-align: middle;
}
</style>
