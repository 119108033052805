<template>
    <el-container class="home-container">
        <el-header>
            <div class="header-left" @click="welcomePage()">
                <h1 class="system-title">Art-Lens 后台管理系统</h1>
            </div>
            <div class="header-right">
                <div class="user-info">
                    <div class="profile-btn" @click="goToProfile"><i class="el-icon-user"></i>个人中心</div>
                    <span class="username">{{ username }}</span>
                    <span class="role-tag">{{ role }}</span>
                </div>
                <el-button class="logout-btn" size="small" plain @click="logout()"><i class="el-icon-switch-button"></i>退出登录</el-button>
            </div>
        </el-header>

        <el-container>
            <el-aside :width="isCollapse ? '64px' : '200px'">
                <div class="toggle-button" @click="togglCollapse()">{{ isCollapse ? "展开" : "折叠" }}</div>
                <el-menu
                    background-color="#333744"
                    text-color="#fff"
                    active-text-color="#409eff"
                    unique-opened
                    :collapse="isCollapse"
                    :collapse-transition='false'
                    router
                    :default-active='activePath'
                >
                    <el-menu-item index="/admin/welcome" @click="saveNavState('/admin/welcome')">
                        <i class="el-icon-s-home"></i>
                        <span>主页</span>
                    </el-menu-item>
                    <el-submenu v-for="menu in menuList" :key="menu.id" :index="menu.id">
                        <template slot="title">
                            <i :class="menu.icon"></i>
                            <span>{{ menu.title }}</span>
                        </template>
                        <el-menu-item v-for="subMenu in menu.children" :key="subMenu.path" :index="subMenu.path" @click="saveNavState(subMenu.path)">
                            <i :class="subMenu.icon"></i>
                            <span slot="title">{{ subMenu.title }}</span>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>

            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import { removeToken } from '@/utils/auth'
export default {
    name: 'Home',
    data(){
        return {
            isCollapse: false,
            activePath: '',
            username: '',
            role: '',
            roleList: [
                { id: 1, name: '超级管理员' },
                { id: 2, name: '管理员' },
                { id: 3, name: '财务' },
                { id: 4, name: '客服' },
                { id: 5, name: '运营' }
            ],
            menus: [
                {
                    id: '001',
                    title: '团队管理',
                    icon: 'el-icon-user-solid',
                    adminOnly: true,
                    children: [
                        {
                            path: '/admin/users',
                            title: '员工列表',
                            icon: 'el-icon-user'
                        }
                    ]
                },
                {
                    id: '002',
                    title: '活动管理',
                    icon: 'el-icon-s-promotion',
                    children: [
                        {
                            path: '/admin/activities',
                            title: '活动列表',
                            icon: 'el-icon-star-off'
                        },
                        {
                            path: '/admin/activity/create',
                            title: '活动创建',
                            icon: 'el-icon-plus'
                        }
                    ]
                },
                {
                    id: '003',
                    title: '属性管理',
                    icon: 'el-icon-s-tools',
                    children: [
                        {
                            path: '/admin/cities',
                            title: '活动城市',
                            icon: 'el-icon-location-information'
                        },
                        {
                            path: '/admin/features',
                            title: '活动特色',
                            icon: 'el-icon-star-off'
                        },
                        {
                            path: '/admin/tags',
                            title: '活动标签',
                            icon: 'el-icon-collection-tag'
                        },
                        {
                            path: '/admin/audiences',
                            title: '报名人群',
                            icon: 'el-icon-user'
                        }
                    ]
                },
                {
                    id: '004',
                    title: '首页轮播管理',
                    icon: 'el-icon-picture',
                    children: [
                        {
                            path: '/admin/banners',
                            title: '轮播列表',
                            icon: 'el-icon-picture-outline'
                        },
                        {
                            path: '/admin/banner/create',
                            title: '轮播创建',
                            icon: 'el-icon-plus'
                        }
                    ]
                },
                {
                    id: '005',
                    title: '活动瞬间管理',
                    icon: 'el-icon-camera-solid',
                    children: [
                        {
                            path: '/admin/events',
                            title: '活动瞬间列表',
                            icon: 'el-icon-camera'
                        },
                        {
                            path: '/admin/event/create',
                            title: '活动瞬间创建',
                            icon: 'el-icon-plus'
                        }
                    ]
                },
                {
                    id: '006',
                    title: '订单管理',
                    icon: 'el-icon-s-order',
                    children: [
                        {
                            path: '/admin/orders',
                            title: '订单列表',
                            icon: 'el-icon-document'
                        },
                        {
                            path: '/admin/order/create',
                            title: '订单创建',
                            icon: 'el-icon-plus'
                        }
                    ]
                },
                {
                    id: '007',
                    title: '报名人管理',
                    icon: 'el-icon-s-custom',
                    children: [
                        {
                            path: '/admin/enrolls',
                            title: '报名人列表',
                            icon: 'el-icon-user'
                        },
                        {
                            path: '/admin/enroll/export',
                            title: '报名人导出',
                            icon: 'el-icon-download'
                        },
                        {
                            path: '/admin/enroll/create',
                            title: '报名人录入',
                            icon: 'el-icon-plus'
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        menuList() {
            if (this.role === '超级管理员') {
                return this.menus;
            }
            return this.menus.filter(menu => !menu.adminOnly);
        }
    },
    created(){
        this.getUserInfo()
        this.activePath = window.sessionStorage.getItem('activePath') || '/admin/welcome'
    },
    methods: {
        getUserInfo(){
            this.$http({ method: 'get', url: 'users/me' }).then(res => {
                this.username = res.data.username
                this.role = this.roleList.find(role => role.id === res.data.role).name
            }).catch(err => {
                this.$message.error(err.response?.data?.message || '获取用户信息失败')
            })
        },
        welcomePage(){
            if (this.$route.path !== '/admin/welcome'){
                this.$router.push('/admin/welcome')
            }
        },
        togglCollapse(){
        this.isCollapse = !this.isCollapse
        },
        saveNavState(activePath){
            window.sessionStorage.setItem('activePath', activePath)
            this.activePath = activePath
        },
        goToProfile() {
            if (this.$route.path !== '/admin/aboutme'){
                this.$router.push('/admin/aboutme')
            }
        },
        logout(){
            removeToken()
            this.$router.push('/admin/login')
            this.$message.warning('已退出登录')
            window.sessionStorage.removeItem('activePath')
        }
    }
}
</script>

<style scoped>
.home-container {
    height: 100%;
}

.el-header {
    background-color: #373d41;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.12);
}

.header-left {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.system-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.user-info {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    padding-left: 40px;
    cursor: pointer;
}

.username {
    font-size: 14px;
}

.role-tag {
    background: rgba(255,255,255,0.1);
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
}

.el-button.is-plain {
    background: transparent;
    border-color: rgba(255,255,255,0.2);
    color: #fff;
    border-radius: 16px;
}

.el-button.is-plain:hover {
    background: rgba(255,255,255,0.1);
    border-color: rgba(255,255,255,0.3);
    color: #fff;
}

.el-aside {
    background-color: #333744;
    transition: 0.1s linear;
}

.el-aside .el-menu {
    border-right: none;
}

.el-main {
    background-color: #eaedf1;
    height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 20px;
}

.toggle-button {
    background-color: #4a5064;
    font-size: 10px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 2px;
    cursor: pointer;
}

.profile-btn {
    position: absolute;
    left: -100px;
    transform: translateX(35px) !important;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    padding: 6px 12px;
    border-radius: 16px;
    opacity: 0;
    transition: all 0.3s ease;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.user-info:hover .profile-btn {
    left: -110px;
    opacity: 1;
    transform: translateX(0);
}

.profile-btn:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
}

.profile-btn i {
    font-size: 14px;
}

.logout-btn i {
    margin-right: 4px;
}

</style>