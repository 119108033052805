<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>活动管理</el-breadcrumb-item>
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 搜索和添加 -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input placeholder="请输入活动名称" v-model="searchQuery.title_cn" clearable @clear="fetchActivities()" size="medium" >
            <el-button slot="append" icon="el-icon-search" @click="fetchActivities()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="searchQuery.type" placeholder="活动类型" @change="fetchActivities()" size="medium">
            <el-option v-for="(name, id) in activityTypes" :key="id" :label="name" :value="Number(id)" >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="searchQuery.status" placeholder="活动状态" @change="fetchActivities()" size="medium">
            <el-option v-for="(item, id) in statusMap" :key="id" :label="item.text" :value="Number(id)" >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="searchQuery.signup_status" placeholder="报名状态" @change="fetchActivities()" size="medium">
            <el-option :value="1" label="可报名"></el-option>
            <el-option :value="0" label="不可报名"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="searchQuery.hide_from_homepage" placeholder="主页隐藏" @change="fetchActivities()" size="medium">
            <el-option :value="false" label="正常"></el-option>
            <el-option :value="true" label="隐藏"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="searchQuery.is_deleted" placeholder="是否删除" @change="fetchActivities()" size="medium">
            <el-option :value="0" label="有效活动"></el-option>
            <el-option :value="1" label="失效活动"></el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="default" @click="resetSearch()" size="medium">重置</el-button>
        </el-col>
      </el-row>

      <!-- 活动列表 -->
      <el-table :data="activityList" style="width: 100%" border stripe>
        <el-table-column label="#" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="活动名称" align="center">
          <template slot-scope="scope">
            <span :class="scope.row.is_deleted === 1 ? 'deleted' : ''">{{ scope.row.title_cn }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动类型" width="100" align="center">
          <template slot-scope="scope">
            <span :class="scope.row.is_deleted === 1 ? 'deleted' : ''">{{ getActivityType(scope.row.type) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动时间" width="210" align="center">
          <template slot-scope="scope">
            <span :class="scope.row.is_deleted === 1 ? 'deleted' : ''">{{ scope.row.start_date }} 至 {{ scope.row.end_date }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="getStatusType(scope.row.status)">
              {{ getStatusText(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="报名状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.signup_status === 1 ? 'success' : 'info'">
              {{ scope.row.signup_status === 1 ? '可报名' : '不可报名' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="主页隐藏" width="100" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.hide_from_homepage" @change="toggleHide(scope.row.activity_id, scope.row.hide_from_homepage)"></el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column label="合同状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.contract_status === 1 ? 'success' : 'info'">
              {{ scope.row.contract_status === 1 ? '可签' : '不可签' }}
            </el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="medium" @click="activityDetail(scope.row.activity_id)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="onSizeChange"
        @current-change="onPageChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Activities',
  data() {
    return {
      activityList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      searchQuery: {
        title_cn: '',
        title_ug: '',
        type: null,
        status: null,
        signup_status: null,
        is_deleted: 0,
        contract_status: null
      },
      activityTypes: {
        1: '内地研学',
        2: '疆内研学',
        3: '主题研学',
        4: '订制团'
      },
      statusMap: {
        1: { text: '未开始', type: 'success' },
        2: { text: '进行中', type: 'success' },
        3: { text: '已结束', type: 'info' }
      }
    }
  },
  created() {
    this.fetchActivities()
  },
  methods: {
    fetchActivities() {
      this.$http({
        method: 'get',
        url: 'activities/search',
        params: {
          ...this.searchQuery,
          page: this.pageNum,
          size: this.pageSize
        }
      }).then(res => {
        if (!res.success) return this.$message.error(res.message)
        this.activityList = res.data
        this.total = res.total
      }).catch(err => {
        this.$message.error(err.response?.data?.message || '获取活动列表失败')
      })
    },

    resetSearch() {
      this.searchQuery = { title_cn: '', type: null, status: null, signup_status: null }
      this.fetchActivities()
    },

    getActivityType(type) {
      return this.activityTypes[type] || '未知类型'
    },

    getStatusText(status) {
      return this.statusMap[status]?.text || '未知状态'
    },

    getStatusType(status) {
      return this.statusMap[status]?.type || ''
    },

    toggleHide(activityId, hideValue) {
      this.$http.put(`activities/${activityId}`, { hide_from_homepage: hideValue }).then(res => {
        if (!res.success) return this.$message.error(res.message)
      }).catch(err => {
        this.$message.error(err.response?.data?.message || '更新失败')
      }).finally(() => {
        this.fetchActivities()
      })
    },

    activityDetail(id) {
      this.$router.push(`/admin/activity/details/${id}`)
    },

    onSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchActivities()
    },

    onPageChange(newPage) {
      this.pageNum = newPage
      this.fetchActivities()
    }
  }
}
</script>

<style scoped>
.el-card {
  margin-top: 15px;
}
.el-table {
  margin-top: 15px;
}
.el-pagination {
  margin-top: 15px;
}
.deleted {
  /* text-decoration: line-through; */
  color: #999;
}
</style> 