<template>
  <div class="activity-create">
    <!-- 顶部操作栏 -->
    <div class="action-bar">
      <div class="left-actions">
        <el-button icon="el-icon-back" size="medium" plain @click="goActivities">返回列表</el-button>
      </div>
      <div class="right-actions">
        <el-button type="primary" icon="el-icon-check" size="medium" @click="submitForm">创建活动</el-button>
      </div>
    </div>

    <!-- 主要内容区域 -->
    <div class="main-content">
      <div class="content-grid">
        <!-- 左侧内容列 -->
        <div class="left-column">
          <!-- 活动标题卡片 -->
          <el-card class="title-card">
            <div class="card-header">
              <span>活动标题</span>
            </div>
            <div class="title-grid">
              <div class="title-cn">
                <div class="title-label">中文标题</div>
                <el-input v-model="activityForm.title_cn" placeholder="请输入中文标题"></el-input>
              </div>
              <div class="title-ug">
                <div class="title-label">ئۇيغۇرچە تېمىسى</div>
                <el-input v-model="activityForm.title_ug" placeholder="ئۇيغۇرچە پائالىيەت تېمىسىنى كىرگۈزۈڭ"></el-input>
              </div>
            </div>
          </el-card>

          <!-- 基本信息卡片 -->
          <el-card class="info-card">
            <div class="card-header">
              <span>基本信息</span>
            </div>
            <div class="info-grid">
              <div class="info-column">
                <div class="info-item">
                  <div class="info-label">活动类型</div>
                  <el-select v-model="activityForm.type" placeholder="请选择活动类型">
                    <el-option
                      v-for="(name, id) in activityTypes"
                      :key="id"
                      :label="name"
                      :value="Number(id)">
                    </el-option>
                  </el-select>
                </div>

                <div class="info-item">
                  <div class="info-label">活动时间</div>
                  <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
                </div>

                <div class="info-item">
                  <div class="info-label">报名状态</div>
                  <div class="switch-wrapper">
                    <el-switch
                      v-model="activityForm.signup_status"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                    <span class="switch-text">{{ activityForm.signup_status === 1 ? '可报名' : '不可报名' }}</span>
                  </div>
                </div>
              </div>

              <div class="info-column">
                <div class="info-item">
                  <div class="info-label">活动状态</div>
                  <el-select v-model="activityForm.status" placeholder="请选择活动状态">
                    <el-option
                      v-for="(status, id) in statusMap"
                      :key="id"
                      :label="status.text"
                      :value="Number(id)">
                    </el-option>
                  </el-select>
                </div>

                <div class="info-item">
                  <div class="info-label">报名截止</div>
                  <el-date-picker
                    v-model="activityForm.signup_deadline"
                    type="datetime"
                    placeholder="选择报名截止时间"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </div>

                <div class="info-item">
                  <div class="info-label">合同状态</div>
                  <div class="switch-wrapper">
                    <el-switch
                      v-model="activityForm.contract_status"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                    <span class="switch-text">{{ activityForm.contract_status === 1 ? '可签' : '不可签' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-card>

          <!-- 活动描述卡片 -->
          <el-card class="desc-card">
            <div class="card-header">
              <span>活动描述</span>
            </div>
            <div class="desc-grid">
              <div class="desc-cn">
                <div class="desc-label">中文描述</div>
                <el-input
                  type="textarea"
                  v-model="activityForm.description_cn"
                  :rows="6"
                  :maxlength="2000"
                  show-word-limit
                  placeholder="请输入中文描述">
                </el-input>
              </div>
              <div class="desc-ug">
                <div class="desc-label">ئۇيغۇرچە تونۇشتۇرۇلىشى</div>
                <el-input
                  type="textarea"
                  v-model="activityForm.description_ug"
                  :rows="6"
                  :maxlength="8000"
                  show-word-limit
                  placeholder="ئۇيغۇرچە پائالىيەت تونۇشتۇرۇلىشىنى كىرگۈزۈڭ">
                </el-input>
              </div>
            </div>
          </el-card>

          <!-- 报名人群与价格卡片 -->
          <el-card class="audience-card">
            <div class="card-header">
              <span>报名人群与价格</span>
            </div>
            <div class="audience-grid">
              <div class="audience-header">
                <!-- 最多参与人数部分 -->
                <div class="audience-section max-participants-section">
                  <div class="audience-label">最多参与人数</div>
                  <el-input-number
                    v-model="activityForm.max_participants"
                    :min="1"
                    :max="500"
                    class="participants-input">
                  </el-input-number>
                </div>

                <!-- 价格部分 -->
                <div class="audience-section price-section">
                  <div class="audience-label">活动价格</div>
                  <el-input-number
                    v-model="activityForm.price"
                    :min="0"
                    :controls="false"
                    class="price-input"
                    placeholder="请输入价格">
                  </el-input-number>
                </div>
              </div>

              <div class="audience-body">
                <!-- 活动城市部分 -->
                <div class="audience-section city-section">
                  <div class="audience-label">活动城市</div>
                  <el-select
                    v-model="activityForm.city_ids"
                    multiple
                    filterable
                    placeholder="请选择活动城市"
                    class="city-select">
                    <el-option
                      v-for="city in cityOptions"
                      :key="city.city_id"
                      :label="`${city.name_cn} / ${city.name_ug}`"
                      :value="city.city_id">
                    </el-option>
                  </el-select>
                </div>

                <!-- 报名人群部分 -->
                <div class="audience-section audience-type-section">
                  <div class="audience-label">报名人群</div>
                  <el-select
                    v-model="activityForm.audience_id"
                    placeholder="请选择报名人群"
                    class="audience-select">
                    <el-option
                      v-for="audience in audienceOptions"
                      :key="audience.audience_id"
                      :label="`${audience.name_cn} / ${audience.name_ug}`"
                      :value="audience.audience_id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </el-card>
        </div>

        <!-- 右侧内容列 -->
        <div class="right-column">
          <!-- 图片上传卡片 -->
          <el-card class="images-card">
            <div class="card-header">
              <span>活动图片</span>
            </div>
            <div class="images-wrapper">
              <!-- 封面图 -->
              <div class="image-section">
                <div class="cover-grid">
                  <div class="cover-item">
                    <div class="label-cn">中文封面图</div>
                    <div class="image-container cover-shape">
                      <template v-if="activityForm.cover_image_cn">
                        <el-image :src="getImageUrl(activityForm.cover_image_cn)">
                        </el-image>
                        <div class="image-actions">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            class="delete-button"
                            circle 
                            size="mini" 
                            @click="removeImage('cover', 'cn')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_cn')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传封面图</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                  
                  <div class="cover-item">
                    <div class="label-ug">ئۇيغۇرچە مۇقاۋا</div>
                    <div class="image-container cover-shape">
                      <template v-if="activityForm.cover_image_ug">
                        <el-image :src="getImageUrl(activityForm.cover_image_ug)">
                        </el-image>
                        <div class="image-actions">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            class="delete-button"
                            circle 
                            size="mini" 
                            @click="removeImage('cover', 'ug')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_ug')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">مۇقاۋىنى چىقىرىڭ</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 轮播图 -->
              <div class="image-section">
                <div class="carousel-grid">
                  <div class="carousel-column">
                    <div class="label-cn">中文轮播图</div>
                      <draggable 
                        class="image-list"
                        v-model="activityForm.carousel_images_cn">
                        <div v-for="(url, index) in activityForm.carousel_images_cn" 
                             :key="index" 
                             class="image-item carousel-shape">
                          <el-image :src="getImageUrl(url)"></el-image>
                          <div class="image-actions">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              class="delete-button"
                              circle 
                              size="mini"
                              @click="removeImage('carousel', 'cn', index)">
                            </el-button>
                          </div>
                        </div>
                        <div class="upload-box carousel-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('carousel_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'carousel_cn')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传轮播图</div>
                          </el-upload>
                        </div>
                      </draggable>
                  </div>
                  
                  <div class="carousel-column">
                    <div class="label-ug">ئۇيغۇرچە ئايلانما تەگلىكلەر</div>
                      <draggable 
                        class="image-list"
                        v-model="activityForm.carousel_images_ug">
                        <div v-for="(url, index) in activityForm.carousel_images_ug" 
                             :key="index" 
                             class="image-item carousel-shape">
                          <el-image :src="getImageUrl(url)"></el-image>
                          <div class="image-actions">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              class="delete-button"
                              circle 
                              size="mini"
                              @click="removeImage('carousel', 'ug', index)">
                            </el-button>
                          </div>
                        </div>
                        <div class="upload-box carousel-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('carousel_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'carousel_ug')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">تەگلىكلەرنى چىقىرىڭ</div>
                          </el-upload>
                        </div>
                      </draggable>
                  </div>
                </div>
              </div>

              <!-- 海报图 -->
              <div class="image-section">
                <div class="poster-grid">
                  <div class="poster-column">
                    <div class="label-cn">中文长图海报</div>
                      <draggable 
                        class="image-list"
                        v-model="activityForm.poster_images_cn">
                        <div v-for="(url, index) in activityForm.poster_images_cn" 
                             :key="index" 
                             class="image-item poster-shape">
                          <el-image :src="getImageUrl(url)"></el-image>
                          <div class="image-actions">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              class="delete-button"
                              circle 
                              size="mini"
                              @click="removeImage('poster', 'cn', index)">
                            </el-button>
                          </div>
                        </div>
                        <div class="upload-box poster-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('poster_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'poster_cn')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传长图海报</div>
                          </el-upload>
                        </div>
                      </draggable>
                  </div>
                  
                  <div class="poster-column">
                    <div class="label-ug">ئۇيغۇرچە ئۇزۇن ئېلانلار</div>
                      <draggable 
                        class="image-list"
                        v-model="activityForm.poster_images_ug"
                        >
                        <div v-for="(url, index) in activityForm.poster_images_ug" 
                             :key="index" 
                             class="image-item poster-shape">
                          <el-image :src="getImageUrl(url)"></el-image>
                          <div class="image-actions">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              class="delete-button"
                              circle 
                              size="mini"
                              @click="removeImage('poster', 'ug', index)">
                            </el-button>
                          </div>
                        </div>
                        <div class="upload-box poster-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('poster_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'poster_ug')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">ئېلانلارنى چىقىرىڭ</div>
                          </el-upload>
                        </div>
                      </draggable>
                  </div>
                </div>
              </div>
            </div>
          </el-card>

          <!-- 标签与特色卡片 -->
          <el-card class="meta-card">
            <div class="card-header">
              <span>标签与特色</span>
            </div>
            <div class="meta-grid">
              <!-- 活动标签 -->
              <div class="meta-section">
                <div class="meta-title">活动标签</div>
                <div class="tag-editor">
                  <div class="tag-selector">
                    <el-select
                      v-model="activityForm.tag_id"
                      placeholder="请选择标签"
                      class="tag-select">
                      <el-option
                        v-for="tag in tagOptions"
                        :key="tag.tag_id"
                        :label="`${tag.name_cn} / ${tag.name_ug}`"
                        :value="tag.tag_id">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <!-- 活动特色 -->
              <div class="meta-section">
                <div class="meta-title">活动特色</div>
                <div class="tag-editor">
                  <div class="tag-selector">
                    <el-select
                      v-model="activityForm.feature_ids"
                      multiple
                      filterable
                      placeholder="请选择活动特色"
                      class="tag-select">
                      <el-option
                        v-for="feature in featureOptions"
                        :key="feature.feature_id"
                        :label="`${feature.name_cn} / ${feature.name_ug}`"
                        :value="feature.feature_id">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { IMAGE_URL, BASE_URL } from '@/utils/urls'
import draggable from 'vuedraggable'

export default {
  name: 'ActivityCreate',
  components: {
    draggable
  },
  data() {
    return {
      imageURL: IMAGE_URL,
      serverURL: BASE_URL,
      dateRange: [],
      activityTypes: {
        1: '内地研学',
        2: '疆内研学',
        3: '主题研学',
        4: '订制团'
      },
      statusMap: {
        1: { text: '未开始', type: 'info' },
        2: { text: '进行中', type: 'success' },
        3: { text: '已结束', type: 'danger' }
      },
      activityForm: {
        title_cn: '',
        title_ug: '',
        type: 1,
        start_date: '',
        end_date: '',
        signup_deadline: '',
        max_participants: 100,
        description_cn: '',
        description_ug: '',
        status: 1,
        signup_status: 0,
        signup_countdown: 0,
        cover_image_cn: '',
        cover_image_ug: '',
        carousel_images_cn: [],
        carousel_images_ug: [],
        poster_images_cn: [],
        poster_images_ug: [],
        contract_status: 0,
        feature_ids: [],
        tag_id: '',
        city_ids: [],
        audience_id: '',
        price: 0
      },
      cityOptions: [],
      audienceOptions: [],
      tagOptions: [],
      featureOptions: []
    }
  },
  created() {
    this.getInitialData()
  },
  methods: {
    async getInitialData() {
      try {
        const [cities, audiences, tags, features] = await Promise.all([
          this.$http.get('cities'),
          this.$http.get('audiences'),
          this.$http.get('tags'),
          this.$http.get('features')
        ])
        
        this.cityOptions = cities.data
        this.audienceOptions = audiences.data
        this.tagOptions = tags.data
        this.featureOptions = features.data
      } catch (err) {
        this.$message.error('获取初始数据失败')
      }
    },

    goActivities() {
      this.$router.push('/admin/activities')
    },

    getUploadUrl(imageType) {
      return `${this.serverURL}/api/v1/activities/images/${imageType}?activity_name=${this.activityForm.title_cn || 'temp'}`
    },

    getHeaders() {
      return {
        'Authorization': `Bearer ${getToken()}`
      }
    },

    getImageUrl(path) {
      return `${this.imageURL}${path}`
    },

    beforeUpload(file) {
      const isImage = /^image\/(jpeg|png|gif|jpg)$/.test(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传 JPG/PNG/GIF 格式的图片!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    uploaded(response, type) {
      if (!response.data) {
        this.$message.error('上传失败')
        return
      }

      const imageUrl = response.data
      const [imageType, lang] = type.split('_')

      switch (imageType) {
        case 'cover':
          this.activityForm[`cover_image_${lang}`] = imageUrl
          break
        case 'carousel':
          if (!this.activityForm[`carousel_images_${lang}`]) {
            this.activityForm[`carousel_images_${lang}`] = []
          }
          this.activityForm[`carousel_images_${lang}`].push(imageUrl)
          break
        case 'poster':
          if (!this.activityForm[`poster_images_${lang}`]) {
            this.activityForm[`poster_images_${lang}`] = []
          }
          this.activityForm[`poster_images_${lang}`].push(imageUrl)
          break
      }
    },

    uploadError(err) {
      this.$message.error('图片上传失败')
    },

    removeImage(type, lang, index) {
      switch (type) {
        case 'cover':
          this.activityForm[`cover_image_${lang}`] = ''
          break
        case 'carousel':
          this.activityForm[`carousel_images_${lang}`].splice(index, 1)
          break
        case 'poster':
          this.activityForm[`poster_images_${lang}`].splice(index, 1)
          break
      }
    },

    validateForm() {
      if (!this.activityForm.title_cn || !this.activityForm.title_ug) {
        this.$message.error('请填写活动标题')
        return false
      }

      if (!this.dateRange || this.dateRange.length !== 2) {
        this.$message.error('请选择活动时间')
        return false
      }

      if (!this.activityForm.signup_deadline) {
        this.$message.error('请选择报名截止时间')
        return false
      }

      if (!this.activityForm.city_ids.length) {
        this.$message.error('请选择活动城市')
        return false
      }

      if (!this.activityForm.audience_id) {
        this.$message.error('请选择报名人群')
        return false
      }

      if (!this.activityForm.price) {
        this.$message.error('请输入活动价格')
        return false
      }

      if (!this.activityForm.cover_image_cn && !this.activityForm.cover_image_ug) {
        if (this.activityForm.signup_status === 1) {
          this.$message.error('未上传封面图，报名状态请改为不可报名')
          return false
        }
      }
      
      if (!this.activityForm.carousel_images_cn.length && !this.activityForm.carousel_images_ug.length) {
        if (this.activityForm.signup_status === 1) {
          this.$message.error('未上传轮播图，报名状态请改为不可报名')
          return false
        }
      }

      return true
    },

    async submitForm() {
      if (!this.validateForm()) return

      try {
        const formData = { ...this.activityForm }
        formData.start_date = this.dateRange[0]
        formData.end_date = this.dateRange[1]

        const { data: res } = await this.$http.post('activities', formData)
        
        if (res.activity_id) {
          this.$message.success('活动创建成功')
          this.$router.push('/admin/activities')
        } else {
          this.$message.error('活动创建失败')
        }
      } catch (err) {
        this.$message.error(err.response?.data?.message || '活动创建失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.activity-create {
  min-height: 100vh;

  .content-grid {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: 20px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
      
      .right-column {
        order: 2;
      }
      
      .left-column {
        order: 1;
        transform: translateY(20px);
      }
    }
  }
}


.el-card {
  &.title-card,
  &.desc-card,
  &.info-card,
  &.audience-card,
  &.meta-card,
  &.images-card {
    margin-bottom: 20px;
    border-radius: 8px;

    .card-header {
      position: relative;
      padding: 8px 20px;
      border-radius: 8px;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #1f2937;
        padding-left: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 12px;
          background: #1890ff;
          border-radius: 1px;
        }
      }

      .header-actions {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
    }
  }
}

// 顶部操作栏样式
.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  border-radius: 8px;

  .date-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    color: #888;
    font-size: 13px;
  }
}

// 标题卡片样式
.title-card {
  .title-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    .title-cn, .title-ug {
      .title-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      .title-content {
        background: #fafafa;
        padding: 7px 15px;
        border-radius: 4px;
        border: 1px solid #f0f0f0;

        .title-text {
          font-size: 14px;
          color: #262626;
          line-height: 1.6;
        }
      }
    }

    .title-ug {
      direction: rtl;

      .title-label {
        text-align: right;
      }

      .el-input__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 描述卡片样式
.desc-card {
  .desc-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    .desc-cn, .desc-ug {
      .desc-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      .desc-content {
        background: #fafafa;
        padding: 6px 15px;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        min-height: 80px;

        .description-content {
          font-size: 14px;
          color: #262626;
        }
      }
    }

    .desc-ug {
      direction: rtl;

      .desc-label {
        text-align: right;
      }

      .el-textarea__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 基本信息卡片样式
.info-card {
  .info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    &.editing {
      grid-template-columns: 1fr;
    }

    .info-column {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .info-item {
        background: #fafafa;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid #f0f0f0;

        .info-label {
          font-size: 13px;
          color: #666;
          margin-bottom: 8px;
        }

        .switch-wrapper {
        display: flex;
        align-items: center;
        gap: 6px;

        .switch-text {
            font-size: 13px;
            color: #666;
        }
        }
      }
    }
  }
}

// 报名人群与价格卡片样式
.audience-card {
  .audience-grid {
    padding: 20px;

    .audience-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      margin-bottom: 24px;
    }

    .audience-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }

    .audience-section {
      background: #fafafa;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #f0f0f0;

      .audience-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      &.price-section {
        .price-input {
          width: 100%;
        }
      }

      &.max-participants-section {
        .participants-input {
          width: 100%;
        }
      }

      &.city-section,
      &.audience-type-section {
        .city-select,
        .audience-select {
          width: 100%;
        }
      }
    }
  }
}

// 标签与特色卡片样式
.meta-card {
  .meta-grid {
    padding: 20px;

    .meta-section {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      .meta-title {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      .tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      .tag-editor {

        .tag-selector {
          width: 100%;

          .el-select {
            width: 100%;
          }
        }
      }
    }
  }
}

// 图片卡片样式
.images-card {
  .images-wrapper {
    padding: 20px;
  }

  .image-section {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .cover-grid,
  .carousel-grid,
  .poster-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .label-cn {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: ltr;
  }

  .label-ug {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: rtl;
  }

  .image-container {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    .image-actions {
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 1;
    }

    &:hover .image-actions {
      opacity: 1;
    }
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 8px;

    .image-item {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      background-color: #f8f8f8;

      .image-actions {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
      }

      &:hover .image-actions {
        opacity: 1;
      }
    }

    .carousel-shape {
      aspect-ratio: 15/10;
    }

    .poster-shape {
      aspect-ratio: 8/16;
    }
  }
}

.cover-shape {
  aspect-ratio: 16/10;
}

.upload-box {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fafafa;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }

  .image-uploader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .el-icon-plus {
    font-size: 20px;
    color: #8c939d;
    margin-bottom: 8px;
  }

  .upload-text {
    font-size: 12px;
    color: #666;
  }
}

.el-button {
  padding: 10px 20px;
  transition: all 0.2s ease;

  &.delete-button {
    padding: 7px !important;
  }

  &--text {
    padding: 4px 8px;
    font-size: 13px;

    &:hover {
      background: rgba(24, 144, 255, 0.06);
      color: #1890ff;
    }
  }
}

.el-tag {
  margin: 0;
  padding: 0 10px;
}

.el-input :deep(.el-input__inner) {
  font-family: inherit;
}

.el-textarea :deep(.el-textarea__inner) {
  font-family: inherit;
}
</style>