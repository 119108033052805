<template>
  <div class="public-container">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-particles" id="particles-js"></div>
      <div class="hero-content">
        <div class="hero-text">
          <div class="brand-container">
            <div class="brand-logo">
              <img 
                src="@/assets/artlens.jpg" 
                alt="ArtLens Logo"
                @click="handleLogoClick"
              />
            </div>
            <div class="brand-name">
              <span class="text-gradient">Art-Lens</span>
              <span class="text-gradient">上海阿特兰斯</span>
            </div>
          </div>
          <div class="tagline">
            <div class="typing-text" ref="typingText"></div>
          </div>
        </div>
      </div>
    </section>

    <!-- Company Introduction -->
    <section class="company-intro">
      <h2>Art-Lens</h2>
      <div class="intro-content">
        <div class="intro-text">
          <div class="intro-text-container">
            <div class="intro-paragraph">
              <div class="quote-mark left">❝</div>
              <p>上海阿特兰斯文化传播有限公司自创立之初，就凭借其独到的文化视角和前瞻性的创新思维。在大型文艺比赛、青少年经验分享会以及公益活动的策划与执行领域崭露头角。公司凭借卓越的组织能力和对文化多样性的深刻理解，不仅获得了广大青年的热情参与，也赢得了社会各界的高度评价和广泛赞誉。</p>
            </div>
            <div class="intro-paragraph">
              <p>我们致力于挖掘和传承民族文化，同时积极探索文化创新的新途径，为新疆各民族青少年提供丰富多样的文化体验和学习机会。通过不断的努力和创新，上海阿特兰斯文化传播有限公司将成为推动边疆青少年文化繁荣发展的重要力量。</p>
              <div class="quote-mark right">❞</div>
            </div>
          </div>
        </div>
        <div class="intro-image">
          <div class="image-wrapper">
            <div class="image-frame">
              <img src="@/assets/public/Company-Image.jpg" alt="Company Image" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Founder and Team -->
    <section class="team">
      <h2>阿特兰斯团队</h2>
      <div class="team-card">
        <div class="team-layout">
          <!-- Founder Column -->
          <div class="founder-column">
            <div class="founder-profile">
              <div class="founder-image">
                <img src="@/assets/public/Founder.jpg" alt="Founder" />
              </div>
              <div class="founder-info">
                <h3>帕哈尔丁·帕尔哈提</h3>
                <span class="founder-role">创始人 / 执行总监</span>
                <p class="founder-education">俄罗斯太平洋大学交通运输类双学位学士</p>
              </div>
            </div>
          </div>

          <!-- Vertical Divider -->
          <div class="vertical-divider"></div>

          <!-- Team Column -->
          <div class="team-column">
            <!-- Directors Grid -->
            <div class="team-grid directors-grid">
              <div v-for="member in directors" :key="member.name" class="member-card">
                <div class="member-image">
                  <img :src="member.image" :alt="member.name" />
                </div>
                <div class="member-info">
                  <h4>{{ member.name }}</h4>
                  <p>{{ member.title }}</p>
                </div>
              </div>
            </div>

            <!-- Other Members Grid -->
            <div class="team-grid members-grid">
              <div v-for="member in otherMembers" :key="member.name" class="member-card">
                <div class="member-image">
                  <img :src="member.image" :alt="member.name" />
                </div>
                <div class="member-info">
                  <h4>{{ member.name }}</h4>
                  <p>{{ member.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Special Activities -->
    <section class="activities">
      <h2>特色活动</h2>
      <div class="activities-container">
        <!-- Main Activity Card -->
        <div class="activity-card main-card">
          <div class="card-content">
            <div class="activity-header">
              <h3>阿特兰斯游学</h3>
              <p class="subtitle">探索 · 成长 · 蜕变</p>
            </div>
            
            <div class="activity-description">
              <p>阿特兰斯游学是由上海阿特兰斯文化传播有限公司主办，乌鲁木齐广播电视台全程进行跟拍纪实的国内青少年游学活动。</p>
            </div>

            <div class="features-list">
              <div class="feature-item">
                <div class="feature-icon">🎓</div>
                <span>学术探索，进入名校</span>
              </div>
              <div class="feature-item">
                <div class="feature-icon">👥</div>
                <span>大咖及优秀学长分享交流</span>
              </div>
              <div class="feature-item">
                <div class="feature-icon">🎨</div>
                <span>"大美新疆"绘画主题游学</span>
              </div>
              <div class="feature-item">
                <div class="feature-icon">🌆</div>
                <span>"都市探索"上海游学</span>
              </div>
              <div class="feature-item">
                <div class="feature-icon">✈️</div>
                <span>"带你飞跃京沪梦想"</span>
              </div>
            </div>
          </div>

          <div class="activity-gallery">
            <div class="gallery-grid">
              <img v-for="(img, index) in academicImages" 
                   :key="index" 
                   :src="img" 
                   :alt="'Academic Image ' + (index + 1)"
                   @click="showLightbox(img)" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Partners Carousel -->
    <section class="partners">
      <h2>合作支持</h2>
      <div class="partners-carousel" ref="partnersCarousel">
        <div class="carousel-container" :style="{ transform: 'translateX(' + carouselPosition + 'px)' }">
          <img v-for="(partner, index) in partners" 
               :key="index" 
               :src="partner" 
               :alt="'Partner ' + (index + 1)" />
        </div>
      </div>
      <div class="background-decoration"></div>
    </section>

    <!-- Contact QR Codes -->
    <section class="contact">
      <h2>联系我们</h2>
      <div class="qr-grid">
        <div class="qr-code" v-for="qr in qrCodes" :key="qr.title">
          <div class="qr-wrapper">
            <img :src="qr.image" :alt="qr.title" />
            <p>{{ qr.title }}</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <section class="footer">
      <div class="footer-content">
        <div class="footer-left">
          <div class="company-info">
            <img src="@/assets/artlens.jpg" alt="ArtLens Logo" class="footer-logo"/>
            <div class="company-details">
              <h4>上海阿特兰斯文化传播有限公司</h4>
              <p>创新思维 · 文化传承 · 青年发展</p>
            </div>
          </div>
        </div>
        <div class="footer-right">
          <div class="thanks-bubbles">
            <span v-for="(thanks, index) in thanksMessages" 
                  :key="index" 
                  class="thanks-bubble">
              {{ thanks }}
            </span>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>© 2025 上海阿特兰斯文化传播有限公司</p>
        <p><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2024104186号</a></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Public',
  data() {
    return {
      carouselPosition: 0,
      directors: [
        { name: '米合古丽·艾孜孜', image: require('@/assets/public/Mihrigul.jpg'), title: '执行董事成员' },
        { name: '艾克赛·卡门', image: require('@/assets/public/Ehsan.jpg'), title: '执行董事成员' },
        { name: '努尔古丽', image: require('@/assets/public/Nurgul.jpg'), title: '执行董事成员' },
        { name: '徐放', image: require('@/assets/public/Xufang.jpg'), title: '执行董事成员' },
      ],
      otherMembers: [
        { name: '依丽米兰木', image: require('@/assets/public/Elmirem.jpg'), title: '游学出行板块风控经理' },
        { name: '阿依加玛力·艾买提江', image: require('@/assets/public/Ayjamal.jpg'), title: '数据分析师｜资深项目经理' },
        { name: '阿卜杜拉·阿卜都如苏力', image: require('@/assets/public/Abdulla.jpg'), title: '宣传负责人' }
      ],
      partners: [
        require('@/assets/public/support/support1.jpg'),
        require('@/assets/public/support/support2.jpg'),
        require('@/assets/public/support/support3.jpg'),
        require('@/assets/public/support/support4.jpg'),
        require('@/assets/public/support/support5.jpg'),
        require('@/assets/public/support/support6.jpg'),
        require('@/assets/public/support/support7.jpg'),
        require('@/assets/public/support/support8.jpg'),
        require('@/assets/public/support/support9.jpg'),
        require('@/assets/public/support/support10.jpg'),
        require('@/assets/public/support/support11.jpg'),
        require('@/assets/public/support/support12.jpg'),
        require('@/assets/public/support/support13.jpg'),
        require('@/assets/public/support/support14.jpg'),
        require('@/assets/public/support/support15.jpg'),
        require('@/assets/public/support/support16.jpg'),
        require('@/assets/public/support/support17.jpg'),
        require('@/assets/public/support/support18.jpg')
      ],
      qrCodes: [
        { title: '抖音号', image: require('@/assets/public/Douyin.jpg') },
        { title: '公众号', image: require('@/assets/public/Account.jpg') },
        { title: '客服➀', image: require('@/assets/public/Service1.jpg') },
        { title: '客服➁', image: require('@/assets/public/Service2.jpg') },
        { title: '视频号', image: require('@/assets/public/Channels.jpg') },
        { title: '小程序', image: require('@/assets/public/mini.jpg') }
      ],
      academicImages: [
        require('@/assets/public/Act1.jpg'),
        require('@/assets/public/Act2.jpg'),
        require('@/assets/public/Act3.jpg'),
        require('@/assets/public/Act4.jpg'),
        require('@/assets/public/Act5.jpg'),
        require('@/assets/public/Act6.jpg')
      ],
      thanksMessages: [
        '谢谢',
        'رەھمەت',
        'Thank you',
        'Спасибо',
        'Danke',
        'Merci',
        'Gracias',
        'ありがとう'
      ],
      clickCount: 0,
      clickTimer: null
    }
  },
  mounted() {
    this.startCarousel()
    this.initParticles()
    this.initTypingEffect()
  },
  methods: {
    startCarousel() {
      setInterval(() => {
        this.carouselPosition -= 2;
        const totalWidth = this.partners.length * 130;
        if (Math.abs(this.carouselPosition) >= totalWidth) {
          this.carouselPosition = 0;
        }
      }, 30);
    },
    initParticles() {
      require('particles.js')
      particlesJS('particles-js', {
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 800
            }
          },
          color: { value: "#ffffff" },
          shape: { type: "circle" },
          opacity: {
            value: 0.3,
            random: true,
            animation: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 3,
            random: true,
            animation: {
              enable: true,
              speed: 2,
              size_min: 0.1,
              sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#00A8E8",
            opacity: 0.2,
            width: 1
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab"
            },
            onclick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 0.5
              }
            },
            push: {
              particles_nb: 4
            }
          }
        },
        retina_detect: true
      })
    },
    initTypingEffect() {
      const texts = [
        '探索未知领域 做最耀的自己',
        'ئىلىم ئوكيانىدا ئۈزۈپ، ئەڭ يېقىن ئۆزۈمگە يېتىمەن.'
      ]
      let currentIndex = 0
      let isTyping = true
      
      const typeText = async (text) => {
        if (!this.$refs.typingText) return false
        const element = this.$refs.typingText
        
        try {
          // Clear with fade out effect
          await new Promise(resolve => {
            element.style.opacity = '0'
            setTimeout(resolve, 300)
          })
          element.textContent = ''
          element.style.opacity = '1'
          
          // Type each character with random delay for natural feel
          for (let char of text) {
            if (!isTyping || !this.$refs.typingText) return false
            element.textContent += char
            // Random delay between 40-100ms for more natural typing
            await new Promise(resolve => setTimeout(resolve, 40 + Math.random() * 60))
          }
          
          // Keep text visible for longer
          await new Promise(resolve => setTimeout(resolve, 2000))
          
          // Smoothly delete each character
          for (let i = text.length; i >= 0; i--) {
            if (!isTyping || !this.$refs.typingText) return false
            element.textContent = text.substring(0, i)
            // Random delay between 40-100ms for more natural deleting
            await new Promise(resolve => setTimeout(resolve, 40 + Math.random() * 60))
          }

          // Fade out after deletion
          await new Promise(resolve => {
            element.style.transition = 'opacity 0.5s ease'
            element.style.opacity = '0'
            setTimeout(resolve, 500)
          })
          element.textContent = ''

          return true
        } catch (error) {
          console.error('Typing effect error:', error)
          return false
        }
      }

      const loop = async () => {
        await this.$nextTick()
        
        while (isTyping && this.$refs.typingText) {
          try {
            const success = await typeText(texts[currentIndex])
            if (success) {
              currentIndex = (currentIndex + 1) % texts.length
            } else {
              await new Promise(resolve => setTimeout(resolve, 1000))
            }
          } catch (error) {
            console.error('Typing loop error:', error)
            await new Promise(resolve => setTimeout(resolve, 1000))
          }
        }
      }

      this.$nextTick(() => {
        if (this.$refs.typingText) {
          this.$refs.typingText.style.transition = 'opacity 0.3s ease'
          loop()
        }
      })

      this.$once('hook:beforeDestroy', () => {
        isTyping = false
      })
    },
    handleLogoClick() {
      this.clickCount++;
      clearTimeout(this.clickTimer);
      
      // 在3秒内点击logo 3次进入管理界面
      this.clickTimer = setTimeout(() => {
        if (this.clickCount >= 3) {
          window.location.href = '/#/admin'
        }
        this.clickCount = 0;
      }, 1000);
    }
  }
}
</script>

<style lang="less" scoped>
// 定义通用变量
@spacing-base: 24px;
@section-spacing: 80px;
@container-padding: 40px;
@container-padding-mobile: 20px;

.section-title() {
  text-align: center;
  font-size: 42px;
  margin-bottom: @spacing-base * 3;
  color: #2c3e50;
  position: relative;
  font-weight: 300;
  letter-spacing: 2px;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #c17f59, #e2b396);
    border-radius: 2px;
  }
}

.section-container() {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.public-container {
  width: 100%;
  overflow-x: hidden;
  background: #fff;
  color: #333;
  position: relative;

  section {
    position: relative;
    z-index: 1;

    h2 {
      .section-title();
    }

    .section-container();

    & + section {
      margin-top: @section-spacing;
    }
  }
}

.hero {
  height: 100vh;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at center,
      rgba(100,255,218,0.1) 0%,
      transparent 70%
    );
  }

  .hero-particles {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  .hero-content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    animation: fadeIn 1s ease;

    .hero-text {
      text-align: center;
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
      animation: fadeInUp 1s ease;

      .brand-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 4rem;

        .brand-logo {
          margin-bottom: 2.5rem;
          
          img {
            width: 150px;
            height: 150px;
            border: 4px solid rgba(100,255,218,0.15);
            border-radius: 50%;
            transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.01s ease;
            
            &:hover {
              border-color: rgba(100,255,218,0.4);
              box-shadow: 0 0 10px rgba(100,255,218,0.4);
            }

            &:active {
              transform: scale(0.99);
            }
          }
        }

        .brand-name {
          .text-gradient {
            display: block;
            font-weight: 200;
            letter-spacing: 2px;
            color: #ffffff;
            text-shadow: 0 2px 15px rgba(0,0,0,0.3);
            
            &:not(.chinese) {
              font-size: 4.2rem;
              margin-bottom: 1rem;
            }
            
            &.chinese {
              font-size: 2.8rem;
              opacity: 0.85;
            }
          }
        }
      }

      .tagline {
        margin-top: 4rem;
        
        .typing-text {
          font-size: 1.8rem;
          color: rgba(255, 255, 255, 0.9);
          font-weight: 200;
          line-height: 1.8;
          min-height: 3.2rem;
          text-shadow: 0 2px 10px rgba(0,0,0,0.3);
          transition: opacity 0.3s ease;
          
          &:empty {
            opacity: 0;
          }
        }
      }
    }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.company-intro {
  .intro-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    padding: 0 40px;
    align-items: center;

    .intro-text {
      .intro-text-container {
        position: relative;
        padding: 20px;
        
        @media (max-width: 768px) {
          padding: 10px;
        }
        
        .intro-paragraph {
          position: relative;
          margin-bottom: 30px;
          
          &:last-child {
            margin-bottom: 0;
          }
          
          p {
            font-size: 16px;
            line-height: 1.8;
            color: #2c3e50;
            text-align: justify;
            margin: 0;
            padding: 0 30px;
            text-indent: 2em;
            position: relative;
            z-index: 2;
            
            @media (max-width: 768px) {
              font-size: 15px;
              padding: 0 20px;
              // 移动端显示较短的内容
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          
          .quote-mark {
            position: absolute;
            font-size: 70px;
            color: rgba(193, 127, 89, 0.09);
            z-index: 1;
            
            &.left {
              top: -30px;
              left: -10px;
            }
            
            &.right {
              bottom: -50px;
              right: -10px;
            }
            
            @media (max-width: 768px) {
              font-size: 50px;
            }
          }
        }
      }
    }

    .intro-image {
      .image-wrapper {
        position: relative;
        padding: 20px;
        
        &::before {
          content: '';
          position: absolute;
          top: 40px;
          left: 40px;
          right: -10px;
          bottom: -10px;
          background: linear-gradient(135deg, #c17f59 0%, #e2b396 100%);
          border-radius: 20px;
          z-index: 1;
        }
        
        .image-frame {
          position: relative;
          border-radius: 20px;
          overflow: hidden;
          z-index: 2;
          box-shadow: 0 20px 40px rgba(0,0,0,0.1);
          
          img {
            width: 100%;
            height: auto;
            display: block;
            transition: transform 0.3s ease;
            
            &:hover {
              transform: scale(1.03);
            }
          }
        }
      }
    }
  }
}

.team {
  padding: 80px 0;

  .team-card {
    max-width: 1400px;
    margin: 0 auto;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0,0,0,0.06);
    padding: 40px 0;
    
    @media (max-width: 768px) {
      padding: 20px 0;
      margin: 0 15px;
    }

    .team-layout {
      display: flex;
      gap: 40px;
      
      @media (max-width: 1024px) {
        gap: 30px;
      }
    }

    // Founder Column
    .founder-column {
      flex: 0 0 300px;
      
      .founder-profile {
        text-align: center;

        .founder-image {
          margin-bottom: 20px;
          position: relative;
          display: inline-block;
          transition: transform 0.3s ease;
          &::after {
            content: '';
            position: absolute;
            top: 10px;
            left: 10px;
            right: -10px;
            bottom: -10px;
            background: linear-gradient(135deg, #c17f59 0%, #e2b396 100%);
            border-radius: 50%;
            z-index: 1;
            opacity: 0.15;
          }

          img {
            width: 200px;
            height: 200px;
            object-fit: cover;
            border-radius: 50%;
            border: 6px solid #fff;
            box-shadow: 0 10px 25px rgba(0,0,0,0.1);
            position: relative;
            z-index: 2;
            
            @media (max-width: 1024px) {
              width: 150px;
              height: 150px;
            }
          }

          &:hover {
            transform: scale(1.06);
          }
        }

        .founder-info {
          h3 {
            font-size: 24px;
            color: #2c3e50;
            margin-bottom: 8px;
          }

          .founder-role {
            color: #c17f59;
            font-size: 15px;
            display: block;
            margin-bottom: 8px;
          }

          .founder-education {
            color: #666;
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }
    }

    // Vertical Divider
    .vertical-divider {
      width: 1px;
      background: rgba(0,0,0,0.1);
      margin: 0 20px;
    }

    // Team Column
    .team-column {
      flex: 1;

      .team-grid {
        display: grid;
        gap: 20px;
        margin-bottom: 20px;

        &.directors-grid {
          grid-template-columns: repeat(4, 1fr);
        }

        &.members-grid {
          grid-template-columns: repeat(3, 1fr);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    // Member Card Styles
    .member-card {
      text-align: center;
      transition: transform 0.3s ease;
      padding: 10px;

      &:hover {
        transform: translateY(-3px);

        .member-image img {
          transform: scale(1.05);
          border-color: #c17f59;
        }
      }

      .member-image {
        margin-bottom: 10px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 3px solid #fff;
          box-shadow: 0 5px 15px rgba(0,0,0,0.1);
          transition: all 0.3s ease;
          object-fit: cover;
        }
      }

      .member-info {
        h4 {
          font-size: 14px;
          color: #2c3e50;
          margin-bottom: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          font-size: 12px;
          color: #666;
          line-height: 1.3;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.activities {
  padding: 100px 0;

  .activities-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .main-card {
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 15px 30px rgba(0,0,0,0.06);
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding: 40px;

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .activity-header {
      margin-bottom: 30px;

      h3 {
        font-size: 32px;
        color: #2c3e50;
        margin-bottom: 10px;
        font-weight: 600;
      }

      .subtitle {
        font-size: 18px;
        color: #c17f59;
        letter-spacing: 2px;
      }
    }

    .activity-description {
      
      p {
        font-size: 16px;
        line-height: 1.8;
        color: #666;
      }
    }

    .features-list {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .feature-item {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 10px 20px;
        background: rgba(193, 127, 89, 0.05);
        border-radius: 15px;
        transition: all 0.3s ease;

        &:hover {
          transform: translateX(10px);
          background: rgba(193, 127, 89, 0.1);
        }

        .feature-icon {
          font-size: 24px;
          min-width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          border-radius: 12px;
          box-shadow: 0 4px 12px rgba(0,0,0,0.05);
        }

        span {
          font-size: 16px;
          color: #2c3e50;
        }
      }
    }

    .activity-gallery {
      .gallery-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 20px;
          transition: all 0.3s ease;
          box-shadow: 0 10px 20px rgba(0,0,0,0.1);
          
          &:hover {
            transform: scale(1.05);
            box-shadow: 0 15px 30px rgba(0,0,0,0.15);
          }
        }
      }
    }
  }
}

.partners {
  .partners-carousel {
    position: relative;
    margin-bottom: 100px;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 200px;
      z-index: 2;
    }
    
    &::before {
      left: 0;
      background: linear-gradient(to right, #fff, transparent);
    }
    
    &::after {
      right: 0;
      background: linear-gradient(to left, #fff, transparent);
    }
    
    .carousel-container {
      display: flex;
      gap: 40px;
      padding: 20px;
      
      img {
        width: 220px;
        height: 280px;
        object-fit: cover;
        border-radius: 20px;
        box-shadow: 0 20px 40px rgba(0,0,0,0.1);
        transition: all 0.3s ease;
        
        &:hover {
          transform: translateY(-15px) scale(1.05);
          box-shadow: 0 30px 60px rgba(0,0,0,0.15);
        }
      }
    }
  }
}

.contact {
  position: relative;
  padding: 60px 0;

  .qr-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 16px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    
    .qr-code {
      background: #fff;
      padding: 16px;
      border-radius: 16px;
      text-align: center;
      box-shadow: 0 8px 16px rgba(0,0,0,0.03);
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
      
      .qr-wrapper {
        img {
          width: 100px;
          height: 100px;
          border-radius: 12px;
          margin-bottom: 12px;
          transition: all 0.3s ease;
          border: 2px solid transparent;
          padding: 4px;
          background: #fff;
          
          @media (max-width: 480px) {
            width: 90px;
            height: 90px;
          }
        }
      }
      
      .qr-title {
        font-size: 13px;
        color: #2c3e50;
        margin: 0;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
      }
    }
  }
}

.footer {
  position: relative;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  padding: 30px 0 20px;
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
      padding: 0 20px;
    }
  }

  .footer-left {
    .company-info {
      display: flex;
      align-items: center;
      gap: 15px;

      .footer-logo {
        width: 48px;
        height: 48px;
        margin-top: 10px;
        border-radius: 8px;
        border: 1px solid rgba(255,255,255,0.1);
        transition: transform 0.3s ease;
        
        &:hover {
          transform: scale(1.05);
        }
      }

      .company-details {
        h4 {
          font-size: 20px;
          font-weight: 500;
          color: rgba(255,255,255,0.9);
          margin-bottom: 6px;
        }

        p {
          font-size: 14px;
          color: rgba(255,255,255,0.6);
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .footer-right {
    .thanks-bubbles {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-end;
      
      @media (max-width: 768px) {
        justify-content: center;
      }
    }

    .thanks-bubble {
      background: rgba(255,255,255,0.08);
      padding: 8px 16px;
      border-radius: 14px;
      font-size: 14px;
      color: rgba(255,255,255,0.8);
      transition: all 0.3s ease;
      
      &:hover {
        background: rgba(255,255,255,0.12);
      }
    }
  }

  .footer-bottom {
    max-width: 1200px;
    margin: 20px auto 0;
    padding: 15px 40px 0;
    border-top: 1px solid rgba(255,255,255,0.08);
    display: flex;
    justify-content: center;
    gap: 20px;
    
    @media (max-width: 768px) {
      align-items: center;
      padding: 15px 20px 0;
    }

    p {
      font-size: 14px;
      color: rgba(255,255,255,0.5);
      text-align: center;
      
      a {
        color: rgba(255,255,255,0.5);
        text-decoration: none;
        transition: color 0.3s ease;
        
        &:hover {
          color: rgba(255,255,255,0.8);
        }
      }
    }
  }
}

</style>