// 表单验证手机号
export const validateFormPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入手机号码'))
    return
  } else if (validatePhone(value)) {
    callback()
  } else {
    callback(new Error('请输入正确的手机号码'))
  }
}

// 表单验证中文姓名
export const ValidateFormName = (rule, value, callback) => {
  if (validateChineseName(value)) {
    callback()
  } else {
    callback(new Error('姓名格式有误，须为中文'))
  }
}

// 表单验证身份证号
export const validateFormIdCard = (rule, value, callback) => {
  if (validateIdCard(value)) {
    callback()
  } else {
    callback(new Error('请输入正确的身份证号'))
  }
}

// 验证中文姓名
export const validateChineseName = (value) => {
  const refChineseName = /^[\u4e00-\u9fa5·]+$/
  return refChineseName.test(value)
}

// 验证手机号
export const validatePhone = (phone) => {
  const reg = /^1(3[0-9]|4[5-9]|5[0-3,5-9]|6[2,5,6,7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/
  return reg.test(phone);
}

// 验证身份证号
export const validateIdCard = (idCard) => {
  if (!/^\d{17}[\dXx]$/.test(idCard)) { return false; }

  const provinceCode = parseInt(idCard.substring(0, 2));
  const validProvinceCodes = [11,12,13,14,15,21,22,23,31,32,33,34,35,36,37,41,42,43,44,45,46,50,51,52,53,54,61,62,63,64,65,71,81,82];
  if (!validProvinceCodes.includes(provinceCode)) { return false; }

  const year = parseInt(idCard.substring(6, 10));
  const month = parseInt(idCard.substring(10, 12));
  const day = parseInt(idCard.substring(12, 14));
  const date = new Date(year, month - 1, day);
  if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) { return false; }

  const coefficients = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
  const checkCodes = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验码对应值

  let sum = 0;
  for (let i = 0; i < 17; i++) { sum += parseInt(idCard.charAt(i)) * coefficients[i]; }
  const expectedCode = checkCodes[sum % 11];
  const actualCode = idCard.charAt(17).toUpperCase();

  // console.log(expectedCode, actualCode)
  return expectedCode === actualCode;
}