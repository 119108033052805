<template>
  <div class="participant-create">
    <!-- Action Bar -->
    <div class="action-bar">
      <template v-if="!orderData.order_no">
        <div class="search-form">
          <el-select v-model="selectedOrder" placeholder="选择订单" @change="fetchOrderData" style="width: 250px; margin-right: 16px;">
            <el-option
              v-for="order in orders"
              :key="order.id"
              :label="`${order.order_no} —— ${order.title_cn} · ${order.created_at.split(' ')[0]}`"
              :value="order.order_no">
            </el-option>
          </el-select>
          <el-input 
            clearable
            v-model="orderNo" 
            placeholder="输入订单编号"
            style="width: 250px; margin-right: 16px;">
          </el-input>
          <el-button type="primary" @click="searchOrder" :loading="searching">查询订单</el-button>
        </div>
      </template>
      <template v-else>
        <div class="order-info">
          <div class="info-group">
            <div class="info-item">
              <span class="label">订单编号：</span>
              <span class="value">{{ orderData.order_no }}</span>
            </div>
            <div class="info-item">
              <span class="label">订单金额：</span>
              <span class="value price">¥{{ orderData.amount }}</span>
            </div>
            <div class="info-item">
              <span class="label">创建时间：</span>
              <span class="value">{{ formatDateTime(orderData.created_at) }}</span>
            </div>
            <div class="info-item">
              <span class="label">创建方式：</span>
              <el-tag type="info">{{ orderData.is_manual ? '员工创建' : '用户报名' }}</el-tag>
            </div>
          </div>
        </div>
        <el-button type="primary" @click="submitForm" :loading="submitting" size="medium">提交创建</el-button>
      </template>
    </div>

    <!-- Main Form -->
    <template v-if="orderData.order_no">
      <div class="main-content">
        <!-- Left Column -->
        <div class="left-column">
          <el-tabs v-model="activeTab" type="border-card" class="participant-tabs">
            <el-tab-pane
              v-for="(participant, index) in formData.participants"
              :key="index"
              :label="participant.name || `报名人${index + 1}`"
              :name="String(index)">
              <template #label>
                <div class="tab-label">
                  <span>{{ participant.name || `报名人${index + 1}` }}</span>
                  <i class="el-icon-delete" @click.stop="removeParticipant(index)" v-if="formData.participants.length > 1"></i>
                </div>
              </template>
              
              <!-- Basic Information -->
              <el-card class="detail-card">
                <div slot="header" class="card-header">
                  <span class="title enroller">基本信息</span>
                </div>
                <div class="card-content">
                  <div class="info-grid">
                    <div class="info-item full-width">
                      <span class="label">姓名<span class="required">*</span></span>
                      <el-input v-model="participant.name" size="small"></el-input>
                    </div>
                  </div>
                  <div class="info-grid" style="margin-top: 16px;">
                    <div class="info-item">
                      <span class="label">联系电话</span>
                      <el-input v-model="participant.phone" size="small"></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">所在学校</span>
                      <el-input v-model="participant.school" size="small"></el-input>
                    </div>
                  </div>
                  <div class="info-grid three-columns" style="margin-top: 16px;">
                    <div class="info-item">
                      <span class="label">性别<span class="required">*</span></span>
                      <el-select v-model="participant.gender" size="small" style="width: 100%">
                        <el-option :value="0" label="男"></el-option>
                        <el-option :value="1" label="女"></el-option>
                      </el-select>
                    </div>
                    <div class="info-item">
                      <span class="label">年龄<span class="required">*</span></span>
                      <el-input v-model="participant.age" size="small">
                        <template slot="append">岁</template>
                      </el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">年级</span>
                      <el-select v-model="participant.grade" size="small" style="width: 100%">
                        <el-option value="XIAO-1" label="一年级"></el-option>
                        <el-option value="XIAO-2" label="二年级"></el-option>
                        <el-option value="XIAO-3" label="三年级"></el-option>
                        <el-option value="XIAO-4" label="四年级"></el-option>
                        <el-option value="XIAO-5" label="五年级"></el-option>
                        <el-option value="XIAO-6" label="六年级"></el-option>
                        <el-option value="CHU-1" label="初一"></el-option>
                        <el-option value="CHU-2" label="初二"></el-option>
                        <el-option value="CHU-3" label="初三"></el-option>
                        <el-option value="GAO-1" label="高一"></el-option>
                        <el-option value="GAO-2" label="高二"></el-option>
                        <el-option value="GAO-3" label="高三"></el-option>
                        <el-option value="OTHER" label="其他"></el-option>
                      </el-select>
                    </div>
                  </div>

                  <!-- 证件信息 -->
                  <div class="section-title" style="margin-top: 24px;">证件信息</div>
                  <div class="info-grid">
                    <div class="info-item">
                      <span class="label">证件类型</span>
                      <el-select v-model="participant.id_card_type" size="small" style="width: 100%">
                        <el-option value="身份证" label="身份证"></el-option>
                        <el-option value="户口本" label="户口本"></el-option>
                      </el-select>
                    </div>
                    <div class="info-item">
                      <span class="label">证件号码<span class="required">*</span></span>
                      <el-input v-model="participant.id_card_number" size="small"></el-input>
                    </div>
                  </div>
                  
                  <!-- 证件照片上传 -->
                  <div class="id-card-images">
                    <template v-if="participant.id_card_type === '户口本'">
                      <div class="image-container">
                        <span class="image-label">户口本</span>
                        <div class="image-wrapper small">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('household')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                            :on-success="(res) => handleIdCardUpload(res, 'household', index)">
                            <img v-if="participant.household" id="household" :src="getImageUrl(participant.household, 'household')" class="upload-image">
                            <i v-else class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                      </div>
                    </template>
                    <template v-if="participant.id_card_type === '身份证'">
                      <div class="image-container">
                        <span class="image-label">身份证正面</span>
                        <div class="image-wrapper small">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('id_card_front')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                            :on-success="(res) => handleIdCardUpload(res, 'front', index)">
                            <img v-if="participant.id_card_front" id="id_card_front" :src="getImageUrl(participant.id_card_front, 'id_card_front')" class="upload-image">
                            <i v-else class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                      </div>
                      <div class="image-container">
                        <span class="image-label">身份证反面</span>
                        <div class="image-wrapper small">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('id_card_back')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                            :on-success="(res) => handleIdCardUpload(res, 'back', index)">
                            <img v-if="participant.id_card_back" id="id_card_back" :src="getImageUrl(participant.id_card_back, 'id_card_back')" class="upload-image">
                            <i v-else class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </el-card>

              <!-- Health Information -->
              <el-card class="detail-card">
                <div slot="header" class="card-header">
                  <span class="title enroller">辅助信息</span>
                </div>
                <div class="card-content">
                  <!-- 健康信息部分 -->
                  <div class="section-title">健康信息</div>
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">身高</span>
                      <el-input v-model="participant.height" size="small">
                        <template slot="append">cm</template>
                      </el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">体重</span>
                      <el-input v-model="participant.weight" size="small">
                        <template slot="append">kg</template>
                      </el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">血型</span>
                      <el-select v-model="participant.blood_type" size="small" style="width: 100%">
                        <el-option value="A" label="A型"></el-option>
                        <el-option value="B" label="B型"></el-option>
                        <el-option value="O" label="O型"></el-option>
                        <el-option value="AB" label="AB型"></el-option>
                        <el-option value="其他" label="其他"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="info-grid three-columns" style="margin-top: 16px;">
                    <div class="info-item">
                      <span class="label">睡眠状况</span>
                      <el-input type="textarea" v-model="participant.sleep_status" size="small" autosize></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">饮食忌口</span>
                      <el-input type="textarea" v-model="participant.dietary_taboo" size="small" autosize></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">过敏药物</span>
                      <el-input type="textarea" v-model="participant.drug_allergy" size="small" autosize></el-input>
                    </div>
                  </div>

                  <!-- 学习情况部分 -->
                  <div class="section-title" style="margin-top: 24px;">学习情况</div>
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">学习情况</span>
                      <el-input v-model="participant.learning_desc" size="small"></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">擅长科目</span>
                      <el-input v-model="participant.favorite_subject" size="small"></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">薄弱科目</span>
                      <el-input v-model="participant.weak_subject" size="small"></el-input>
                    </div>
                  </div>

                  <!-- 个性特征部分 -->
                  <div class="section-title" style="margin-top: 24px;">个性特征</div>
                  <div class="info-grid three-columns">
                    <div class="info-item">
                      <span class="label">性格特点</span>
                      <el-input type="textarea" v-model="participant.personality_traits" size="small" autosize></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">兴趣爱好</span>
                      <el-input type="textarea" v-model="participant.hobbies" size="small" autosize></el-input>
                    </div>
                    <div class="info-item">
                      <span class="label">喜欢的颜色</span>
                      <el-input type="textarea" v-model="participant.favorite_color" size="small" autosize></el-input>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-tab-pane>
            
            <!-- 添加报名人的 tab -->
            <el-tab-pane name="add" disabled>
              <template #label>
                <div class="tab-label add-tab" @click.stop="addParticipant">
                  <i class="el-icon-plus"></i>
                  <span>ADD</span>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>

        <!-- Right Column -->
        <div class="right-column">
          <!-- Emergency Contact -->
          <el-card class="detail-card">
            <div slot="header" class="card-header">
              <span class="title contact">联系人信息</span>
            </div>
            <div class="card-content">
              <div class="info-grid">
                <div class="info-item">
                  <span class="label">家长姓名<span class="required">*</span></span>
                  <el-input v-model="formData.emergency_contact.name" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家长职业<span class="required">*</span></span>
                  <el-input v-model="formData.emergency_contact.occupation" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家长电话<span class="required">*</span></span>
                  <el-input v-model="formData.emergency_contact.phone" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家长身份证号</span>
                  <el-input v-model="formData.emergency_contact.id_card_number" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家长关系</span>
                  <el-input v-model="formData.emergency_contact.relationship" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">家庭住址</span>
                  <el-input v-model="formData.emergency_contact.address" size="small"></el-input>
                </div>
              </div>
            </div>
          </el-card>

          <!-- Travel Plan -->
          <el-card class="detail-card">
            <div slot="header" class="card-header">
              <span class="title contact">接送服务</span>
            </div>
            <div class="card-content">
              <div class="info-grid three-columns">
                <div class="info-item">
                  <span class="label">是否需要接送</span>
                  <el-switch v-model="formData.travel_plan.needs_pickup"></el-switch>
                </div>
                <div class="info-item">
                  <span class="label">出发城市</span>
                  <el-input v-model="formData.travel_plan.departure_city" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">交通方式</span>
                  <el-select v-model="formData.travel_plan.transport_type" size="small" style="width: 100%">
                    <el-option value="飞机" label="飞机"></el-option>
                    <el-option value="火车" label="火车"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item">
                  <span class="label">到达时间</span>
                  <el-date-picker
                    v-model="formData.travel_plan.arrival_time"
                    type="datetime"
                    size="small"
                    style="width: 100%"
                    placeholder="选择到达时间"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </div>
                <div class="info-item">
                  <span class="label">车次/航班号</span>
                  <el-input v-model="formData.travel_plan.transport_number" size="small"></el-input>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">出行备注</span>
                  <el-input type="textarea" v-model="formData.travel_plan.travel_remarks" size="small" autosize></el-input>
                </div>
              </div>
            </div>
          </el-card>

          <!-- Extra Information -->
          <el-card class="detail-card">
            <div slot="header" class="card-header">
              <span class="title contact">通用信息</span>
            </div>
            <div class="card-content">
              <div class="info-grid three-columns">
                <div class="info-item">
                  <span class="label">参加过游学</span>
                  <el-switch v-model="formData.extra_info.has_participated"></el-switch>
                </div>
                <div class="info-item">
                  <span class="label">游学类型</span>
                  <el-input v-model="formData.extra_info.study_type" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">游学详细</span>
                  <el-input v-model="formData.extra_info.study_details" size="small"></el-input>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item">
                  <span class="label">所在城市</span>
                  <el-input v-model="formData.extra_info.city" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">了解渠道</span>
                  <el-input v-model="formData.extra_info.info_source" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">推荐人</span>
                  <el-input v-model="formData.extra_info.referrer" size="small"></el-input>
                </div>
                <div class="info-item">
                  <span class="label">专属客服</span>
                  <el-input v-model="formData.extra_info.customer_service" size="small"></el-input>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">预约备注</span>
                  <el-input type="textarea" v-model="formData.extra_info.reservation_remarks" size="small" autosize></el-input>
                </div>
              </div>
              <div class="info-grid" style="margin-top: 16px;">
                <div class="info-item full-width">
                  <span class="label">其他备注</span>
                  <el-input type="textarea" v-model="formData.extra_info.extra_remarks" size="small" autosize></el-input>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { BASE_URL } from '@/utils/urls'
import { validateChineseName, validateIdCard, validatePhone } from '@/utils/validation'

export default {
  name: 'ParticipantCreate',
  data() {
    return {
      orderNo: '',
      orderData: {},
      activeTab: '0',
      searching: false,
      submitting: false,
      selectedOrder: null,
      orders: [],
      formData: {
        participants: [{
          name: '',
          phone: '',
          school: '',
          gender: null,
          age: '',
          grade: '',
          id_card_type: '',
          id_card_number: '',
          height: '',
          weight: '',
          blood_type: '',
          sleep_status: '',
          dietary_taboo: '',
          drug_allergy: '',
          learning_desc: '',
          favorite_subject: '',
          weak_subject: '',
          personality_traits: '',
          hobbies: '',
          favorite_color: '',
          id_card_front: '',
          id_card_back: '',
          household: ''
        }],
        emergency_contact: {
          name: '',
          phone: '',
          relationship: '',
          address: '',
          occupation: '',
          id_card_number: ''
        },
        extra_info: {
          has_participated: false,
          study_type: '',
          study_details: '',
          city: '',
          info_source: '',
          referrer: '',
          customer_service: '',
          reservation_remarks: '',
          extra_remarks: ''
        },
        travel_plan: {
          needs_pickup: false,
          departure_city: '',
          transport_type: '',
          arrival_time: null,
          transport_number: '',
          travel_remarks: ''
        }
      }
    }
  },
  mounted() {
    this.fetchOrders()
  },
  methods: {
    async searchOrder() {
      if (!this.orderNo) {
        this.$message.warning('请先输入订单编号')
        return
      }
      
      this.searching = true
      try {
        const res = await this.$http.get(`orders/${this.orderNo}`)
        if (!res.data) {
          this.$message.warning('未找到订单信息')
          return
        }
        
        this.orderData = res.data
      } catch (err) {
        this.$message.error(err.response?.data?.message || '订单查询失败')
      } finally {
        this.searching = false
      }
    },

    addParticipant() {
      if (this.formData.participants.length >= 10) {
        this.$message.warning('最多添加10个报名人')
        return
      }
      
      this.formData.participants.push({
        name: '',
        phone: '',
        school: '',
        gender: null,
        age: '',
        grade: '',
        id_card_type: '',
        id_card_number: '',
        height: '',
        weight: '',
        blood_type: '',
        sleep_status: '',
        dietary_taboo: '',
        drug_allergy: '',
        learning_desc: '',
        favorite_subject: '',
        weak_subject: '',
        personality_traits: '',
        hobbies: '',
        favorite_color: '',
        id_card_front: '',
        id_card_back: '',
        household: ''
      })
      this.activeTab = String(this.formData.participants.length - 1)
    },

    removeParticipant(index) {
      if (this.formData.participants.length <= 1) {
        this.$message.warning('至少保留一个报名人')
        return
      }

      this.$confirm('确定要删除该报名人吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.formData.participants.splice(index, 1)
        if (this.activeTab >= index) {
          this.activeTab = String(Math.max(0, index - 1))
        }
      }).catch(() => {});
    },

    async submitForm() {
      // 基本信息验证
      for (const participant of this.formData.participants) {
        if (!participant.name) {
          this.$message.warning(`请填写报名人姓名`)
          return
        } else if (!validateChineseName(participant.name)) {
          this.$message.warning(`报名人姓名格式有误（${participant.name}）`)
          return
        }
        if (participant.gender !== 0 && participant.gender !== 1) {
          this.$message.warning(`请填写 ${participant.name} 的性别`)
          return
        }
        if (!participant.age) {
          this.$message.warning(`请填写 ${participant.name} 的年龄`)
          return
        }
        if (!participant.id_card_number) {
          this.$message.warning(`请填写 ${participant.name} 的身份证号`)
          return
        } else if (!validateIdCard(participant.id_card_number)) {
          this.$message.warning(`${participant.name} 的身份证号格式有误`)
          return
        }
      }
      
      // 紧急联系人验证
      if (!this.formData.emergency_contact.name) {
        this.$message.warning('请填写联系人姓名')
        return
      } else if (!validateChineseName(this.formData.emergency_contact.name)) {
        this.$message.warning(`联系人姓名格式有误（${this.formData.emergency_contact.name}）`)
        return
      }
      if (!this.formData.emergency_contact.phone) {
        this.$message.warning('请填写联系人电话')
        return
      } else if (!validatePhone(this.formData.emergency_contact.phone)) {
        this.$message.warning(`联系人电话格式有误`)
        return
      }
      if (!this.formData.emergency_contact.occupation) {
        this.$message.warning('请填写联系人职业')
        return
      }

      // 提交表单
      this.submitting = true
      try {
        await this.$http.post(`participants/${this.orderNo}`, this.formData)
        this.$message.success('报名信息提交成功')
        this.$router.push('/admin/enrolls')
      } catch (err) {
        this.$message.error(err.response?.data?.message || '报名信息提交失败')
      } finally {
        this.submitting = false
      }
    },

    getUploadUrl(type) {
      if (!this.orderNo) {
        this.$message.warning('请先查询订单')
        return ''
      }
      return `${BASE_URL}/api/v1/images/confidential/${type}?order_no=${this.orderNo}`
    },

    getHeaders() {
      return {
        'Authorization': `Bearer ${getToken()}`
      }
    },

    beforeUpload(file) {
      const isImage = file.type.startsWith('image/')
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传图片文件!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    handleIdCardUpload(response, type, participantIndex) {
      if (!response.data) {
        this.$message.error('上传失败')
        return
      }
      const participant = this.formData.participants[participantIndex]
      if (!participant) {
        this.$message.error('参数错误')
        return
      }
      
      switch (type) {
        case 'front':
          participant.id_card_front = response.data
          break
        case 'back':
          participant.id_card_back = response.data
          break
        case 'household':
          participant.household = response.data
          break
        default:
          this.$message.error('未知的上传类型')
      }
    },

    getImageUrl(path, imageId) {
      if (!path) return ''
      if (imageId) {
        this.$http({
          method: 'get',
          url: `images/confidential/${path}`,
          responseType: 'blob'
        }).then(res => {
          const objectURL = URL.createObjectURL(res)
          const img = document.getElementById(imageId)
          if (img) img.src = objectURL
        }).catch(error => {
          console.error('加载图片失败:', error)
        })
      }
    },

    formatDateTime(datetime) {
      if (!datetime) return '-'
      return new Date(datetime).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/\//g, '-')
    },

    async fetchOrders() {
      try {
        const res = await this.$http.get('orders/')
        this.orders = res.data || []
        console.log(this.orders)
      } catch (err) {
        this.$message.error('获取订单列表失败')
      }
    },

    fetchOrderData() {
      this.orderNo = this.selectedOrder
      this.searchOrder()
    }
  }
}
</script>

<style lang="less" scoped>
.participant-create {
  min-height: 100vh;

  .search-bar {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
  }

  // Copy relevant styles from Details.vue
  .action-bar {
    padding: 16px 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .search-form {
      display: flex;
      align-items: center;
    }

    .order-info {
      flex: 1;
      margin-right: 24px;

      .info-group {
        display: flex;
        align-items: center;

        .info-item {
          display: flex;
          align-items: center;
          margin-right: 32px;
          
          &:last-child {
            margin-right: 0;
          }
          
          .label {
            color: #909399;
            font-size: 14px;
            margin-right: 8px;
          }

          .value {
            color: #303133;
            font-size: 14px;

            &.price {
              color: #f56c6c;
            }
          }
        }
      }
    }

    .el-button {
      min-width: 120px;
    }
  }

  .main-content {
    display: grid;
    grid-template-columns: 1fr 500px;
    gap: 20px;
    margin: 0 auto;
    max-width: 1800px;

    .left-column {
      .add-participant {
        margin-top: 20px;
        text-align: center;
      }
    }

    .right-column {
      .submit-actions {
        margin-top: 20px;
        text-align: right;
      }
    }
  }

  // Copy other relevant styles from Details.vue
  .detail-card {
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #303133;
        position: relative;
        padding-left: 12px;
      }

      .enroller::before,
      .contact::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 16px;
        border-radius: 2px;
      }

      .enroller::before {
        background: #409EFF;
      }

      .contact::before {
        background: #67C23A;
      }
    }

    .card-content {
      padding: 20px;
    }

    .section-title {
      font-size: 14px;
      color: #606266;
      font-weight: 500;
      margin-bottom: 16px;
      position: relative;
      padding-left: 10px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        background: #909399;
        border-radius: 2px;
      }
    }
  }

  .info-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(160px, 1fr));
    gap: 16px;

    &.three-columns {
      grid-template-columns: repeat(3, 1fr);
    }

    .info-item {
      background: #f8f9fa;
      padding: 12px 16px;
      border-radius: 6px;

      &.full-width {
        grid-column: 1 / -1;
      }

      .label {
        display: block;
        color: #909399;
        font-size: 13px;
        margin-bottom: 4px;

        .required {
          color: #F56C6C;
          margin-left: 2px;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
          line-height: 1;
          vertical-align: middle;
        }
      }

      .el-input,
      .el-select {
        margin-top: 4px;
      }

      :deep(.el-input__inner) {
        background-color: #fff;
      }
    }
  }

  .id-card-images {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    
    .image-container {
      .image-label {
        display: block;
        color: #909399;
        font-size: 13px;
        margin-bottom: 8px;
      }

      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f8f9fa;
        border-radius: 6px;
        overflow: hidden;
        height: 125px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .image-uploader {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;

    :deep(.el-upload) {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upload-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .el-icon-plus {
      font-size: 28px;
      color: #8c939d;
    }
  }

  .participant-tabs {
    :deep(.el-tabs__header) {
      .el-tabs__nav {
        .el-tabs__item {
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
          
          &.is-disabled {
            cursor: default;
            background: transparent;
            border: none;
            
            &:hover {
              color: #909399;
            }
          }
        }
      }
    }

    .tab-label {
      display: flex;
      align-items: center;
      gap: 8px;

      .el-icon-delete {
        font-size: 14px;
        color: #909399;
        cursor: pointer;
        
        &:hover {
          color: #f56c6c;
        }
      }

      &.add-tab {
        color: #409EFF;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
        
        &:hover {
          color: #66b1ff;
        }

        .el-icon-plus {
          font-size: 14px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  // 移除原有的 add-participant 样式
  .add-participant {
    display: none;
  }
}
</style>
