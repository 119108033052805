<template>
  <div class="create-order">
    <!-- 顶部操作栏 -->
    <div class="action-bar">
      <div class="left-actions">
        <el-button icon="el-icon-back" size="medium" plain @click="goBack">返回列表</el-button>
      </div>
      <div class="title">手动创建订单</div>
      <div class="right-actions">
        <el-button type="primary" size="medium" @click="submitOrder" :loading="submitting">
          创建订单
        </el-button>
      </div>
    </div>

    <!-- 主要内容区域 -->
    <div class="main-content">
      <div class="content-grid">
        <!-- 左侧订单信息 -->
        <div class="left-column">
          <el-card class="order-info-card">
            <div class="card-header">
              <span>订单信息</span>
            </div>
            
            <!-- 选中的活动信息展示 -->
            <div class="selected-activity" v-if="selectedActivity">
              <div class="activity-info">
                <h3>{{ selectedActivity.title_cn }}</h3>
                <div class="info-row">
                  <span>活动时间：</span>
                  <span>{{ selectedActivity.start_date }} 至 {{ selectedActivity.end_date }}</span>
                </div>
                <div class="info-row">
                  <span>活动费用：</span>
                  <span>¥{{ selectedActivity.price }}</span>
                </div>
              </div>
            </div>
            <div class="no-activity" v-else>
              <el-alert
                title="请先从右侧活动列表选择活动"
                type="warning"
                :closable="false">
              </el-alert>
            </div>

            <div class="order-form">
              <el-form 
                :model="orderForm" 
                ref="orderForm"
                label-width="100px">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="参与人数">
                      <el-input-number 
                        v-model="orderForm.participants_count"
                        :min="1"
                        :max="999"
                        @change="calculateAmount">
                      </el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="订单金额">
                      <div class="amount-display">¥{{ orderForm.amount }}</div>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="支付金额">
                      <el-input-number 
                        v-model="orderForm.paid_amount"
                        :precision="2"
                        :step="0.01"
                        :min="0">
                      </el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="支付方式">
                      <el-select v-model="orderForm.payment_method" style="width: 100%">
                        <el-option :value="1" label="小程序支付"></el-option>
                        <el-option :value="2" label="微信转账"></el-option>
                        <el-option :value="3" label="支付宝转账"></el-option>
                        <el-option :value="4" label="扫码支付"></el-option>
                        <el-option :value="5" label="现金"></el-option>
                        <el-option :value="6" label="银行转账"></el-option>
                        <el-option :value="7" label="其他"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-form-item label="User ID">
                  <el-input v-model="orderForm.user_id" placeholder="请输入 User ID"></el-input>
                </el-form-item>
                <el-form-item label="交易流水号">
                  <el-input v-model="orderForm.transaction_id" placeholder="请输入交易流水号"></el-input>
                </el-form-item>
                <el-form-item label="支付凭证">
                  <el-upload
                    class="evidence-uploader"
                    :action="uploadUrl"
                    :headers="uploadHeaders"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :on-success="handleUploadSuccess">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10MB</div>
                  </el-upload>
                  <div v-if="orderForm.payment_evidence" class="evidence-preview">
                    <el-image 
                      :src="getImageUrl(orderForm.payment_evidence)"
                      :preview-src-list="[getImageUrl(orderForm.payment_evidence)]">
                    </el-image>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-card>

          <!-- 备注信息卡片 -->
          <el-card class="remark-card">
            <div class="card-header">
              <span>订单备注</span>
            </div>
            <div class="remark-content">
              <el-input
                type="textarea"
                v-model="orderForm.remark"
                :rows="4"
                placeholder="请输入订单备注信息">
              </el-input>
            </div>
          </el-card>
        </div>

        <!-- 右侧活动选择 -->
        <div class="right-column">
          <el-card class="activity-select-card">
            <div class="card-header">
              <span>选择活动</span>
            </div>
            <div class="activity-select">
              <el-input
                v-model="activitySearch.title_cn"
                placeholder="搜索活动名称"
                class="search-input"
                clearable
                prefix-icon="el-icon-search"
                @clear="searchActivities"
                @keyup.enter.native="searchActivities">
                <el-button slot="append" icon="el-icon-search" @click="searchActivities"></el-button>
              </el-input>

              <el-table
                v-loading="loading"
                :data="activityList"
                highlight-current-row
                @current-change="handleActivitySelect"
                class="activity-table">
                <el-table-column label="活动名称" prop="title_cn" min-width="200"></el-table-column>
                <el-table-column label="价格" width="120" align="right">
                  <template slot-scope="scope">
                    <span class="activity-price">¥{{ scope.row.price }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { BASE_URL } from '@/utils/urls'

export default {
  name: 'CreateOrder',
  data() {
    return {
      submitting: false,
      loading: false,
      activityList: [],
      activitySearch: {
        title_cn: ''
      },
      orderForm: {
        activity_id: null,
        openid: '',
        participants_count: 1,
        amount: 0,
        paid_amount: 0,
        payment_method: 1,
        payment_evidence: '',
        transaction_id: '',
        remark: ''
      },
      selectedActivity: null
    }
  },
  computed: {
    uploadUrl() {
      return `${BASE_URL}/api/v1/activities/images/payment`
    },
    uploadHeaders() {
      return {
        'Authorization': `Bearer ${getToken()}`
      }
    }
  },
  created() {
    this.searchActivities()
  },
  methods: {
    async searchActivities() {
      this.loading = true
      try {
        const res = await this.$http.get('activities/search', {
          params: {
            title_cn: this.activitySearch.title_cn
          }
        })
        this.activityList = res.data
      } catch (err) {
        this.$message.error('获取活动列表失败')
      }
      this.loading = false
    },

    handleActivitySelect(row) {
      if (!row) return
      this.selectedActivity = row
      this.orderForm.activity_id = row.activity_id
      this.calculateAmount()
    },

    calculateAmount() {
      if (this.selectedActivity) {
        this.orderForm.amount = this.selectedActivity.price * this.orderForm.participants_count
        this.orderForm.paid_amount = this.orderForm.amount
      }
    },

    getImageUrl(path) {
      return path ? `${BASE_URL}${path}` : ''
    },

    beforeUpload(file) {
      const isImage = file.type.startsWith('image/')
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传图片文件!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    handleUploadSuccess(response) {
      if (response.data) {
        this.orderForm.payment_evidence = response.data
        this.$message.success('上传成功')
      } else {
        this.$message.error('上传失败')
      }
    },

    goBack() {
      this.$router.push('/admin/orders')
    },

    async submitOrder() {
      try {
        await this.$refs.orderForm.validate()
        
        if (!this.orderForm.activity_id) {
          return this.$message.warning('请选择活动')
        }

        this.submitting = true
        await this.$http.post('orders', this.orderForm)
        this.$message.success('订单创建成功')
        this.$router.push('/admin/orders')
      } catch (err) {
        this.$message.error(err.response?.data?.message || '订单创建失败')
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.create-order {
  min-height: 100vh;

  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
    margin-bottom: 20px;
    border-radius: 8px;

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #1f2937;
    }
  }

  .content-grid {
    display: grid;
    grid-template-columns: minmax(600px, 3fr) minmax(0, 2fr);
    gap: 20px;
    margin: 0 auto;
  }

  .el-card {
    margin-bottom: 20px;
    border-radius: 8px;

    .card-header {
      padding: 8px 20px;
      border-radius: 8px;
      background: #fafafa;
      margin-bottom: 20px;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #1f2937;
        padding-left: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 12px;
          background: #1890ff;
          border-radius: 1px;
        }
      }
    }
  }

  .activity-select-card {
    .search-input {
      margin-bottom: 10px;
    }

    .activity-table {
      height: 570px;
      overflow-y: auto;

      :deep(.el-table__row) {
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f5f7fa;
        }

        &.current-row {
          background-color: #e6f7ff;
        }
      }

      .activity-price {
        color: #f56c6c;
        font-weight: 500;
      }
    }
  }

  .order-info-card {
    .order-form {
      padding: 0 20px;

      .evidence-preview {
        margin-top: 10px;
        
        .el-image {
          width: 120px;
          height: 120px;
          border-radius: 4px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .remark-card {
    .remark-content {
      padding: 0 20px;
    }
  }

  .selected-activity {
    padding: 0 20px;
    margin-bottom: 20px;
    
    .activity-info {
      background: #f8f9fa;
      border-radius: 8px;
      padding: 16px;

      h3 {
        margin: 0 0 12px 0;
        font-size: 16px;
        color: #1f2937;
      }

      .info-row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-size: 14px;

        span {
          color: #666;
        }
      }
    }
  }

  .no-activity {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .amount-display {
    font-size: 16px;
    font-weight: 500;
    color: #f56c6c;
    line-height: 36px;
  }
}

.el-upload__tip {
  font-size: 12px;
  color: #666;
}

.el-table::before {
  display: none;
}
</style>
