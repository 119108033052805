<template>
  <div class="order-details">
    <!-- 顶部操作栏 -->
    <div class="action-bar">
      <div class="left-actions">
        <el-button icon="el-icon-back" size="medium" plain @click="goBack">返回列表</el-button>
      </div>
      <div class="date-info">
        <div v-if="creatorInfo">创建时间：{{ creatorInfo.username || '用户' }} {{ formatDateTime(orderData.created_at) }}</div>
        <div v-if="updaterInfo.username">上次更新：{{ updaterInfo.username }} {{ formatDateTime(orderData.updated_at) }}</div>
      </div>
      <div class="right-actions">
        <div class="user-id-wrapper">
          <template v-if="!editingUserId">
            <el-tag type="info" @dblclick.native="orderData.user_id ? null : startEditUserId" :disable-transitions="true">User ID: {{ orderData.user_id || '- - - - -' }}</el-tag>
          </template>
          <template v-else>
            <el-input v-model="userIdEdit.user_id" size="small" placeholder="输入User ID" class="user-id-input">
              <template #append>
                <el-button icon="el-icon-check" @click="saveUserId"></el-button>
                <el-button icon="el-icon-close" @click="cancelEditUserId"></el-button>
              </template>
            </el-input>
          </template>
        </div>
      </div>
    </div>

    <!-- 主要内容区域 -->
    <div class="main-content">
      <div class="content-grid">
        <!-- 左侧内容列 -->
        <div class="left-column">
          <!-- 订单基本信息卡片 -->
          <el-card class="order-info-card">
            <div class="card-header">
              <span>订单信息</span>
            </div>
            <div class="info-grid">
              <div class="info-section">
                <div class="info-item">
                  <span class="label">订单编号</span>
                  <span class="value">{{ orderData.order_no || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">创建方式</span>
                  <span class="value">{{ orderData.is_manual ? '员工创建' : '用户报名' }}</span>
                </div>
              </div>
              <div class="info-section">
                <div class="info-item">
                  <span class="label">订单金额</span>
                  <span class="value amount">{{ orderData.amount }}</span>
                </div>
                <div class="info-item">
                  <span class="label">参与人数</span>
                  <span class="value">{{ orderData.participants_count || '0' }} 人</span>
                </div>
              </div>
            </div>
          </el-card>

          <!-- 活动信息卡片 -->
          <el-card class="activity-info-card">
            <div class="card-header">
              <span>关联活动</span>
              <div class="header-actions">
                <el-button type="text" icon="el-icon-view" @click="viewActivity(orderData.activity_id)">活动详情</el-button>
              </div>
            </div>
            <div class="activity-info">
              <div class="activity-details">
                <div class="activity-title">
                  <h3>{{ orderData.title_cn || '^_^' }}</h3>
                </div>
                <div class="detail-item">
                  <span class="label">活动时间</span>
                  <span class="value">{{ orderData.start_date }} 至 {{ orderData.end_date }}</span>
                </div>
                <div class="detail-item">
                  <span class="label">活动价格</span>
                  <span class="value">¥{{ orderData.price }}</span>
                </div>
              </div>
            </div>
          </el-card>

          <!-- 备注信息卡片 -->
          <el-card class="remark-card">
            <div class="card-header">
              <span>支付备注</span>
              <div class="header-actions">
                <template v-if="!editingRemark">
                  <el-button type="text" icon="el-icon-edit" @click="startEditRemark">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveRemark">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditRemark">取消</el-button>
                </template>
              </div>
            </div>
            <div class="remark-content">
              <template v-if="!editingRemark">
                <div class="remark-text">{{ orderData.remark || '暂无备注' }}</div>
              </template>
              <template v-else>
                <el-input
                  type="textarea"
                  v-model="remarkEdit.remark"
                  :rows="4"
                  placeholder="请输入备注">
                </el-input>
              </template>
            </div>
          </el-card>
        </div>

        <!-- 右侧内容列 -->
        <div class="right-column">
          <!-- 支付信息卡片 -->
          <el-card class="payment-card">
            <div class="card-header">
              <span>支付信息</span>
              <div class="header-actions">
                <template v-if="!editingPayment">
                  <el-button type="text" icon="el-icon-edit" @click="startEditPayment">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="savePayment">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditPayment">取消</el-button>
                </template>
              </div>
            </div>
            <div class="payment-info">
              <template v-if="!editingPayment">
                <div class="payment-grid">
                  <div class="payment-item">
                    <span class="label">支付状态</span>
                    <span class="value">
                      <el-tag :type="getStatusType(orderData.status)">{{ getStatusText(orderData.status) }}</el-tag>
                    </span>
                  </div>
                  <div class="payment-item">
                    <span class="label">支付金额</span>
                    <span class="value price">¥{{ orderData.paid_amount }}</span>
                  </div>
                  <div class="payment-item">
                    <span class="label">支付方式</span>
                    <span class="value">{{ getPaymentMethod(orderData.payment_method) }}</span>
                  </div>
                  <div class="payment-item">
                    <span class="label">下单时间</span>
                    <span class="value">{{ formatDateTime(orderData.paid_at) || '-' }}</span>
                  </div>
                  <div class="payment-item full-width" v-if="orderData.transaction_id">
                    <span class="label">交易流水号</span>
                    <span class="value">{{ orderData.transaction_id || '-' }}</span>
                  </div>
                  <div class="payment-item full-width" v-if="orderData.payment_evidence">
                    <span class="label">支付凭证</span>
                    <div class="evidence-image">
                      <img id="evidence-img" :src="getImageUrl(orderData.payment_evidence, 'evidence-img')" alt="加载失败">
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="payment-form">
                  <el-form :model="paymentEdit" label-width="100px">
                    <el-form-item label="支付状态">
                      <el-select v-model="paymentEdit.status">
                        <el-option :value="0" label="待支付"></el-option>
                        <el-option :value="1" label="已支付"></el-option>
                        <el-option :value="2" label="已取消"></el-option>
                        <el-option :value="3" label="已退款"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="支付金额">
                      <el-input-number 
                        v-model="paymentEdit.paid_amount"
                        :min="0"
                        :precision="2"
                        :step="100">
                      </el-input-number>
                    </el-form-item>
                    <el-form-item label="支付方式">
                      <el-select v-model="paymentEdit.payment_method">
                        <el-option :value="1" label="小程序支付"></el-option>
                        <el-option :value="2" label="微信转账"></el-option>
                        <el-option :value="3" label="支付宝转账"></el-option>
                        <el-option :value="4" label="扫码支付"></el-option>
                        <el-option :value="5" label="现金"></el-option>
                        <el-option :value="6" label="银行转账"></el-option>
                        <el-option :value="7" label="其他"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="交易流水号">
                      <el-input v-model="paymentEdit.transaction_id"></el-input>
                    </el-form-item>
                    <el-form-item label="支付凭证">
                      <div class="evidence-preview" v-if="paymentEdit.payment_evidence">
                        <img id="payment-edit-img" :src="getImageUrl(paymentEdit.payment_evidence, 'payment-edit-img')" alt="支付凭证">
                      </div>
                      <el-upload
                        class="evidence-uploader"
                        :action="getUploadUrl('payment_info')"
                        :headers="getHeaders()"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        :on-success="(res) => handleUploadSuccess(res, 'payment')">
                        <el-button size="small" type="primary">
                          {{ paymentEdit.payment_evidence ? '替换照片' : '点击上传' }}
                        </el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10MB</div>
                      </el-upload>
                    </el-form-item>
                  </el-form>
                </div>
              </template>
            </div>
          </el-card>

          <!-- 折扣信息卡片 -->
          <el-card class="discount-card">
            <div class="card-header">
              <span>折扣信息</span>
              <div class="header-actions">
                <template v-if="!editingDiscount">
                  <el-button type="text" icon="el-icon-edit" @click="startEditDiscount">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveDiscount">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditDiscount">取消</el-button>
                </template>
              </div>
            </div>
            <div class="discount-info">
              <template v-if="!editingDiscount">
                <div class="discount-grid">
                  <div class="discount-item">
                    <span class="label">原始金额</span>
                    <span class="value">¥{{ orderData.original_amount }}</span>
                  </div>
                  <div class="discount-item">
                    <span class="label">折扣金额</span>
                    <span class="value price">¥{{ orderData.discount_amount }}</span>
                  </div>
                  <div class="discount-item" v-if="orderData.discount_amount > 0">
                    <span class="label">操作人</span>
                    <span class="value">{{ discountInfo.username || '-' }}</span>
                  </div>
                  <div class="discount-item" v-if="orderData.discount_amount > 0">
                    <span class="label">操作时间</span>
                    <span class="value">{{ formatDateTime(orderData.discount_at) || '-' }}</span>
                  </div>
                  <div class="discount-item full-width" v-if="orderData.discount_amount > 0">
                    <span class="label">折扣原因</span>
                    <span class="value">{{ orderData.discount_reason || '-' }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="discount-form">
                  <el-form :model="discountEdit" label-width="100px">
                    <el-form-item label="折扣金额">
                      <el-input-number 
                        v-model="discountEdit.discount_amount"
                        :min="0"
                        :precision="2"
                        :step="100">
                      </el-input-number>
                    </el-form-item>
                    <el-form-item label="折扣原因">
                      <el-input 
                        type="textarea"
                        v-model="discountEdit.discount_reason"
                        :rows="3"
                        placeholder="请输入折扣原因">
                      </el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </template>
            </div>
          </el-card>

          <!-- 退款信息卡片 -->
          <el-card class="refund-card" v-if="orderData.status === 3">
            <div class="card-header">
              <span>退款信息</span>
              <div class="header-actions">
                <template v-if="!editingRefund">
                  <el-button type="text" icon="el-icon-edit" @click="startEditRefund">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveRefund">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditRefund">取消</el-button>
                </template>
              </div>
            </div>
            <div class="refund-info">
              <template v-if="!editingRefund">
                <div class="refund-grid">
                  <div class="refund-item">
                    <span class="label">退款金额</span>
                    <span class="value price">¥{{ orderData.refund_amount }}</span>
                  </div>
                  <div class="refund-item">
                    <span class="label">退款时间</span>
                    <span class="value">{{ formatDateTime(orderData.refunded_at) || '-' }}</span>
                  </div>
                  <div class="refund-item full-width">
                    <span class="label">退款原因</span>
                    <span class="value">{{ orderData.refund_reason || '-' }}</span>
                  </div>
                  <div class="refund-item full-width" v-if="orderData.refund_evidence">
                    <span class="label">退款凭证</span>
                    <div class="evidence-image">
                      <img id="refund-img" :src="getImageUrl(orderData.refund_evidence, 'refund-img')" alt="加载失败">
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="refund-form">
                  <el-form :model="refundEdit" label-width="100px">
                    <el-form-item label="退款金额">
                      <el-input-number 
                        v-model="refundEdit.refund_amount"
                        :min="0"
                        :precision="2"
                        :step="0.01">
                      </el-input-number>
                    </el-form-item>
                    <el-form-item label="退款原因">
                      <el-input 
                        type="textarea"
                        v-model="refundEdit.refund_reason"
                        :rows="3">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="退款凭证">
                      <div class="evidence-preview" v-if="refundEdit.refund_evidence">
                        <img id="refund-edit-img" :src="getImageUrl(refundEdit.refund_evidence, 'refund-edit-img')" alt="退款凭证">
                      </div>
                      <el-upload
                        class="evidence-uploader"
                        :action="getUploadUrl('payment_info')"
                        :headers="getHeaders()"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        :on-success="(res) => handleUploadSuccess(res, 'refund')">
                        <el-button size="small" type="primary">
                          {{ refundEdit.refund_evidence ? '替换照片' : '点击上传' }}
                        </el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10MB</div>
                      </el-upload>
                    </el-form-item>
                  </el-form>
                </div>
              </template>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { BASE_URL, IMAGE_URL } from '@/utils/urls'

export default {
  name: 'OrderDetails',
  data() {
    return {
      orderData: {},
      editingPayment: false,
      editingRefund: false,
      editingRemark: false,
      editingDiscount: false,
      editingUserId: false,
      userIdEdit: {
        user_id: '',
      },
      paymentEdit: {
        status: 0,
        paid_amount: 0,
        payment_method: 1,
        payment_evidence: '',
        transaction_id: ''
      },
      remarkEdit: {
        remark: ''
      },
      refundEdit: {
        refund_amount: 0,
        refund_reason: '',
        refund_evidence: ''
      },
      discountEdit: {
        discount_amount: 0,
        discount_reason: ''
      },
      statusMap: {
        0: { type: 'danger', text: '待支付' },
        1: { type: 'primary', text: '已支付' },
        2: { type: 'info', text: '已取消' },
        3: { type: 'warning', text: '已退款' }
      },
      paymentMethods: {
        1: '小程序支付',
        2: '微信转账',
        3: '支付宝转账',
        4: '扫码支付',
        5: '现金',
        6: '银行转账',
        7: '其他'
      },
      creatorInfo: {},
      updaterInfo: {},
      discountInfo: {}
    }
  },
  created() {
    this.getOrderDetails()
  },
  methods: {
    async getOrderDetails() {
      try {
        const res = await this.$http.get(`orders/${this.$route.params.id}`)
        this.orderData = res.data
        
        // 获取创建者、更新者和折扣操作人信息
        if (this.orderData.created_by) {
          await this.getUserInfo(this.orderData.created_by, 'creator')
        }
        if (this.orderData.updated_by) {
          await this.getUserInfo(this.orderData.updated_by, 'updater')
        }
        if (this.orderData.discount_by) {
          await this.getUserInfo(this.orderData.discount_by, 'discount')
        }
      } catch (err) {
        this.$message.error('获取订单详情失败')
      }
    },
    async getUserInfo(userId, type) {
      try {
        const res = await this.$http.get(`users/${userId}`)
        switch (type) {
          case 'creator':
            this.creatorInfo = res.data
            break
          case 'updater':
            this.updaterInfo = res.data
            break
          case 'discount':
            this.discountInfo = res.data
            break
        }
      } catch (err) {
        console.error(`获取${type}信息失败:`, err)
      }
    },
    
    formatDateTime(date) {
      if (!date) return '';
      return new Date(date).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    },

    getStatusType(status) {
      return this.statusMap[status]?.type || 'info'
    },

    getStatusText(status) {
      return this.statusMap[status]?.text || '未知'
    },

    getPaymentMethod(method) {
      return this.paymentMethods[method] || '未知'
    },

    getImageUrl(path, imageId) {
      this.$http({
        method: 'get',
        url: `images/confidential/${path}`,
        responseType: 'blob'
      }).then(res => {
        let objectURL = URL.createObjectURL(res);
        let img = document.getElementById(imageId);
        img.src = objectURL;
      }).catch(error => {
        console.error(error);
      });
    },

    goBack() {
      this.$router.push('/admin/orders')
    },

    viewActivity(id) {
      this.$router.push(`/admin/activity/details/${id}`)
    },

    // 支付信息编辑
    startEditPayment() {
      this.editingPayment = true
      this.paymentEdit = {
        status: this.orderData.status,
        paid_amount: this.orderData.paid_amount || 0,
        payment_method: this.orderData.payment_method || 1,
        payment_evidence: this.orderData.payment_evidence || '',
        transaction_id: this.orderData.transaction_id || ''
      }
    },

    async savePayment() {
      try {
        await this.$http.put(`orders/${this.$route.params.id}`, this.paymentEdit)
        this.editingPayment = false
        this.getOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '支付信息更新失败')
      }
    },

    cancelEditPayment() {
      this.editingPayment = false
    },

    // 退款信息编辑
    startEditRefund() {
      this.editingRefund = true
      this.refundEdit = {
        refund_amount: this.orderData.refund_amount || 0,
        refund_reason: this.orderData.refund_reason || '',
        refund_evidence: this.orderData.refund_evidence || ''
      }
    },

    async saveRefund() {
      try {
        await this.$http.put(`orders/${this.$route.params.id}`, this.refundEdit)
        this.editingRefund = false
        this.getOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '退款信息更新失败')
      }
    },

    cancelEditRefund() {
      this.editingRefund = false
    },

    // 备注信息编辑
    startEditRemark() {
      this.editingRemark = true
      this.remarkEdit = {
        remark: this.orderData.remark || ''
      }
    },

    async saveRemark() {
      try {
        await this.$http.put(`orders/${this.$route.params.id}`, this.remarkEdit)
        this.editingRemark = false
        this.getOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '备注更新失败')
      }
    },

    cancelEditRemark() {
      this.editingRemark = false
    },

    // 折扣信息编辑
    startEditDiscount() {
      this.editingDiscount = true
      this.discountEdit = {
        discount_amount: this.orderData.discount_amount || 0,
        discount_reason: this.orderData.discount_reason || ''
      }
    },

    async saveDiscount() {
      try {
        await this.$http.put(`orders/${this.$route.params.id}`, this.discountEdit)
        this.editingDiscount = false
        this.getOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || '折扣信息更新失败')
      }
    },

    cancelEditDiscount() {
      this.editingDiscount = false
    },

    // UserID编辑
    startEditUserId() {
      if (this.orderData.user_id) return
      this.editingUserId = true
      this.userIdEdit = {
        user_id: this.orderData.user_id || ''
      }
    },

    async saveUserId() {
      try {
        await this.$http.put(`orders/${this.$route.params.id}`, this.userIdEdit)
        this.editingUserId = false
        this.getOrderDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.message || 'UserID更新失败')
      }
    },

    cancelEditUserId() {
      this.editingUserId = false
    },

    getUploadUrl(type) {
      return `${BASE_URL}/api/v1/images/confidential/${type}`
    },

    getHeaders() {
      return {
        'Authorization': `Bearer ${getToken()}`
      }
    },

    beforeUpload(file) {
      const isImage = file.type.startsWith('image/')
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传图片文件!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    handleUploadSuccess(response, type) {
      if (response.data) {
        if (type === 'payment') {
          this.paymentEdit.payment_evidence = response.data
        } else if (type === 'refund') {
          this.refundEdit.refund_evidence = response.data
        }
        this.$message.success('上传成功')
      } else {
        this.$message.error('上传失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.order-details {
  min-height: 100vh;

  .content-grid {
    display: grid;
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
    gap: 20px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
    margin-bottom: 20px;
    border-radius: 8px;

    .date-info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 48px;
      color: #888;
      font-size: 13px;
    }
  }

  .el-card {
    margin-bottom: 20px;
    border-radius: 8px;

    .card-header {
      position: relative;
      padding: 8px 20px;
      border-radius: 8px;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #1f2937;
        padding-left: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 12px;
          background: #1890ff;
          border-radius: 1px;
        }
      }

      .header-actions {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
    }
  }

  .order-info-card {
    .info-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      padding: 20px;

      .info-section {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .info-item {
          background: #fafafa;
          padding: 12px 16px;
          border-radius: 8px;
          border: 1px solid #f0f0f0;

          .label {
            display: block;
            font-size: 13px;
            color: #666;
            margin-bottom: 8px;
          }

          .value {
            font-size: 14px;
            color: #262626;

            &.amount {
              color: #f56c6c;
              font-size: 20px;
              font-weight: 600;
              font-family: DIN Alternate, -apple-system, BlinkMacSystemFont, sans-serif;
              
              &::before {
                content: '¥';
                font-size: 16px;
                margin-right: 2px;
                font-weight: normal;
              }
            }

            &.price {
              color: #f56c6c;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .activity-info-card {
    .activity-info {
      padding: 20px;

      .activity-details {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background: #fafafa;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #f0f0f0;

        .activity-title {
          margin-bottom: 10px;
          
          h3 {
            font-size: 17px;
            font-weight: 500;
            color: #262626;
            margin: 0;
            line-height: 1.4;
          }
        }

        .detail-item {
          display: flex;
          align-items: center;
          gap: 12px;

          i {
            font-size: 16px;
            color: #1890ff;
          }

          .label {
            font-size: 14px;
            color: #666;
            min-width: 70px;
          }

          .value {
            font-size: 14px;
            color: #262626;

            &.price {
              color: #f56c6c;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .payment-card,
  .refund-card {
    .payment-grid,
    .refund-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      padding: 20px;

      .payment-item,
      .refund-item {
        background: #fafafa;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid #f0f0f0;

        &.full-width {
          grid-column: 1 / -1;
        }

        .label {
          display: block;
          font-size: 13px;
          color: #666;
          margin-bottom: 8px;
        }

        .value {
          font-size: 14px;
          color: #262626;

          &.price {
            color: #f56c6c;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .evidence-image {
          margin-top: 8px;

          img {
            width: 100%;
            height: 120px;
            border-radius: 4px;
            object-fit: cover;
            overflow: hidden;
            display: inline-block;
            background-color: #F5F7FA;
            color: #C0C4CC;
            text-align: center;
            line-height: 120px;
            font-size: 14px;
          }
        }
      }
    }

    .payment-form,
    .refund-form {
      padding: 20px;

      .el-form-item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .remark-card {
    .remark-content {
      padding: 20px;

      .remark-text {
        background: #fafafa;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid #f0f0f0;
        min-height: 90px;
        font-size: 14px;
        color: #666;
        line-height: 1.6;
      }
    }
  }

  .discount-card {
    margin-bottom: 20px;
    
    .discount-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      padding: 20px;

      .discount-item {
        background: #fafafa;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid #f0f0f0;

        &.full-width {
          grid-column: 1 / -1;
        }

        .label {
          display: block;
          font-size: 13px;
          color: #666;
          margin-bottom: 8px;
        }

        .value {
          font-size: 14px;
          color: #262626;

          &.price {
            color: #f56c6c;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    .discount-form {
      padding: 20px;

      .el-form-item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.el-button {
  &--text {
    padding: 4px 8px;
    font-size: 13px;

    &:hover {
      background: rgba(24, 144, 255, 0.06);
      color: #1890ff;
    }
  }
}

.evidence-preview {
  margin-bottom: 12px;
  
  img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #eee;
  }
}

.evidence-uploader {
  :deep(.el-upload__tip) {
    font-size: 12px;
    color: #666;
    margin-top: 8px;
  }
}

.el-textarea :deep(.el-textarea__inner) {
  font-family: inherit;
  padding: 12px 16px;
  border-radius: 8px;
  line-height: 1.6;
}

.right-actions {
  .user-id-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    
    .el-tag {
      margin-right: 4px;
      cursor: pointer;
      transition: all 0.3s;
      
      &:hover {
        border-color: #409eff;
      }
    }
    
    .user-id-input {
      width: 200px;
      
      :deep(.el-input-group__append) {
        padding: 0;
        
        .el-button {
          border: none;
          margin: 0;
          padding: 8px 12px;
          
          &:first-child {
            border-right: 1px solid #dcdfe6;
          }
          
          &:hover {
            color: #409eff;
          }
        }
      }
    }
  }
}

</style>

