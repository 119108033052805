import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
// Public
import Public from '@/pages/public/Public'
// Art-Lens
import Home from '@/pages/Home'
import Login from '@/pages/Login'
import Welcome from '@/pages/Welcome'
// 员工管理
import Staffs from '@/pages/team/Staffs'
import Personal from '@/pages/team/Personal'
import Guidance from '@/pages/team/Guidance'
// 活动管理
import Activities from '@/pages/activity/Activities'
import ActivityCreate from '@/pages/activity/Create'
import ActivityDetail from '@/pages/activity/Details'
// 属性管理
import Tags from '@/pages/attribute/Tags'
import Cities from '@/pages/attribute/Cities'
import Features from '@/pages/attribute/Features'
import Audiences from '@/pages/attribute/Audiences'
// 轮播图管理
import Banners from '@/pages/banner/Banners'
import BannerCreate from '@/pages/banner/Create'
import BannerDetail from '@/pages/banner/Details'
// 活动瞬间管理
import Events from '@/pages/event/Events'
import EventCreate from '@/pages/event/Create'
import EventDetail from '@/pages/event/Details'
// 订单管理
import Orders from '@/pages/order/Orders'
import OrderCreate from '@/pages/order/Create'
import OrderDetail from '@/pages/order/Details'
// 报名人管理
import Enrolls from '@/pages/enroll/Enrolls'
import EnrollCreate from '@/pages/enroll/Create'
import EnrollDetail from '@/pages/enroll/Details'
import EnrollExport from '@/pages/enroll/Export'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: '首页',
      component: Public,
      meta: { title: 'Art-Lens 阿特兰斯' }
    },
    {
      path: '/admin/login',
      name: '登录',
      component: Login,
      meta: { title: 'Art-Lens 后台管理系统' }
    },
    {
      path: '/admin',
      name: '后台首页',
      component: Home,
      redirect: '/admin/welcome',
      children: [
        {
          path: 'welcome',
          name: '欢迎页',
          component: Welcome
        },
        {
          path: 'users',
          name: '员工列表',
          component: Staffs
        },
        {
          path: 'aboutme',
          name: '个人中心',
          component: Personal
        },
        {
          path: 'guide',
          name: '操作指南',
          component: Guidance
        },
        {
          path: 'activities',
          name: '活动列表',
          component: Activities
        },
        {
          path: 'activity/details/:id',
          name: '活动详情',
          component: ActivityDetail
        },
        {
          path: 'activity/create',
          name: '活动创建',
          component: ActivityCreate
        },
        {
          path: 'cities',
          name: '城市列表',
          component: Cities
        },
        {
          path: 'features',
          name: '活动特色列表',
          component: Features
        },
        {
          path: 'tags',
          name: '活动标签列表',
          component: Tags
        },
        {
          path: 'audiences',
          name: '报名人类型列表',
          component: Audiences
        },
        {
          path: 'banners',
          name: '轮播图列表',
          component: Banners
        },
        {
          path: 'banner/details/:id',
          name: '轮播图详情',
          component: BannerDetail
        },
        {
          path: 'banner/create',
          name: '轮播图创建',
          component: BannerCreate
        },
        {
          path: 'events',
          name: '活动瞬间列表',
          component: Events
        },
        {
          path: 'event/details/:id',
          name: '活动瞬间详情',
          component: EventDetail
        },
        {
          path: 'event/create',
          name: '活动瞬间创建',
          component: EventCreate
        },
        {
          path: 'orders',
          name: '订单列表',
          component: Orders
        },
        {
          path: 'order/details/:id',
          name: '订单详情',
          component: OrderDetail
        },
        {
          path: 'order/create',
          name: '订单创建',
          component: OrderCreate
        },
        {
          path: 'enrolls',
          name: '报名人列表',
          component: Enrolls
        },
        {
          path: 'enroll/details/:order_no',
          name: '报名人详情',
          component: EnrollDetail
        },
        {
          path: 'enroll/create',
          name: '报名人创建',
          component: EnrollCreate
        },
        {
          path: 'enroll/export',
          name: '报名人导出',
          component: EnrollExport
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  // 设置页面标题
  document.title = to.meta.title || 'Art-Lens 后台管理系统'

  // 公开页面直接放行
  if(to.path === '/') return next()
  
  // 后台页面需要验证登录
  if(to.path === '/admin/login') return next()
  if(!getToken()) return next('/admin/login')
  next()
})

export default router
