<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 搜索区域 -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input 
            placeholder="订单编号" 
            v-model="searchQuery.order_no" 
            clearable 
            @clear="fetchOrders()" 
            size="medium">
            <el-button slot="append" icon="el-icon-search" @click="fetchOrders()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input 
            placeholder="活动标题" 
            v-model="searchQuery.activity_title" 
            clearable 
            @clear="fetchOrders()" 
            size="medium">
            <el-button slot="append" icon="el-icon-search" @click="fetchOrders()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="searchQuery.status" placeholder="订单状态" @change="fetchOrders()" size="medium">
            <el-option :value="0" label="待支付"></el-option>
            <el-option :value="1" label="已支付"></el-option>
            <el-option :value="2" label="已取消"></el-option>
            <el-option :value="3" label="已退款"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="searchQuery.is_manual" placeholder="创建方式" @change="fetchOrders()" size="medium">
            <el-option :value="true" label="员工创建"></el-option>
            <el-option :value="false" label="用户报名"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="default" @click="resetSearch()" size="medium">重置</el-button>
        </el-col>
      </el-row>

      <!-- 订单列表 -->
      <el-table :data="orderList" style="width: 100%" border stripe>
        <el-table-column label="#" type="index" width="40" align="center"></el-table-column>
        <el-table-column label="订单编号" prop="order_no" align="center"></el-table-column>
        <el-table-column label="活动标题" prop="title_cn" align="center"></el-table-column>
        <el-table-column label="订单金额" width="100" align="center">
          <template slot-scope="scope">¥{{ scope.row.amount }}</template>
        </el-table-column>
        <el-table-column label="创建时间" prop="created_at" width="150" align="center"></el-table-column>
        <el-table-column label="订单状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="getStatusType(scope.row.status)">
              {{ getStatusText(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建方式" width="100" align="center">
          <template slot-scope="scope">
            <el-tag type="info">{{ scope.row.is_manual ? '员工创建' : '用户报名' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="medium" @click="viewOrder(scope.row.order_no)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="onSizeChange"
        @current-change="onPageChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Orders',
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      orderList: [],
      searchQuery: {
        order_no: '',
        activity_title: '',
        status: null,
        is_manual: null
      },
      statusInfo: {
        0: { type: 'danger', text: '待支付' },
        1: { type: 'primary', text: '已支付' },
        2: { type: 'info', text: '已取消' },
        3: { type: 'warning', text: '已退款' }
      }
    }
  },
  created() {
    this.fetchOrders()
  },
  methods: {
    fetchOrders() {
      this.$http({
        method: 'get',
        url: 'orders/',
        params: {
          ...this.searchQuery,
          page: this.pageNum,
          size: this.pageSize
        }
      }).then(res => {
        if (!res.success) return this.$message.error(res.message)
        this.orderList = res.data
        this.total = res.total
      }).catch(err => {
        this.$message.error(err.response?.data?.message || '获取订单列表失败')
      })
    },

    resetSearch() {
      this.searchQuery = {
        order_no: '',
        title_cn: '',
        status: null,
        is_manual: null
      }
      this.fetchOrders()
    },

    getStatusText(status) {
      return this.statusInfo[status]?.text || '未知'
    },

    getStatusType(status) {
      return this.statusInfo[status]?.type || 'info'
    },

    onSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchOrders()
    },

    onPageChange(newPage) {
      this.pageNum = newPage
      this.fetchOrders()
    },

    viewOrder(id) {
      this.$router.push(`/admin/order/details/${id}`)
    }
  }
}
</script>

<style scoped>
.el-card {
  margin-top: 15px;
}
.el-table {
  margin-top: 15px;
}
.el-pagination {
  margin-top: 15px;
}
</style>
