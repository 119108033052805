<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>活动瞬间管理</el-breadcrumb-item>
      <el-breadcrumb-item>活动瞬间列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 搜索区域 -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input 
            placeholder="请输入活动标题" 
            v-model="searchQuery.title_cn" 
            clearable 
            @clear="fetchEvents()" 
            size="medium">
            <el-button slot="append" icon="el-icon-search" @click="fetchEvents()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="searchQuery.status" placeholder="活动状态" @change="fetchEvents" size="medium">
            <el-option :value="0" label="即将开始"></el-option>
            <el-option :value="1" label="进行中"></el-option>
            <el-option :value="2" label="已结束"></el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="default" @click="resetSearch" size="medium">重置</el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="createEvent" size="medium">创建活动瞬间</el-button>
        </el-col>
      </el-row>

      <!-- 活动列表 -->
      <el-table :data="eventList" style="width: 100%" border stripe>
        <el-table-column label="#" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="活动名称" prop="title_cn" align="center"></el-table-column>
        <el-table-column label="副标题" prop="sub_title_cn" align="center"></el-table-column>
        <el-table-column label="封面图" width="180" align="center">
          <template slot-scope="scope">
            <el-image 
              class="cover-image" 
              :src="`${imageURL}${scope.row.cover_image_cn}`" 
              :preview-src-list="[`${imageURL}${scope.row.cover_image_cn}`]"
              fit="cover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="活动时间" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.event_time }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="getStatusType(scope.row.status)">
              {{ getStatusText(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button 
              type="primary" 
              size="medium" 
              @click="viewEvent(scope.row.event_id)">
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="onSizeChange"
        @current-change="onPageChange"
        :current-page="pageNum"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { IMAGE_URL } from '@/utils/urls'

export default {
  name: 'Events',
  data() {
    return {
      imageURL: IMAGE_URL,
      eventList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      searchQuery: {
        status: null,
        title_cn: ''
      },
      statusInfo: {
        0: { type: 'warning', text: '即将开始' },
        1: { type: 'success', text: '进行中' },
        2: { type: 'info', text: '已结束' }
      }
    }
  },
  created() {
    this.fetchEvents()
  },
  methods: {
    fetchEvents() {
      this.$http({
        method: 'get',
        url: 'events/events',
        params: {
          ...this.searchQuery,
          page: this.pageNum,
          size: this.pageSize
        }
      }).then(res => {
        if (!res.success) return this.$message.error(res.message)
        this.eventList = res.data
        this.total = res.total
      }).catch(err => {
        this.$message.error(err.response?.data?.message || '获取活动列表失败')
      })
    },

    resetSearch() {
      this.searchQuery = {
        status: null,
        title_cn: ''
      }
      this.fetchEvents()
    },

    onSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchEvents()
    },

    onPageChange(newPage) {
      this.pageNum = newPage
      this.fetchEvents()
    },

    viewEvent(id) {
      this.$router.push(`/admin/event/details/${id}`)
    },

    createEvent() {
      this.$router.push('/admin/event/create')
    },

    getStatusType(status) {
      return this.statusInfo[status]?.type || 'info'
    },

    getStatusText(status) {
      return this.statusInfo[status]?.text || '未知'
    }
  }
}
</script>

<style scoped>
.el-card {
  margin-top: 15px;
}
.el-table {
  margin-top: 15px;
}
.el-pagination {
  margin-top: 15px;
}

.cover-image {
  width: 150px;
  height: 80px;
  border-radius: 5px;
}
</style>
