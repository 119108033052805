<template>
  <div class="banner-details">
    <!-- 顶部操作栏 -->
    <div class="action-bar">
      <div class="left-actions">
        <el-button icon="el-icon-back" size="medium" plain @click="goBanners">返回列表</el-button>
      </div>
      <div class="date-info" :class="{ 'deleted': bannerData.is_deleted === 1 }">
        <div v-if="creatorInfo.username">创建时间：{{ creatorInfo.username }} {{ formatDateTime(bannerData.created_at) }}</div>
        <div v-if="updaterInfo.username">上次更新：{{ updaterInfo.username }} {{ formatDateTime(bannerData.updated_at) }}</div>
      </div>
      <div class="right-actions">
        <el-button type="danger" icon="el-icon-delete" size="medium" plain @click="handleDelete">删除轮播</el-button>
      </div>
    </div>

    <!-- 主要内容区域 -->
    <div class="main-content">
      <div class="content-grid">
        <!-- 左侧内容列 -->
        <div class="left-column">
          <!-- 标题卡片 -->
          <el-card class="title-card">
            <div class="card-header">
              <span>详情标题</span>
              <div class="header-actions">
                <template v-if="!editingTitle">
                  <el-button type="text" icon="el-icon-edit" @click="startEditTitle">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveTitle">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditTitle">取消</el-button>
                </template>
              </div>
            </div>
            <div class="title-grid">
              <div class="title-cn">
                <div class="title-label">中文标题</div>
                <template v-if="!editingTitle">
                  <div class="title-content">{{ bannerData.title_cn }}</div>
                </template>
                <template v-else>
                  <el-input v-model="titleEdit.title_cn" placeholder="请输入中文标题"></el-input>
                </template>
              </div>
              <div class="title-ug">
                <div class="title-label">ئۇيغۇرچە تېمىسى</div>
                <template v-if="!editingTitle">
                  <div class="title-content">{{ bannerData.title_ug }}</div>
                </template>
                <template v-else>
                  <el-input v-model="titleEdit.title_ug" placeholder="ئۇيغۇرچە تېمىسىنى كىرگۈزۈڭ"></el-input>
                </template>
              </div>
            </div>
          </el-card>

          <!-- 内容卡片 -->
          <el-card class="desc-card">
            <div class="card-header">
              <span>详情内容</span>
              <div class="header-actions">
                <template v-if="!editingContent">
                  <el-button type="text" icon="el-icon-edit" @click="startEditContent">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveContent">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditContent">取消</el-button>
                </template>
              </div>
            </div>
            <div class="desc-grid">
              <div class="desc-cn">
                <div class="desc-label">中文内容</div>
                <template v-if="!editingContent">
                  <div class="desc-content">
                    <p v-for="(line, index) in getContentLines(bannerData.content_cn)" 
                       :key="index">
                      {{ line }}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <el-input
                    type="textarea"
                    v-model="contentEdit.content_cn"
                    :rows="6"
                    autosize
                    placeholder="请输入中文内容">
                  </el-input>
                </template>
              </div>
              <div class="desc-ug">
                <div class="desc-label">ئۇيغۇرچە مەزمۇنى</div>
                <template v-if="!editingContent">
                  <div class="desc-content">
                    <p v-for="(line, index) in getContentLines(bannerData.content_ug)" 
                       :key="index">
                      {{ line }}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <el-input
                    type="textarea"
                    v-model="contentEdit.content_ug"
                    :rows="6"
                    autosize
                    placeholder="ئۇيغۇرچە مەزمۇنىنى كىرگۈزۈڭ">
                  </el-input>
                </template>
              </div>
            </div>
          </el-card>

          <!-- 基本信息卡片 -->
          <el-card class="info-card">
            <div class="card-header">
              <span>轮播信息</span>
              <div class="header-actions">
                <template v-if="!editingInfo">
                  <el-button type="text" icon="el-icon-edit" @click="startEditInfo">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveInfo">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditInfo">取消</el-button>
                </template>
              </div>
            </div>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item">
                  <div class="info-label">图片排序</div>
                  <template v-if="!editingInfo">
                    <div class="info-content">
                      <el-tag v-if="bannerData.is_primary_image" type="success">首图</el-tag>
                      <el-tag v-else type="info">非首图</el-tag>
                    </div>
                  </template>
                  <template v-else>
                    <el-select 
                      v-model="infoEdit.is_primary_image" 
                      placeholder="请选择图片排序"
                      :disabled="bannerData.is_primary_image">
                      <el-option :value="true" label="首图"></el-option>
                      <el-option :value="false" label="非首图"></el-option>
                    </el-select>
                  </template>
                </div>

                <div class="info-item">
                  <div class="info-label">显示状态</div>
                  <template v-if="!editingInfo">
                    <div class="info-content">
                      <el-tag :type="bannerData.status === 1 ? 'success' : 'info'">
                        {{ bannerData.status === 1 ? '显示' : '隐藏' }}
                      </el-tag>
                    </div>
                  </template>
                  <template v-else>
                    <el-select v-model="infoEdit.status" placeholder="请选择显示状态">
                      <el-option :value="1" label="显示"></el-option>
                      <el-option :value="0" label="隐藏"></el-option>
                    </el-select>
                  </template>
                </div>
              </div>

              <div class="info-item">
                <div class="info-label">跳转链接<span class="info-tip">（选填，留空则跳转至详情）</span></div>
                <template v-if="!editingInfo">
                  <div class="info-content">{{ bannerData.link_url || '无' }}</div>
                </template>
                <template v-else>
                  <el-input 
                    v-model="infoEdit.link_url" 
                    placeholder="请输入跳转链接地址">
                  </el-input>
                </template>
              </div>
            </div>
          </el-card>
        </div>

        <!-- 右侧内容列 -->
        <div class="right-column">
          <!-- 图片卡片 -->
          <el-card class="images-card">
            <div class="card-header">
              <span>轮播图</span>
              <div class="header-actions">
                <template v-if="!editingCovers">
                  <el-button type="text" icon="el-icon-edit" @click="startEditCovers">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="cancelEditCovers">完成</el-button>
                </template>
              </div>
            </div>
            <div class="images-wrapper">
              <!-- 封面图 -->
              <div class="image-section">
                <div class="cover-grid">
                  <div class="cover-item">
                    <div class="label-cn">中文封面</div>
                    <div class="image-container cover-shape">
                      <template v-if="bannerData.cover_cn">
                        <el-image 
                          :src="getImageUrl(bannerData.cover_cn)"
                          :preview-src-list="[getImageUrl(bannerData.cover_cn)]">
                        </el-image>
                        <div class="image-actions" v-if="editingCovers">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            class="delete-button"
                            circle 
                            size="mini" 
                            @click="removeImage('cover_cn')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_cn')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传封面</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                  
                  <div class="cover-item">
                    <div class="label-ug">ئۇيغۇرچە مۇقاۋا</div>
                    <div class="image-container cover-shape">
                      <template v-if="bannerData.cover_ug">
                        <el-image 
                          :src="getImageUrl(bannerData.cover_ug)"
                          :preview-src-list="[getImageUrl(bannerData.cover_ug)]">
                        </el-image>
                        <div class="image-actions" v-if="editingCovers">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            class="delete-button"
                            circle 
                            size="mini" 
                            @click="removeImage('cover_ug')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_ug')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">مۇقاۋىنى چىقىرىڭ</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>

          <!-- 详情图片列表 -->
          <el-card class="carousel-images-card">
            <div class="card-header">
              <span>详情图片</span>
              <div class="header-actions">
                <template v-if="!editingDetailImages">
                  <el-button type="text" icon="el-icon-edit" @click="startEditDetailImages">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="cancelEditDetailImages">完成</el-button>
                </template>
              </div>
            </div>
            <div class="images-wrapper">
                <draggable 
                  class="image-list"
                  v-model="bannerData.images"
                  :disabled="!editingDetailImages"
                  @end="onDragEnd">
                  <div v-for="(url, index) in bannerData.images" 
                       :key="index" 
                       class="image-item carousel-shape">
                    <el-image 
                      :src="getImageUrl(url)"
                      :preview-src-list="bannerData.images.map(img => getImageUrl(img))">
                    </el-image>
                    <div class="image-actions" v-if="editingDetailImages">
                      <el-button 
                        type="danger" 
                        icon="el-icon-delete" 
                        class="delete-button"
                        circle 
                        size="mini"
                        @click="removeImage('images', index)">
                      </el-button>
                    </div>
                  </div>
                  <div v-if="editingDetailImages" class="upload-box carousel-shape">
                    <el-upload
                      class="image-uploader"
                      :action="getUploadUrl('linked_images')"
                      :headers="getHeaders()"
                      :show-file-list="false"
                      :on-success="(res) => uploaded(res, 'images')"
                      :before-upload="beforeUpload">
                      <i class="el-icon-plus"></i>
                      <div class="upload-text">上传详情图片</div>
                    </el-upload>
                  </div>
                </draggable>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { IMAGE_URL, BASE_URL } from '@/utils/urls'
import draggable from 'vuedraggable'

export default {
  name: 'BannerDetails',
  components: {
    draggable
  },
  data() {
    return {
      imageURL: IMAGE_URL,
      serverURL: BASE_URL,
      bannerData: {
        title_cn: '',
        title_ug: '',
        content_cn: '',
        content_ug: '',
        images: [],
        cover_cn: '',
        cover_ug: '',
        is_primary_image: false,
        link_url: '',
        status: 1
      },
      editingTitle: false,
      editingContent: false,
      editingInfo: false,
      editingCovers: false,
      editingDetailImages: false,
      titleEdit: {},
      contentEdit: {},
      infoEdit: {},
      creatorInfo: {},
      updaterInfo: {}
    }
  },
  created() {
    this.getBannerDetails()
  },
  methods: {
    async getBannerDetails() {
      try {
        const { data: res } = await this.$http.get(`/banners/banners/${this.$route.params.id}`)
        this.bannerData = res
        
        if (this.bannerData.created_by) {
          await this.getUserInfo(this.bannerData.created_by, 'creator')
        }
        if (this.bannerData.updated_by) {
          await this.getUserInfo(this.bannerData.updated_by, 'updater')
        }
      } catch (err) {
        this.$message.error('获取轮播详情失败')
      }
    },

    async getUserInfo(userId, type) {
      try {
        const res = await this.$http.get(`users/${userId}`)
        if (type === 'creator') {
          this.creatorInfo = res.data
        } else if (type === 'updater') {
          this.updaterInfo = res.data
        }
      } catch (err) {
        console.error(`获取${type === 'creator' ? '创建者' : '更新者'}信息失败:`, err)
      }
    },

    formatDateTime(date) {
      if (!date) return '';
      return new Date(date).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
    },

    goBanners() {
      this.$router.push('/admin/banners')
    },

    // Title editing methods
    startEditTitle() {
      this.editingTitle = true
      this.titleEdit = {
        title_cn: this.bannerData.title_cn,
        title_ug: this.bannerData.title_ug
      }
    },

    async saveTitle() {
      try {
        if (!this.titleEdit.title_cn || !this.titleEdit.title_ug) {
          this.$message.warning('中文和维文标题都不能为空')
          return
        }

        await this.$http.put(`/banners/banners/${this.$route.params.id}`, {
          title_cn: this.titleEdit.title_cn,
          title_ug: this.titleEdit.title_ug
        })
        
        this.$message.success('标题更新成功')
        this.editingTitle = false
        this.getBannerDetails()
      } catch (err) {
        this.$message.error('标题更新失败')
      }
    },

    cancelEditTitle() {
      this.editingTitle = false
      this.titleEdit = {}
    },

    // Content editing methods
    startEditContent() {
      this.editingContent = true
      this.contentEdit = {
        content_cn: this.bannerData.content_cn,
        content_ug: this.bannerData.content_ug
      }
    },

    async saveContent() {
      try {
        await this.$http.put(`/banners/banners/${this.$route.params.id}`, {
          content_cn: this.contentEdit.content_cn,
          content_ug: this.contentEdit.content_ug
        })
        
        this.$message.success('内容更新成功')
        this.editingContent = false
        this.getBannerDetails()
      } catch (err) {
        this.$message.error('内容更新失败')
      }
    },

    cancelEditContent() {
      this.editingContent = false
      this.contentEdit = {}
    },

    // Info editing methods
    startEditInfo() {
      this.editingInfo = true
      this.infoEdit = {
        is_primary_image: this.bannerData.is_primary_image,
        status: this.bannerData.status,
        link_url: this.bannerData.link_url
      }
    },

    async saveInfo() {
      try {
        await this.$http.put(`/banners/banners/${this.$route.params.id}`, {
          is_primary_image: this.infoEdit.is_primary_image,
          status: this.infoEdit.status,
          link_url: this.infoEdit.link_url
        })
        
        this.$message.success('信息更新成功')
        this.editingInfo = false
        this.getBannerDetails()
      } catch (err) {
        this.$message.error('信息更新失败')
      }
    },

    cancelEditInfo() {
      this.editingInfo = false
      this.infoEdit = {}
    },

    // Image handling methods
    getUploadUrl(type) {
      return `${this.serverURL}/api/v1/images/image/banner/${type}`
    },

    getHeaders() {
      return {
        'Authorization': `Bearer ${getToken()}`
      }
    },

    getImageUrl(path) {
      return path.startsWith('http') ? path : `${this.imageURL}${path}`
    },

    beforeUpload(file) {
      const isImage = /^image\/(jpeg|png|gif|jpg)$/.test(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传 JPG/PNG/GIF 格式的图片!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    startEditCovers() {
      this.editingCovers = true
    },

    cancelEditCovers() {
      this.editingCovers = false
    },

    startEditDetailImages() {
      this.editingDetailImages = true
    },

    cancelEditDetailImages() {
      this.editingDetailImages = false
    },

    async uploaded(response, type) {
      if (!response.data) {
        this.$message.error('上传失败')
        return
      }

      const imageUrl = response.data
      const updateData = {}
      
      if (type === 'images') {
        updateData.images = [...this.bannerData.images, imageUrl]
      } else {
        updateData[type] = imageUrl
      }

      try {
        await this.$http.put(`/banners/banners/${this.$route.params.id}`, updateData)
        this.$message.success('图片上传成功')
        this.getBannerDetails()
      } catch (err) {
        this.$message.error('图片上传失败')
      }
    },

    async removeImage(type, index) {
      try {
        const confirmResult = await this.$confirm('确认删除该图片?', '提示', {
          type: 'warning'
        })

        if (confirmResult === 'confirm') {
          const updateData = {}
          
          if (type === 'images') {
            const newImages = [...this.bannerData.images]
            newImages.splice(index, 1)
            updateData.images = newImages
          } else {
            updateData[type] = ''
          }

          await this.$http.put(`/banners/banners/${this.$route.params.id}`, updateData)
          this.$message.success('图片删除成功')
          this.getBannerDetails()
        }
      } catch (err) {
        if (err !== 'cancel') {
          this.$message.error('删除失败')
        }
      }
    },

    async handleDelete() {
      try {
        await this.$confirm('确认删除该轮播?', '提示', {
          type: 'warning'
        })
        
        await this.$http.delete(`/banners/banners/${this.$route.params.id}`)
        this.$message.success('删除成功')
        this.goBanners()
      } catch (err) {
        if (err !== 'cancel') {
          this.$message.error('删除失败')
        }
      }
    },

    getContentLines(content) {
      if (!content) return [''];
      return content.split('\n').map(line => line.trim()).filter(line => line);
    },

    async onDragEnd() {
      try {
        await this.$http.put(`/banners/banners/${this.$route.params.id}`, {
          images: this.bannerData.images
        })
        this.$message.success('顺序已更新')
      } catch (err) {
        this.$message.error('顺序更新失败')
        this.getBannerDetails()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.banner-details {
  min-height: 100vh;

  .content-grid {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: 20px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
      
      .right-column {
        order: 2;
      }
      
      .left-column {
        order: 1;
        transform: translateY(20px);
      }
    }
  }
}

.el-card {
  &.title-card,
  &.desc-card,
  &.info-card,
  &.meta-card,
  &.images-card,
  &.carousel-images-card {
    margin-bottom: 20px;
    border-radius: 8px;

    .card-header {
      position: relative;
      padding: 8px 20px;
      border-radius: 8px;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #1f2937;
        padding-left: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 12px;
          background: #1890ff;
          border-radius: 1px;
        }
      }

      .header-actions {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
    }
  }
}

// 顶部操作栏样式
.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  border-radius: 8px;

  .date-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    color: #888;
    font-size: 13px;

    &.deleted {
      color: #ff4d4f;
    }
  }
}

// 标题卡片样式
.title-card {
  .title-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    .title-cn, .title-ug {
      .title-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      .title-content {
        background: #fafafa;
        padding: 9px 15px;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        font-size: 14px;
        color: #262626;
      }
    }

    .title-ug {
      direction: rtl;

      .title-label {
        text-align: right;
      }

      .el-input__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 简介卡片样式
.desc-card {
  .desc-grid {
    padding: 20px;

    .desc-cn, .desc-ug {
      .desc-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      .desc-content {
        background: #fafafa;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        font-size: 14px;
        color: #262626;
        min-height: 80px;

        p {
          margin: 0;
          line-height: 1.8;
          text-indent: 0.7em;
        }
      }
    }

    .desc-ug {
      direction: rtl;
      margin-top: 20px;

      .desc-label {
        text-align: right;
      }

      .el-textarea__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 基本信息卡片样式
.info-card {
  .info-grid {
    padding: 20px;

    .info-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      margin-bottom: 20px;
    }

    .info-item {
      background: #fafafa;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #f0f0f0;

      .info-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 8px;
      }

      .info-content {
        font-size: 14px;
        color: #262626;
        overflow: hidden;
      }

      .info-tip {
        font-size: 12px;
        color: #999;
        margin-left: 4px;
      }

      .el-select {
        width: 100%;
      }
    }
  }
}

// 图片卡片样式
.images-card {
  .images-wrapper {
    padding: 20px;
  }

  .image-section {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .cover-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .label-cn {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: ltr;
  }

  .label-ug {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: rtl;
  }

  .image-container {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    :deep(.el-image) {
      width: 100%;
      height: 100%;
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image-actions {
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 1;
    }

    &:hover .image-actions {
      opacity: 1;
    }
  }
}

// 详情图片卡片样式
.carousel-images-card {
  margin-top: 20px;
  
  .images-wrapper {
    padding: 20px;
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 8px;

    .image-item {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      background-color: #f8f8f8;

      :deep(.el-image) {
        width: 100%;
        height: 100%;
        display: block;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .image-actions {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
      }

      &:hover .image-actions {
        opacity: 1;
      }
    }
  }
}

.cover-shape {
  aspect-ratio: 16/9;
}

.carousel-shape {
  aspect-ratio: 16/10;
}

.upload-box {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fafafa;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }

  .image-uploader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .el-icon-plus {
    font-size: 20px;
    color: #8c939d;
    margin-bottom: 8px;
  }

  .upload-text {
    font-size: 12px;
    color: #666;
  }
}

.el-button {
  padding: 10px 20px;
  transition: all 0.2s ease;

  &.delete-button {
    padding: 7px !important;
  }

  &--text {
    padding: 4px 8px;
    font-size: 13px;

    &:hover {
      background: rgba(24, 144, 255, 0.06);
      color: #1890ff;
    }
  }
}

.el-input :deep(.el-input__inner) {
  font-family: inherit;
}

.el-textarea :deep(.el-textarea__inner) {
  font-family: inherit;
  padding: 10px 15px;
  line-height: 1.8;
}

.el-tag {
  margin: 0;
  padding: 0 10px;
}
</style>