<template>
    <div class="event-details">
      <!-- 顶部操作栏 -->
      <div class="action-bar">
        <div class="left-actions">
          <el-button icon="el-icon-back" size="medium" plain @click="goEvents">返回列表</el-button>
        </div>
        <div class="date-info" :class="{ 'deleted': eventData.is_deleted === 1 }">
          <div v-if="creatorInfo.username">创建时间：{{ creatorInfo.username }} {{ formatDateTime(eventData.created_at) }}</div>
          <div v-if="updaterInfo.username">上次更新：{{ updaterInfo.username }} {{ formatDateTime(eventData.updated_at) }}</div>
        </div>
        <div class="right-actions">
          <el-button type="danger" icon="el-icon-delete" size="medium" plain @click="handleDelete">删除瞬间</el-button>
        </div>
      </div>
  
      <!-- 主要内容区域 -->
      <div class="main-content">
        <div class="content-grid">
          <!-- 左侧内容列 -->
          <div class="left-column">
            <!-- 标题卡片 -->
            <el-card class="title-card">
              <div class="card-header">
                <span>活动标题</span>
                <div class="header-actions">
                  <template v-if="!editingTitle">
                    <el-button type="text" icon="el-icon-edit" @click="startEditTitle">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="saveTitle">保存</el-button>
                    <el-button type="text" icon="el-icon-close" @click="cancelEditTitle">取消</el-button>
                  </template>
                </div>
              </div>
              <div class="title-grid">
                <div class="title-cn">
                  <div class="title-label">中文标题</div>
                  <template v-if="!editingTitle">
                    <div class="title-content">{{ eventData.title_cn }}</div>
                  </template>
                  <template v-else>
                    <el-input v-model="titleEdit.title_cn" placeholder="请输入中文标题"></el-input>
                  </template>
                </div>
                <div class="title-ug">
                  <div class="title-label">ئۇيغۇرچە تېمىسى</div>
                  <template v-if="!editingTitle">
                    <div class="title-content">{{ eventData.title_ug }}</div>
                  </template>
                  <template v-else>
                    <el-input v-model="titleEdit.title_ug" placeholder="ئۇيغۇرچە تېمىسىنى كىرگۈزۈڭ"></el-input>
                  </template>
                </div>
              </div>
            </el-card>
  
            <!-- 副标题卡片 -->
            <el-card class="subtitle-card">
              <div class="card-header">
                <span>活动副标题</span>
                <div class="header-actions">
                  <template v-if="!editingSubtitle">
                    <el-button type="text" icon="el-icon-edit" @click="startEditSubtitle">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="saveSubtitle">保存</el-button>
                    <el-button type="text" icon="el-icon-close" @click="cancelEditSubtitle">取消</el-button>
                  </template>
                </div>
              </div>
              <div class="title-grid">
                <div class="title-cn">
                  <div class="title-label">中文副标题</div>
                  <template v-if="!editingSubtitle">
                    <div class="title-content">{{ eventData.sub_title_cn }}</div>
                  </template>
                  <template v-else>
                    <el-input v-model="subtitleEdit.sub_title_cn" placeholder="请输入中文副标题"></el-input>
                  </template>
                </div>
                <div class="title-ug">
                  <div class="title-label">ئۇيغۇرچە تارماق تېما</div>
                  <template v-if="!editingSubtitle">
                    <div class="title-content">{{ eventData.sub_title_ug }}</div>
                  </template>
                  <template v-else>
                    <el-input v-model="subtitleEdit.sub_title_ug" placeholder="ئۇيغۇرچە تارماق تېمىنى كىرگۈزۈڭ"></el-input>
                  </template>
                </div>
              </div>
            </el-card>
  
            <!-- 基本信息卡片 -->
            <el-card class="info-card">
              <div class="card-header">
                <span>活动信息</span>
                <div class="header-actions">
                  <template v-if="!editingInfo">
                    <el-button type="text" icon="el-icon-edit" @click="startEditInfo">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="saveInfo">保存</el-button>
                    <el-button type="text" icon="el-icon-close" @click="cancelEditInfo">取消</el-button>
                  </template>
                </div>
              </div>
              <div class="info-grid">
                <div class="info-row">
                  <div class="info-item">
                    <div class="info-label">活动时间</div>
                    <template v-if="!editingInfo">
                      <div class="info-content">{{ eventData.event_time }}</div>
                    </template>
                    <template v-else>
                      <el-date-picker
                        v-model="infoEdit.event_time"
                        type="month"
                        placeholder="选择活动时间"
                        value-format="yyyy-MM"
                        style="width: 100%">
                      </el-date-picker>
                    </template>
                  </div>
  
                  <div class="info-item">
                    <div class="info-label">活动状态</div>
                    <template v-if="!editingInfo">
                      <div class="info-content">
                        <el-tag :type="eventData.status === 1 ? 'success' : eventData.status === 0 ? 'warning' : 'info'">
                          {{ eventData.status === 1 ? '进行中' : eventData.status === 0 ? '即将开始' : '已结束' }}
                        </el-tag>
                      </div>
                    </template>
                    <template v-else>
                      <el-select v-model="infoEdit.status" placeholder="请选择活动状态" style="width: 100%">
                        <el-option :value="0" label="即将开始"></el-option>
                        <el-option :value="1" label="进行中"></el-option>
                        <el-option :value="2" label="已结束"></el-option>
                      </el-select>
                    </template>
                  </div>
                </div>
  
                <div class="info-item">
                  <div class="info-label">跳转链接<span class="info-tip">（选填）</span></div>
                  <template v-if="!editingInfo">
                    <div class="info-content">{{ eventData.hyper_link || '无' }}</div>
                  </template>
                  <template v-else>
                    <el-input 
                      v-model="infoEdit.hyper_link" 
                      placeholder="请输入跳转链接地址">
                    </el-input>
                  </template>
                </div>
              </div>
            </el-card>
          </div>
  
          <!-- 右侧内容列 -->
          <div class="right-column">
            <!-- 封面图卡片 -->
            <el-card class="images-card">
              <div class="card-header">
                <span>活动封面</span>
                <div class="header-actions">
                  <template v-if="!editingCovers">
                    <el-button type="text" icon="el-icon-edit" @click="startEditCovers">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="cancelEditCovers">完成</el-button>
                  </template>
                </div>
              </div>
              <div class="images-wrapper">
                <div class="cover-grid">
                  <div class="cover-item">
                    <div class="label-cn">中文封面</div>
                    <div class="image-container cover-shape">
                      <template v-if="eventData.cover_image_cn">
                        <el-image 
                          :src="getImageUrl(eventData.cover_image_cn)"
                          :preview-src-list="[getImageUrl(eventData.cover_image_cn)]">
                        </el-image>
                        <div class="image-actions" v-if="editingCovers">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            class="delete-button"
                            circle 
                            size="mini" 
                            @click="removeImage('cover_image_cn')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_image_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_image_cn')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传封面</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                  
                  <div class="cover-item">
                    <div class="label-ug">ئۇيغۇرچە مۇقاۋا</div>
                    <div class="image-container cover-shape">
                      <template v-if="eventData.cover_image_ug">
                        <el-image 
                          :src="getImageUrl(eventData.cover_image_ug)"
                          :preview-src-list="[getImageUrl(eventData.cover_image_ug)]">
                        </el-image>
                        <div class="image-actions" v-if="editingCovers">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            class="delete-button"
                            circle 
                            size="mini" 
                            @click="removeImage('cover_image_ug')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_image_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_image_ug')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">مۇقاۋىنى چىقىرىڭ</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
  
            <!-- 轮播图片卡片 -->
            <el-card class="carousel-images-card">
              <div class="card-header">
                <span>活动轮播图</span>
                <div class="header-actions">
                  <template v-if="!editingCarousel">
                    <el-button type="text" icon="el-icon-edit" @click="startEditCarousel">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="cancelEditCarousel">完成</el-button>
                  </template>
                </div>
              </div>
              <div class="images-wrapper">
                <div class="carousel-grid">
                  <!-- 中文轮播图列 -->
                  <div class="carousel-column">
                    <div class="label-cn">中文轮播图</div>
                    <div class="image-list">
                      <draggable 
                        class="image-list"
                        v-model="eventData.carousel_images_cn"
                        :disabled="!editingCarousel"
                        @end="onCarouselDragEnd('cn')">
                        <div v-for="(url, index) in eventData.carousel_images_cn" 
                             :key="index" 
                             class="image-item carousel-shape">
                          <el-image 
                            :src="getImageUrl(url)"
                            :preview-src-list="eventData.carousel_images_cn.map(img => getImageUrl(img))">
                          </el-image>
                          <div class="image-actions" v-if="editingCarousel">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              class="delete-button"
                              circle 
                              size="mini"
                              @click="removeImage('carousel_images_cn', index)">
                            </el-button>
                          </div>
                        </div>
                        <div v-if="editingCarousel" class="upload-box carousel-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('carousel_images_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'carousel_images_cn')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传轮播图</div>
                          </el-upload>
                        </div>
                      </draggable>
                    </div>
                  </div>
                  
                  <!-- 维文轮播图列 -->
                  <div class="carousel-column">
                    <div class="label-ug">ئۇيغۇرچە ئايلانما تەگلىكلەر</div>
                    <div class="image-list">
                      <draggable 
                        class="image-list"
                        v-model="eventData.carousel_images_ug"
                        :disabled="!editingCarousel"
                        @end="onCarouselDragEnd('ug')">
                        <div v-for="(url, index) in eventData.carousel_images_ug" 
                             :key="index" 
                             class="image-item carousel-shape">
                          <el-image 
                            :src="getImageUrl(url)"
                            :preview-src-list="eventData.carousel_images_ug.map(img => getImageUrl(img))">
                          </el-image>
                          <div class="image-actions" v-if="editingCarousel">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              class="delete-button"
                              circle 
                              size="mini"
                              @click="removeImage('carousel_images_ug', index)">
                            </el-button>
                          </div>
                        </div>
                        <div v-if="editingCarousel" class="upload-box carousel-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('carousel_images_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'carousel_images_ug')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">تەگلىكلەرنى چىقىرىڭ</div>
                          </el-upload>
                        </div>
                      </draggable>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
  
            <!-- moments 图片卡片 -->
            <el-card class="moments-images-card">
              <div class="card-header">
                <span>活动瞬间图片</span>
                <div class="header-actions">
                  <template v-if="!editingMoments">
                    <el-button type="text" icon="el-icon-edit" @click="startEditMoments">编辑</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" icon="el-icon-check" @click="cancelEditMoments">完成</el-button>
                  </template>
                </div>
              </div>
              <div class="images-wrapper">
                <draggable 
                  class="image-list"
                  v-model="eventData.moments"
                  :disabled="!editingMoments"
                  @end="onDragEnd">
                  <div v-for="(url, index) in eventData.moments" 
                       :key="index" 
                       class="image-item moments-shape">
                    <el-image 
                      :src="getImageUrl(url)"
                      :preview-src-list="eventData.moments.map(img => getImageUrl(img))">
                    </el-image>
                    <div class="image-actions" v-if="editingMoments">
                      <el-button 
                        type="danger" 
                        icon="el-icon-delete" 
                        class="delete-button"
                        circle 
                        size="mini"
                        @click="removeImage('moments', index)">
                      </el-button>
                    </div>
                  </div>
                  <div v-if="editingMoments" class="upload-box moments-shape">
                    <el-upload
                      class="image-uploader"
                      :action="getUploadUrl('moments')"
                      :headers="getHeaders()"
                      :show-file-list="false"
                      :on-success="(res) => uploaded(res, 'moments')"
                      :before-upload="beforeUpload">
                      <i class="el-icon-plus"></i>
                      <div class="upload-text">上传瞬间图片</div>
                    </el-upload>
                  </div>
                </draggable>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getToken } from '@/utils/auth'
  import { IMAGE_URL, BASE_URL } from '@/utils/urls'
  import draggable from 'vuedraggable'
  
  export default {
    name: 'EventDetails',
    components: {
      draggable
    },
    data() {
      return {
        imageURL: IMAGE_URL,
        serverURL: BASE_URL,
        eventData: {
          title_cn: '',
          title_ug: '',
          sub_title_cn: '',
          sub_title_ug: '',
          event_time: '',
          hyper_link: '',
          status: null,
          cover_image_cn: '',
          cover_image_ug: '',
          carousel_images_cn: [],
          carousel_images_ug: [],
          moments: []
        },
        editingTitle: false,
        editingSubtitle: false,
        editingInfo: false,
        editingCovers: false,
        editingCarousel: false,
        editingMoments: false,
        titleEdit: {},
        subtitleEdit: {},
        infoEdit: {},
        creatorInfo: {},
        updaterInfo: {}
      }
    },
    created() {
      this.getEventDetails()
    },
    methods: {
      async getEventDetails() {
        try {
          const { data: res } = await this.$http.get(`/events/${this.$route.params.id}`)
          this.eventData = res
          
          if (this.eventData.created_by) {
            await this.getUserInfo(this.eventData.created_by, 'creator')
          }
          if (this.eventData.updated_by) {
            await this.getUserInfo(this.eventData.updated_by, 'updater')
          }
        } catch (err) {
          this.$message.error('获取活动详情失败')
        }
      },
  
      async getUserInfo(userId, type) {
        try {
          const res = await this.$http.get(`users/${userId}`)
          if (type === 'creator') {
            this.creatorInfo = res.data
          } else if (type === 'updater') {
            this.updaterInfo = res.data
          }
        } catch (err) {
          console.error(`获取${type === 'creator' ? '创建者' : '更新者'}信息失败:`, err)
        }
      },
  
      formatDateTime(date) {
        if (!date) return '';
        return new Date(date).toLocaleString('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        });
      },
  
      goEvents() {
        this.$router.push('/admin/events')
      },
  
      // Title editing methods
      startEditTitle() {
        this.editingTitle = true
        this.titleEdit = {
          title_cn: this.eventData.title_cn,
          title_ug: this.eventData.title_ug
        }
      },
  
      async saveTitle() {
        try {
          if (!this.titleEdit.title_cn || !this.titleEdit.title_ug) {
            this.$message.warning('中文和维文标题都不能为空')
            return
          }
  
          await this.$http.put(`/events/${this.$route.params.id}`, {
            title_cn: this.titleEdit.title_cn,
            title_ug: this.titleEdit.title_ug
          })
          
          this.$message.success('标题更新成功')
          this.editingTitle = false
          this.getEventDetails()
        } catch (err) {
          this.$message.error('标题更新失败')
        }
      },
  
      cancelEditTitle() {
        this.editingTitle = false
        this.titleEdit = {}
      },
  
      // Subtitle editing methods
      startEditSubtitle() {
        this.editingSubtitle = true
        this.subtitleEdit = {
          sub_title_cn: this.eventData.sub_title_cn,
          sub_title_ug: this.eventData.sub_title_ug
        }
      },
  
      async saveSubtitle() {
        try {
          await this.$http.put(`/events/${this.$route.params.id}`, {
            sub_title_cn: this.subtitleEdit.sub_title_cn,
            sub_title_ug: this.subtitleEdit.sub_title_ug
          })
          
          this.$message.success('副标题更新成功')
          this.editingSubtitle = false
          this.getEventDetails()
        } catch (err) {
          this.$message.error('副标题更新失败')
        }
      },
  
      cancelEditSubtitle() {
        this.editingSubtitle = false
        this.subtitleEdit = {}
      },
  
      // Info editing methods
      startEditInfo() {
        this.editingInfo = true
        this.infoEdit = {
          event_time: this.eventData.event_time,
          status: this.eventData.status,
          hyper_link: this.eventData.hyper_link
        }
      },
  
      async saveInfo() {
        try {
          await this.$http.put(`/events/${this.$route.params.id}`, {
            event_time: this.infoEdit.event_time,
            status: this.infoEdit.status,
            hyper_link: this.infoEdit.hyper_link
          })
          
          this.$message.success('信息更新成功')
          this.editingInfo = false
          this.getEventDetails()
        } catch (err) {
          this.$message.error('信息更新失败')
        }
      },
  
      cancelEditInfo() {
        this.editingInfo = false
        this.infoEdit = {}
      },
  
      // Image handling methods
      getUploadUrl(type) {
        return `${this.serverURL}/api/v1/images/image/event/${type}`
      },
  
      getHeaders() {
        return {
          'Authorization': `Bearer ${getToken()}`
        }
      },
  
      getImageUrl(path) {
        return path.startsWith('http') ? path : `${this.imageURL}${path}`
      },
  
      beforeUpload(file) {
        const isImage = /^image\/(jpeg|png|gif|jpg)$/.test(file.type)
        const isLt10M = file.size / 1024 / 1024 < 10
  
        if (!isImage) {
          this.$message.error('只能上传 JPG/PNG/GIF 格式的图片!')
          return false
        }
        if (!isLt10M) {
          this.$message.error('图片大小不能超过 10MB!')
          return false
        }
        return true
      },
  
      startEditCovers() {
        this.editingCovers = true
      },
  
      cancelEditCovers() {
        this.editingCovers = false
      },
  
      startEditCarousel() {
        this.editingCarousel = true
      },
  
      cancelEditCarousel() {
        this.editingCarousel = false
      },
  
      startEditMoments() {
        this.editingMoments = true
      },
  
      cancelEditMoments() {
        this.editingMoments = false
      },
  
      async uploaded(response, type) {
        if (!response.data) {
          this.$message.error('上传失败')
          return
        }
  
        const imageUrl = response.data
        const updateData = {}
        
        if (type === 'moments' || type.includes('carousel_images')) {
          updateData[type] = [...this.eventData[type], imageUrl]
        } else {
          updateData[type] = imageUrl
        }
  
        try {
          await this.$http.put(`/events/${this.$route.params.id}`, updateData)
          this.$message.success('图片上传成功')
          this.getEventDetails()
        } catch (err) {
          this.$message.error('图片上传失败')
        }
      },
  
      async removeImage(type, index) {
        try {
          const confirmResult = await this.$confirm('确认删除该图片?', '提示', {
            type: 'warning'
          })
  
          if (confirmResult === 'confirm') {
            const updateData = {}
            
            if (type === 'moments' || type.includes('carousel_images')) {
              const newImages = [...this.eventData[type]]
              newImages.splice(index, 1)
              updateData[type] = newImages
            } else {
              updateData[type] = ''
            }
  
            await this.$http.put(`/events/${this.$route.params.id}`, updateData)
            this.$message.success('图片删除成功')
            this.getEventDetails()
          }
        } catch (err) {
          if (err !== 'cancel') {
            this.$message.error('删除失败')
          }
        }
      },
  
      async handleDelete() {
        try {
          await this.$confirm('确认删除该活动?', '提示', {
            type: 'warning'
          })
          
          await this.$http.delete(`/events/${this.$route.params.id}`)
          this.$message.success('删除成功')
          this.goEvents()
        } catch (err) {
          if (err !== 'cancel') {
            this.$message.error('删除失败')
          }
        }
      },
  
      async onDragEnd() {
        try {
          await this.$http.put(`/events/${this.$route.params.id}`, {
            moments: this.eventData.moments
          })
          this.$message.success('顺序已更新')
        } catch (err) {
          this.$message.error('顺序更新失败')
          this.getEventDetails()
        }
      },
  
      async onCarouselDragEnd(lang) {
        try {
          const updateData = {}
          if (lang === 'cn') {
            updateData.carousel_images_cn = this.eventData.carousel_images_cn
          } else {
            updateData.carousel_images_ug = this.eventData.carousel_images_ug
          }

          await this.$http.put(`/events/${this.$route.params.id}`, updateData)
          this.$message.success('顺序已更新')
        } catch (err) {
          this.$message.error('顺序更新失败')
          this.getEventDetails()
        }
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  // 复用 banner/Details.vue 的样式
  .event-details {
    min-height: 100vh;
  
    .content-grid {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
      gap: 20px;
      margin: 0 auto;
  
      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        
        .right-column {
          order: 2;
        }
        
        .left-column {
          order: 1;
          transform: translateY(20px);
        }
      }
    }
  }
  
  .el-card {
    &.title-card,
    &.subtitle-card,
    &.info-card,
    &.images-card,
    &.carousel-images-card,
    &.moments-images-card {
      margin-bottom: 20px;
      border-radius: 8px;
  
      .card-header {
        position: relative;
        padding: 8px 20px;
        border-radius: 8px;
        background: #fafafa;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        span {
          font-size: 14px;
          font-weight: 500;
          color: #1f2937;
          padding-left: 10px;
          position: relative;
  
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 2px;
            height: 12px;
            background: #1890ff;
            border-radius: 1px;
          }
        }
  
        .header-actions {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
        }
      }
    }
  }
  
  // 顶部操作栏样式
  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
    margin-bottom: 20px;
    border-radius: 8px;
  
    .date-info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 48px;
      color: #888;
      font-size: 13px;
  
      &.deleted {
        color: #ff4d4f;
      }
    }
  }
  
  // 标题和副标题卡片样式
  .title-card,
  .subtitle-card {
    .title-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      padding: 20px;
  
      .title-cn, .title-ug {
        .title-label {
          font-size: 13px;
          color: #666;
          margin-bottom: 12px;
        }
  
        .title-content {
          background: #fafafa;
          padding: 9px 15px;
          border-radius: 4px;
          border: 1px solid #f0f0f0;
          font-size: 14px;
          color: #262626;
        }
      }
  
      .title-ug {
        direction: rtl;
  
        .title-label {
          text-align: right;
        }
  
        .el-input__inner {
          direction: rtl;
          text-align: right;
        }
  
        .title-content {
          text-align: right;
        }
      }
    }
  }
  
  // 基本信息卡片样式
  .info-card {
    .info-grid {
      padding: 20px;
  
      .info-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        margin-bottom: 20px;
      }
  
      .info-item {
        background: #fafafa;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid #f0f0f0;
  
        .info-label {
          font-size: 13px;
          color: #666;
          margin-bottom: 8px;
        }
  
        .info-content {
          font-size: 14px;
          color: #262626;
          overflow: hidden;
        }
  
        .info-tip {
          font-size: 12px;
          color: #999;
          margin-left: 4px;
        }
  
        .el-select {
          width: 100%;
        }
      }
    }
  }
  
  // 图片相关样式
  .images-card,
  .carousel-images-card,
  .moments-images-card {
    .images-wrapper {
      padding: 20px;
    }
  
    .cover-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  
    .label-cn {
      font-size: 13px;
      color: #666;
      margin-bottom: 12px;
      direction: ltr;
    }
  
    .label-ug {
      font-size: 13px;
      color: #666;
      margin-bottom: 12px;
      direction: rtl;
    }
  
    .image-container {
      position: relative;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
  
      :deep(.el-image) {
        width: 100%;
        height: 100%;
        display: block;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .image-actions {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
      }
  
      &:hover .image-actions {
        opacity: 1;
      }
    }
  
    .image-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
      gap: 8px;
  
      .image-item {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        background-color: #f8f8f8;
  
        :deep(.el-image) {
          width: 100%;
          height: 100%;
          display: block;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
  
        .image-actions {
          position: absolute;
          top: 8px;
          right: 8px;
          opacity: 0;
          transition: opacity 0.3s;
          z-index: 1;
        }
  
        &:hover .image-actions {
          opacity: 1;
        }
      }
    }
  }
  
  .carousel-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  
    .carousel-column {
      .label-cn, .label-ug {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }
  
      .label-ug {
        text-align: right;
        direction: rtl;
      }
  
      .image-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
        gap: 8px;
      }
    }
  }
  
  .cover-shape {
    aspect-ratio: 16/9;
  }
  
  .carousel-shape {
    aspect-ratio: 16/10;
  }
  
  .moments-shape {
    aspect-ratio: 3/2;
  }
  
  .upload-box {
    border-radius: 8px;
    overflow: hidden;
    background-color: #fafafa;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #f5f5f5;
    }
  
    .image-uploader {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .el-icon-plus {
      font-size: 20px;
      color: #8c939d;
      margin-bottom: 8px;
    }
  
    .upload-text {
      font-size: 12px;
      color: #666;
    }
  }
  
  .el-button {
    padding: 10px 20px;
    transition: all 0.2s ease;

    &.delete-button {
      padding: 7px !important;
    }
  
    &--text {
      padding: 4px 8px;
      font-size: 13px;
  
      &:hover {
        background: rgba(24, 144, 255, 0.06);
        color: #1890ff;
      }
    }
  }
  
  .el-input :deep(.el-input__inner) {
    font-family: inherit;
  }
  
  .el-tag {
    margin: 0;
    padding: 0 10px;
  }
  </style>