<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>报名人管理</el-breadcrumb-item>
      <el-breadcrumb-item>报名人列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <el-row :gutter="10" class="filter-row">
        <el-col :span="5">
            <el-input 
                placeholder="订单编号" 
                v-model="searchQuery.order_no"
                clearable 
                @clear="fetchParticipants()" 
                size="medium">
                <el-button slot="append" icon="el-icon-search" @click="fetchParticipants()"></el-button>
            </el-input>
        </el-col>
        <el-col :span="12">
          <el-select 
            size="medium"
            style="width: 100%"
            placeholder="请选择活动"
            @change="fetchParticipants()"
            v-model="searchQuery.activity_id" 
            popper-class="activity-select-dropdown">
            <el-option
              v-for="item in activityOptions"
              :key="item.activity_id"
              :label="item.title_cn"
              :value="item.activity_id">
              <div class="activity-option">
                <div class="activity-info">
                  <span>{{ item.title_cn }}</span>
                  <div class="activity-meta">
                    <el-tag size="mini" :type="getStatusType(item.status)">
                      {{ getStatusText(item.status) }}
                    </el-tag>
                    <span class="activity-price">￥{{ item.price }}</span>
                  </div>
                </div>
              </div>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="switch-col">
          <el-switch
            v-model="showActive"
            inactive-text="有效活动"
            active-text="所有活动"
            @change="fetchActivities">
          </el-switch>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="5">
          <el-input 
            placeholder="报名人姓名" 
            v-model="searchQuery.name" 
            clearable  
            @clear="fetchParticipants()" 
            size="medium">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input 
            placeholder="报名人手机号" 
            v-model="searchQuery.phone" 
            clearable 
            @clear="fetchParticipants()" 
            size="medium">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input 
            placeholder="紧急联系人姓名" 
            v-model="searchQuery.emergency_contact_name" 
            clearable 
            @clear="fetchParticipants()" 
            size="medium">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input 
            placeholder="紧急联系人电话" 
            v-model="searchQuery.emergency_contact_phone" 
            clearable 
            @clear="fetchParticipants()" 
            size="medium">
          </el-input>
        </el-col>
        <el-col :span="3">
            <el-select
                size="medium"
                placeholder="订单状态"
                @change="fetchParticipants()"
                v-model="searchQuery.order_status">
                <el-option label="待支付" :value="0"></el-option>
                <el-option label="已支付" :value="1"></el-option>
                <el-option label="已取消" :value="2"></el-option>
                <el-option label="已退款" :value="3"></el-option>
            </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="default" @click="resetSearch()" size="medium">重置</el-button>
          <el-button type="primary" @click="fetchParticipants()" size="medium">搜索</el-button>
        </el-col>
      </el-row>

      <!-- 报名列表 -->
      <el-table :data="participantList" style="width: 100%" border stripe>
        <el-table-column type="index" prop="id" width="40" align="center"></el-table-column>
        <el-table-column label="姓名" prop="name" width="180" align="center"></el-table-column>
        <el-table-column label="手机号" prop="phone" width="120" align="center"></el-table-column>
        <el-table-column label="身份证号" prop="id_card_number" width="160" align="center"></el-table-column>
        <el-table-column label="年龄" width="50" align="center"><template slot-scope="scope">{{ scope.row.age }}岁</template></el-table-column>
        <el-table-column label="年级" prop="grade" width="80" align="center"><template slot-scope="scope">{{ getGrade(scope.row.grade) }}</template></el-table-column>
        <el-table-column label="紧急联系人" prop="emergency_contact_name" width="160" align="center"></el-table-column>
        <el-table-column label="紧急联系人电话" prop="emergency_contact_phone" width="120" align="center"></el-table-column>
        <el-table-column label="报名时间" width="160" align="center">
          <template slot-scope="scope">{{ formatDateTime(scope.row.signup_time) }}</template>
        </el-table-column>
        <el-table-column label="报名状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.signup_status === 0 ? 'info' : 'success'" effect="plain">
              {{ scope.row.signup_status === 0 ? '未支付' : '已支付' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="order_no" width="160" align="center">
          <template slot-scope="scope">
            <span 
              @click="viewDetails(scope.row.order_no)" 
              class="clickable-order-no">{{ scope.row.order_no }}</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="onSizeChange"
        @current-change="onPageChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Enrolls',
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      participantList: [],
      searchQuery: {
        name: '',
        phone: '',
        order_no: '',
        activity_id: null,
        order_status: null,
        emergency_contact_name: '',
        emergency_contact_phone: ''
      },
      activityOptions: [],
      activityStatusMap: {
        1: '未开始',
        2: '进行中',
        3: '已结束'
      },
      showActive: false,
      activityStatusTagTypes: {
        1: 'primary',    // 未开始
        2: 'success', // 进行中
        3: 'info'  // 已结束
      }
    }
  },
  created() {
    this.fetchActivities()
    this.fetchParticipants()
  },
  methods: {
    fetchParticipants() {
      this.$http({
        method: 'get',
        url: '/participants',
        params: {
          ...this.searchQuery,
          page: this.pageNum,
          size: this.pageSize
        }
      }).then(res => {
        if (!res.success) return this.$message.error(res.message)
        this.participantList = res.data
        this.total = res.total
      }).catch(err => {
        this.$message.error(err.response?.data?.message || '获取报名列表失败')
      })
    },

    fetchActivities() {
      this.$http({
        method: 'get',
        url: '/activities/search',
        params: {
          is_deleted: this.showActive ? 1 : 0
        }
      }).then(res => {
        if (!res.success) return this.$message.error(res.message)
        this.activityOptions = res.data
      }).catch(err => {
        this.$message.error(err.response?.data?.message || '获取活动列表失败')
      })
    },

    viewDetails(order_no) {
      window.open(`#/admin/enroll/details/${order_no}`, '_blank')
    },

    getGrade(grade) {
        if (grade === 'XIAO-1') return '一年级'
        if (grade === 'XIAO-2') return '二年级'
        if (grade === 'XIAO-3') return '三年级'
        if (grade === 'XIAO-4') return '四年级'
        if (grade === 'XIAO-5') return '五年级'
        if (grade === 'XIAO-6') return '六年级'
        if (grade === 'CHU-1') return '初一'
        if (grade === 'CHU-2') return '初二'
        if (grade === 'CHU-3') return '初三'
        if (grade === 'GAO-1') return '高一'
        if (grade === 'GAO-2') return '高二'
        if (grade === 'GAO-3') return '高三'
        if (grade === 'OTHER') return '其他'
        return grade
    },

    getStatusText(status) {
      return this.activityStatusMap[status] || '未知'
    },

    getStatusType(status) {
      return this.activityStatusTagTypes[status] || 'info'
    },

    resetSearch() {
      this.searchQuery = {
        name: '',
        phone: '',
        order_no: '',
        activity_id: null,
        order_status: null,
        emergency_contact_name: '',
        emergency_contact_phone: ''
      }
      this.fetchParticipants()
    },

    formatDateTime(date) {
      if (!date) return ''
      return new Date(date).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    },

    onSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchParticipants()
    },

    onPageChange(newPage) {
      this.pageNum = newPage
      this.fetchParticipants()
    }
  }
}
</script>

<style scoped>
.el-card {
  margin-top: 15px;
}

.el-table {
  margin-top: 15px;
}

.el-pagination {
  margin-top: 15px;
}

.activity-option {
  padding: 6px;
}

.activity-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-meta {
  display: flex;
  align-items: center;
  gap: 10px;
}

.activity-price {
  color: #f56c6c;
  font-size: 12px;
  font-weight: 500;
  min-width: 40px;
  text-align: right;
}

.activity-select-dropdown .el-select-dropdown__item {
  padding: 0 8px !important;
  height: auto !important;
}

.filter-row {
  margin-bottom: 10px;
}

.switch-col {
  display: flex;
  align-items: center;
  height: 35px;
}

.clickable-order-no {
  color: #409EFF;
  cursor: pointer;
  text-decoration: underline;
}

.clickable-order-no:hover {
  color: #66b1ff;
}
</style>
