<template>
  <div class="about-me">
    <!-- Profile Card -->
    <div class="profile-card">
      <div class="profile-header">
        <div class="profile-info">
          <div class="avatar">
            <!-- Add user avatar/initials here -->
            <span>{{ userInfo.username[0] }}</span>
          </div>
          <div class="user-details">
            <h2>{{ userInfo.username }}</h2>
            <span class="role">{{ roleName }}</span>
          </div>
        </div>
        <div class="actions">
          <el-button type="primary" @click="handleEdit">
            <i class="el-icon-edit"></i> 修改信息
          </el-button>
          <el-button type="warning" @click="handleChangePassword">
            <i class="el-icon-key"></i> 修改密码
          </el-button>
        </div>
      </div>

      <!-- Info Grid -->
      <div class="info-grid">
        <div class="info-card">
          <i class="el-icon-mobile-phone"></i>
          <div class="info-content">
            <label>手机号</label>
            <span>{{ userInfo.phone || '-' }}</span>
          </div>
        </div>

        <div class="info-card">
          <i class="el-icon-circle-check"></i>
          <div class="info-content">
            <label>账号状态</label>
            <span>{{ userInfo.is_active ? '启用' : '禁用' }}</span>
          </div>
        </div>

        <div class="info-card">
          <i class="el-icon-location-outline"></i>
          <div class="info-content">
            <label>登录IP</label>
            <span>{{ userInfo.last_login_ip || '-' }}</span>
          </div>
        </div>

        <div class="info-card">
          <i class="el-icon-time"></i>
          <div class="info-content">
            <label>最近登录</label>
            <span>{{ formatDate(userInfo.last_login_time) }}</span>
          </div>
        </div>

        <div class="info-card">
          <i class="el-icon-time"></i>
          <div class="info-content">
            <label>创建时间</label>
            <span>{{ formatDate(userInfo.created_at) }}</span>
          </div>
        </div>

        <div class="info-card">
          <i class="el-icon-time"></i>
          <div class="info-content">
            <label>更新时间</label>
            <span>{{ formatDate(userInfo.updated_at) }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加修改信息对话框 -->
    <el-dialog
      title="修改个人信息"
      :visible.sync="editDialogVisible"
      width="500px"
      @close="resetForm"
    >
      <el-form :model="editForm" :rules="rules" ref="editForm" label-width="80px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEdit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加修改密码对话框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="passwordDialogVisible"
      width="500px"
      @close="resetPasswordForm"
    >
      <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" label-width="100px">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input type="password" v-model="passwordForm.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="passwordForm.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPassword">
          <el-input type="password" v-model="passwordForm.confirmPassword"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="passwordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPasswordChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AboutMe',
  data() {
    // 验证确认密码
    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    
    return {
      userInfo: {
        username: '-',
        phone: '-',
        is_active: null,
        role: null,
        last_login_ip: '-',
        last_login_time: ''
      },
      roleList: [
          { id: 1, name: '超级管理员' },
          { id: 2, name: '管理员' },
          { id: 3, name: '财务' },
          { id: 4, name: '客服' },
          { id: 5, name: '运营' }
      ],
      editDialogVisible: false,
      passwordDialogVisible: false,
      editForm: {
        username: '',
        phone: ''
      },
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ]
      },
      passwordRules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: validateConfirmPassword, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    roleName() {
      if (!this.userInfo.role) return '未知'
      return this.roleList.find(role => role.id === this.userInfo.role).name
    },
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$http({ method: 'get', url: 'users/me' }).then(res => {
        this.userInfo = res.data
      }).catch(err => {
        if (err.response.status === 401) {
          this.$router.push('/admin/login')
        } else {
          this.$message.error(err.response?.data?.message || '获取用户信息失败')
        }
      })
    },
    formatDate(dateStr) {
      if (!dateStr) return '-'
      return new Date(dateStr).toLocaleString()
    },
    handleEdit() {
      this.editForm.username = this.userInfo.username
      this.editForm.phone = this.userInfo.phone
      this.editDialogVisible = true
    },
    handleChangePassword() {
      this.passwordDialogVisible = true
    },
    submitEdit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$http({
            method: 'patch',
            url: 'users/me',
            data: this.editForm
          }).then(() => {
            this.$message.success('个人信息修改成功')
            this.editDialogVisible = false
            this.getUserInfo()
          }).catch(err => {
            this.$message.error(err.response?.data?.message || '个人信息修改失败')
          })
        }
      })
    },
    submitPasswordChange() {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          this.$http({
            method: 'patch',
            url: 'users/me/password',
            data: {
              current_password: this.passwordForm.oldPassword,
              new_password: this.passwordForm.newPassword
            }
          }).then(() => {
            this.$message.success('密码修改成功，请重新登录')
            this.passwordDialogVisible = false
            this.$router.push('/admin/login')
          }).catch(err => {
            this.$message.error(err.response?.data?.message || '密码修改失败')
          })
        }
      })
    },
    resetForm() {
      this.$refs.editForm?.resetFields()
    },
    resetPasswordForm() {
      this.$refs.passwordForm?.resetFields()
    }
  }
}
</script>

<style scoped>
.about-me {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.profile-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.profile-header {
  padding: 32px;
  background: linear-gradient(135deg, #1890ff 0%, #36cfc9 100%);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}

.user-details h2 {
  margin: 0;
  font-size: 24px;
}

.role {
  font-size: 14px;
  opacity: 0.8;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  padding: 32px;
}

.info-card {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.info-card i {
  font-size: 24px;
  color: #1890ff;
}

.info-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-content label {
  font-size: 12px;
  color: #909399;
}

.info-content span {
  font-size: 14px;
  color: #303133;
}

.actions {
  display: flex;
  gap: 12px;
}
</style>