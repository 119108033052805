<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>团队管理</el-breadcrumb-item>
      <el-breadcrumb-item>员工列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
        <!-- 搜索和添加 -->
        <el-row :gutter ='20'>
            <el-col :span='8'>
                <el-input placeholder="请输入搜索内容" v-model="searchQuery" clearable @clear="fetchUsers()" disabled size="medium">
                    <el-button slot="append" icon="el-icon-search" @click="fetchUsers()"></el-button>
                </el-input>
            </el-col>
            <el-col :span='4'>
                <el-button type='primary' @click="showAddBox = true" size="medium">创建员工</el-button>
            </el-col>
        </el-row>

        <!-- 员工列表 -->
        <el-table :data="userList" style="width: 100%" border stripe>
            <el-table-column label='#' type='index' align="center"></el-table-column>
            <el-table-column label='用户名' prop='username' align="center"></el-table-column>
            <el-table-column label='手机号' prop='phone' align="center"></el-table-column>
            <el-table-column label='角色' width="120px" align="center">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.role === 1 ? 'danger' : (scope.row.role === 2 ? 'primary' : 'success')">
                        {{getRole(scope.row.role)}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label='状态' width='80px' align="center">
                <template v-slot='scope'>
                    <el-switch v-model="scope.row.is_active" @change="toggleState(scope.row.id, scope.row.is_active)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label='上次登录' align="center">
                <template v-slot='scope'>
                    {{dataFormat(scope.row.last_login_time)}}
                </template>
            </el-table-column>
            <el-table-column label='登录IP' prop='last_login_ip' align="center"></el-table-column>
            <el-table-column label='操作' width='200px' align="center">
                <template v-slot='scope'>
                    <el-tooltip effect="dark" content="编辑" placement="top" :enterable='false'>
                        <el-button type="primary" icon="el-icon-edit" size='mini' @click="editUser(scope.row.id)"></el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="删除" placement="top" :enterable='false'>
                        <el-button type="danger" icon="el-icon-delete" size='mini' @click="deleteUser(scope.row.id)"></el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="分配角色" placement="top" :enterable='false'>
                        <el-button type="warning" icon="el-icon-setting" size='mini' @click="setRole(scope.row)"></el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
            @size-change="onSizeChange"
            @current-change="onPageChange"
            :current-page="pageNum"
            :page-sizes="[1, 2, 5, 10]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </el-card>

    <!-- 创建员工对话框 -->
    <el-dialog title="创建员工" :visible.sync="showAddBox" width="30%" @close="resetAddForm()">
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="addForm.username"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="addForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="addForm.password"></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="role">
                <el-select v-model="addForm.role" placeholder="请选择角色">
                    <el-option 
                        v-for="item in roleList.filter(r => r.id !== 1)" 
                        :key="item.id" 
                        :label="item.name" 
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="showAddBox = false">取 消</el-button>
            <el-button type="primary" @click="addUser">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 修改员工对话框 -->
    <el-dialog title="修改员工" :visible.sync="showEditBox" width="30%" @close="resetEditForm()">
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
            <el-form-item label="员工ID" prop="id">
                <el-input v-model="editForm.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
                <el-input v-model="editForm.username"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="editForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
                <el-input v-model="editForm.password"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="showEditBox = false">取 消</el-button>
            <el-button type="primary" @click="updateUser">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 更新角色对话框 -->
    <el-dialog title="更新角色" :visible.sync="showRoleBox" width="30%" @close="resetRoleForm()">
        <div>
            <p>用户名称：<el-tag type="info">{{userInfo.username}}</el-tag></p>
            <p>当前角色：<el-tag type="info">{{getRole(userInfo.role)}}</el-tag></p>
            <p>新配角色：
                <el-select v-model="newRole" placeholder="请选择新角色">
                    <el-option 
                        v-for="item in roleList.filter(r => r.id !== 1)" 
                        :key="item.id" 
                        :label="item.name" 
                        :value="item.id">
                    </el-option>
                </el-select>
            </p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="showRoleBox = false">取 消</el-button>
            <el-button type="primary" @click="updateRole(userInfo.id)">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { validateFormPhone } from '@/utils/validation'

export default {
  name: "Users",
  data(){
    return {
        
        userList: [],
        showAddBox: false,
        showEditBox: false,
        showRoleBox: false,
        addForm: {
            username: '',
            phone: '',
            role: null,
            password: null,
            is_active: true,
        },
        editForm: {
            username: '',
            phone: '',
            password: null
        },
        addFormRules: {
            username: [
                { required: true, message: '请输入用户名', trigger: 'blur' },
                { min: 3, max: 10, message: '长度在3到10个字符', trigger: 'blur' }
            ],
            phone: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { validator: validateFormPhone, trigger: 'blur'}
            ],
            password: [
                { required: true, message: '请设置新密码', trigger: 'blur' },
                { min: 8, max: 15, message: '长度在8到15个字符', trigger: 'blur' }
            ],
            role: [
                { required: true, message: '请选择角色', trigger: 'blur' }
            ]
        },
        editFormRules: {
            username: [
                { required: true, message: '请输入用户名', trigger: 'blur' },
                { min: 3, max: 10, message: '长度在3到10个字符',trigger: 'blur' }
            ],
            phone: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { validator: validateFormPhone, trigger: 'blur'}
            ],
            password: [
                { required: false, min: 8, max: 15, message: '长度在8到15个字符', trigger: 'blur' }
            ],
            role: [
                { required: true, message: '请选择角色', trigger: 'blur' }
            ]
        },
        newRole: '',
        userInfo: {},
        roleList: [
            { id: 1, name: '超级管理员' },
            { id: 2, name: '管理员' },
            { id: 3, name: '财务' },
            { id: 4, name: '客服' },
            { id: 5, name: '运营' }
        ],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        searchQuery: ''
    }
  },
  created(){
      this.fetchUsers()
  },
  methods: {
    fetchUsers() {
      this.$http({ method: 'get', url: 'users', params: { page: this.pageNum, size: this.pageSize } })
        .then(res => {
          if(!res.success) return this.$message.error(res.message)
          this.userList = res.data
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.response?.data?.message || '获取员工列表失败')
        })
    },
    dataFormat(originVal){
        const dt = new Date(originVal)
        const y = dt.getFullYear()
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        const d = (dt.getDate() + '').padStart(2, '0')
        const hh = (dt.getHours() + '').padStart(2, '0')
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        const ss = (dt.getSeconds() + '').padStart(2, '0')
        return `${y}/${m}/${d} ${hh}:${mm}:${ss}`
    },
    onSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchUsers()
    },
    onPageChange(newPage) {
      this.pageNum = newPage 
      this.fetchUsers()
    },
    addUser(){
        this.$refs.addFormRef.validate(valid => {
            if(!valid) return
            this.$http({ method: 'post', url: 'users', data: this.addForm }).then(res => {
                if(!res.success) return this.$message.error(res.message)
                this.$message.success('创建员工成功')
                this.showAddBox = false
                this.fetchUsers()
            }).catch(err => {
                this.$message.error(err.response?.data?.message || '创建员工失败')
            })
        })
    },
    resetAddForm() {
      this.$refs.addFormRef.resetFields()
    },
    editUser(id) {
      this.$http({ method: 'get', url: 'users/' + id })
        .then(res => {
          if(!res.success) return this.$message.error(res.message)
          this.editForm = res.data
          this.showEditBox = true
        })
        .catch(err => {
          this.$message.error(err.response?.data?.message || '获取员工信息失败')
        })
    },
    updateUser() {
      this.$refs.editFormRef.validate(valid => {
        if(!valid) return
        this.$http({
            method: 'patch',
            url: 'users/' + this.editForm.id,
            data: {
                username: this.editForm.username,
                phone: this.editForm.phone,
                password: this.editForm.password
            }
        })
          .then(res => {
            if(!res.success) return this.$message.error(res.message)
            this.$message.success('修改员工信息成功')
            this.showEditBox = false
            this.fetchUsers()
          })
          .catch(err => {
            this.$message.error(err.response?.data?.message || '修改员工信息失败')
          })
      })
    },
    resetEditForm() {
      this.$refs.editFormRef.resetFields()
    },
    toggleState(id, state) {
      this.$http({ method: 'patch', url: `users/${id}`, data: {is_active: state} })
        .then(res => {
          if(!res.success) return this.$message.error(res.message)
        })
        .catch(err => {
          this.$message.error(err.response?.data?.message || '更新员工状态失败')
          this.fetchUsers()
        })
    },
    deleteUser(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete('users/' + id)
          .then(res => {
            if(!res.success) return this.$message.error(res.message)
            this.$message.success('删除员工成功')
            this.fetchUsers()
          })
          .catch(err => {
            this.$message.error(err.response?.data?.message || '删除员工失败')
          })
      }).catch((err) => err)
    },
    getRole(roleId) {
        const role = this.roleList.find(r => r.id === roleId)
        return role ? role.name : '未知角色'
    },
    setRole(userInfo){
        this.userInfo = userInfo
        this.showRoleBox = true
    },
    updateRole(id) {
      if(!this.newRole) return this.$message.error('请选择要分配的角色')
      this.$http({ method: 'patch', url: `users/${id}`, data: {role: this.newRole} })
        .then(res => {
          if(!res.success) return this.$message.error(res.message)
          this.fetchUsers()
          this.showRoleBox = false
          this.$message.success('更新角色成功')
        })
        .catch(err => {
          this.$message.error(err.response?.data?.message || '更新角色失败')
        })
    },
    resetRoleForm() {
      this.newRole = ''
      this.userInfo = {}
    }
  }
}
</script>

<style>

</style>